import React from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next'

export default function TrendingSpeakers(props) {
  const { speakers, onSpeakerClicked } = props;
  const { t } = useTranslation();

  return (
    <section className="speakers">
      <div className="viewport">
        <div className="inner">
          <h2 className="center-title">{t('Trending_Speakers')}</h2>
          <div className="speakers-list">
            {speakers &&
              speakers.filter(s => s !== null).map((s, i) => (
                <div key={s.id} className="speaker-item">
                  <div className="inner">
                    <div className="medias">
                      <span
                        style={{
                          backgroundImage: `url(${
                            process.env.REACT_APP_IMG_URL
                          }/storage/speaker-picture/${get(s, 'speakerPicture.uri', '')})`,
                        }}
                      ></span>
                    </div>
                    <div className="speaker-infos">
                      <div className="speaker-name">{s.name} {s.surname}</div>
                      <div className="job">{s.jobTitle}</div>
                      <div className="branch">
                        {get(s, 'city.name', '') !== '' &&
                          get(s, 'country.name', '') !== '' &&
                          get(s, 'hotel.name', '') !== '' && (
                            <>{`${get(s, 'city.name', '')},${get(s, 'country.name', '')} - ${get(
                              s,
                              'hotel.name',
                              ''
                            )}`}</>
                          )}
                      </div>
                      <Link
                        key={i}
                        className="discover"
                        to={`/speaker/${s.id}`}
                        onClick={
                          () => onSpeakerClicked ? 
                          onSpeakerClicked(s.id) : null
                        }
                      >
                        {t('Discover_Series')}
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
}
