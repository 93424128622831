import React, { useState } from "react";
import { withRouter } from "react-router";
import { parseCategoryID } from "../../utils/index";
import get from "lodash.get";
import { useTranslation } from 'react-i18next'

function ViewedVideoItem(props) {
  const { t } = useTranslation();

  const { video, onVideoClicked } = props;

  const parseTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.round(time - minutes * 60, 2);
    return `${minutes}${t('Xmins')}`;
  };

  const handleClick = (e) => {
    if (e.target.className.includes("speaker")) {
      const url = `/speaker/${e.target.className.split(" ")[1]}`;

      if (e.button === 1) {
        window.open(url, "_blank");
      } else if (e.button === 0) {
        props.history.push(url);
      }
    } else {
      if (e.button === 1) {
        if (onVideoClicked) {
          onVideoClicked(video.id);
        }
        const win = window.open(`/video?id=${video.id}`, "_blank");
        win.focus();
      } else if (e.button === 0) {
        if (onVideoClicked) {
          onVideoClicked(video.id);
        }
        props.history.push(`/video?id=${video.id}`);
      }
    }
  };

  const handleWatchAgain = (e) => {
    props.history.push(`/video?id=${video.id}`);
  };

  return (
    <div className="viewed-video-item">
      <div
        // to={`/video?id=${video.id}`}
        style={{ color: "white", textDecoration: "none", pointerEvents: "all" }}
        onMouseDown={handleClick}
      >
        <div className="viewed-block">
          <div className="viewed-video-wrapper">
            {get(video, "videoThumbnail.uri", "") !== "" && (
              <div
                className="video-wrapper"
                style={{
                  backgroundImage:
                    get(video, "videoThumbnail.uri", "") !== ""
                      ? `url(${
                          process.env.REACT_APP_IMG_URL
                        }/storage/video-thumbnail/${get(
                          video,
                          "videoThumbnail.uri",
                          ""
                        )})`
                      : 'url("/assets/images/video-1.jpg")',
                }}
              ></div>
            )}
            {get(video, "videoThumbnail.uri", "") === "" && (
              <div className="video-wrapper">
                <div className="medias" style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
                  <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1}}/>
                    <iframe
                      title={video.wistiaHashId}
                      style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 0}}
                      src={`//fast.wistia.net/embed/iframe/${video.wistiaHashId}?fitStrategy=cover&controls=false&endVideoBehavior=loop&playbar=false&playButton=false&settingsControl=false&volumeControl=false&silentAutoplay=allow&smallPlayButton=false&fullscreenButton=false`}
                      frameBorder="0"
                      scrolling="no"
                      name="hero_embed"
                    ></iframe>
                </div>
              </div>
                
            )}
          </div>
          <div className="infos">
            <div className="video-title">{video.title}</div>
            <div className="video-details">
              <div className="category-wrapper">
                <div
                  className={`category cat ${
                    parseCategoryID(video.category).categoryShorthand
                  }`}
                >
                  {parseCategoryID(video.category).categoryName}
                </div>
                {video.isSustainability && (
                  <div className="sustainability-tag-wrapper">
                    <span className="icon-sustainability"></span>
                    <span className="text-sustainability">sustainability</span>
                  </div>
                )}
              </div>
              <div className="time">{parseTime(video.duration)}</div>
              {(video?.speakers?.length > 0 && video?.speakers?.filter(s => s !== null) > 0 ) && (
                <div className="author">
                  <span>{t('With')}: </span>
                  {video.speakers.filter(speaker => speaker !== null).map((speaker, index) => [
                    <span className={`speaker ${speaker.id}`}>{speaker.name} {speaker.surname}</span>,
                    index === video.speakers.length - 1 ? null : ", ",
                  ])}
                </div>
              )}
            </div>
            <div className="video-description">{video.description}</div>
          </div>
          <div onClick={handleWatchAgain} className="watch-again">
            <i className={'icon icon-playbutton'}></i>
            {t('Watc_Again')}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ViewedVideoItem);
