import React from "react";
import { useGlobalContext } from "../../../store/global";
import Announcement from "./announcement";
import PhotoUpload from "./photoUpload";
import Registration from "./registration";
import Suggestion from "./suggestion";
import Thanks from "./thanks";
import WrongPassword from "./wrongPassword";
import EmailSent from "./emailSent";
import SsofFail from "./ssoFail";
import ResetPassword from "./resetPassword";
import Error from "./error";
import Comment from "./comment";
import DeleteComment from "./deleteComment";
import DeleteCommentConfirm from "./deleteCommentConfirm";
import EditComment from "./editComment";
import Reply from "./reply";
import Likes from "./likes";
import Quizzes from "./quizzes";

const Modal = (props) => {
  const { modalType, closeModal } = useGlobalContext();

  return (
    <div
      onClick={(e) =>
        e.target.className.includes("popup-wrapper") &&
        modalType !== "registration"
          ? closeModal()
          : null
      }
      className={`popup-wrapper ${modalType}`}
    >
      {modalType === "announcement" && <Announcement />}
      {modalType === "registration" && <Registration />}
      {modalType === "profile-pic" && <PhotoUpload />}
      {modalType === "suggestion" && <Suggestion />}
      {modalType === "thanks" && <Thanks />}
      {modalType === "credentials" && <WrongPassword />}
      {modalType === "emailSent" && <EmailSent />}
      {modalType === "ssofail" && <SsofFail />}
      {modalType === "pwreset" && <ResetPassword />}
      {modalType === "fail" && <Error />}
      {modalType === "comment" && <Comment />}
      {modalType === "delete-comment" && <DeleteComment />}
      {modalType === "delete-comment-confirm" && <DeleteCommentConfirm />}
      {modalType === "edit-comment" && <EditComment />}
      {modalType === "reply" && <Reply />}
      {modalType === "likes" && <Likes />}
      {modalType === "quizzes" && <Quizzes />}
    </div>
  );
};

export default Modal;
