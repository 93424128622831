import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next'
import 'swiper/swiper.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { getProfile } from "../../utils/data";

export default function Leaderboard(props) {
  const [role, setRole] = useState();
  const [swiper, setSwiper] = useState(null);
  const { data, region } = props;
  const { t } = useTranslation();

  useEffect(() => {
    getProfile().then((res) => {
      setRole(res?.data?.role)
    })
  }, []);

  return (
      <div className="leaderboard-wrapper">
        <div className="list expert">
          <div className="title">
            <span className="icon-eye"></span>{t('Expert_Viewer')}
          </div>
          <div className="subtitle">{t('Biggest_Viewed')}</div>
          <ul>
            {get(props.data, 'expertViewers', [])
              .filter((u) => !u.leaderboardBlacklisted)
              .map((ev, i) => {
                return i === 0 ? (
                  <li key={ev.id} className="first">
                    {get(ev, 'profilePicture.uri', '') !== '' && (
                      <div className="medias">
                        <span
                          style={{
                            backgroundImage: `url(${
                              process.env.REACT_APP_IMG_URL
                            }/storage/user-picture/${get(ev, 'profilePicture.uri', '')})`,
                          }}
                        ></span>
                      </div>
                    )}
                    {get(ev, 'profilePicture.uri', '') === '' && (
                      <div className="medias">
                        <span
                          style={{ backgroundImage: `url('/assets/images/profile.png')` }}
                        ></span>
                      </div>
                    )}
                    <LinkOptional
                      to={`/profile/${ev.id}`}
                      disabled={!role || (role !== 'admin' && role !== 'super-admin' && role !== 'hotel')}
                    >
                      <div className="name">
                        {`${ev.name} ${ev.surname}`}
                      </div>
                    </LinkOptional>
                  </li>
                ) : (
                  <li key={ev.id}>
                    {get(ev, 'profilePicture.uri', '') !== '' && (
                      <div className="medias">
                        <span
                          style={{
                            backgroundImage: `url(${
                              process.env.REACT_APP_IMG_URL
                            }/storage/user-picture/${get(ev, 'profilePicture.uri', '')})`,
                          }}
                        ></span>
                      </div>
                    )}
                    {get(ev, 'profilePicture.uri', '') === '' && (
                      <div className="medias">
                        <span
                          style={{ backgroundImage: `url('/assets/images/profile.png')` }}
                        ></span>
                      </div>
                    )}
                    <LinkOptional
                      to={`/profile/${ev.id}`}
                      disabled={!role || (role !== 'admin' && role !== 'super-admin' && role !== 'hotel')}
                    >
                      <div className="name">
                        {`${ev.name} ${ev.surname}`}
                      </div>
                    </LinkOptional>
                  </li>
                );
              })}
          </ul>
        </div>

        <div className="list fastest">
          <div className="title">
            <span className="icon-thunder"></span>{t('Fastest_Viewer')}
          </div>
          <div className="subtitle">{t('Woke_Up_Earilest')}</div>
          <ul>
            {get(props, 'region.users', [])
              .filter((u) => !u.leaderboardBlacklisted)
              .map((ev, i) => {
                return i === 0 ? (
                  <li key={ev.id} className="first">
                    {get(ev, 'profilePicture.uri', '') !== '' && (
                      <div className="medias">
                        <span
                          style={{
                            backgroundImage: `url(${
                              process.env.REACT_APP_IMG_URL
                            }/storage/user-picture/${get(ev, 'profilePicture.uri', '')})`,
                          }}
                        ></span>
                      </div>
                    )}
                    {get(ev, 'profilePicture.uri', '') === '' && (
                      <div className="medias">
                        <span
                          style={{ backgroundImage: `url('/assets/images/profile.png')` }}
                        ></span>
                      </div>
                    )}
                    <LinkOptional
                      to={`/profile/${ev.id}`}
                      disabled={!role || (role !== 'admin' && role !== 'super-admin' && role !== 'hotel')}
                    >
                      <div className="name">
                        {`${ev.name} ${ev.surname}`}
                      </div>
                    </LinkOptional>
                  </li>
                ) : (
                  <li key={ev.id}>
                    {get(ev, 'profilePicture.uri', '') !== '' && (
                      <div className="medias">
                        <span
                          style={{
                            backgroundImage: `url(${
                              process.env.REACT_APP_IMG_URL
                            }/storage/user-picture/${get(ev, 'profilePicture.uri', '')})`,
                          }}
                        ></span>
                      </div>
                    )}
                    {get(ev, 'profilePicture.uri', '') === '' && (
                      <div className="medias">
                        <span
                          style={{ backgroundImage: `url('/assets/images/profile.png')` }}
                        ></span>
                      </div>
                    )}
                    <LinkOptional
                      to={`/profile/${ev.id}`}
                      disabled={!role || (role !== 'admin' && role !== 'super-admin' && role !== 'hotel')}
                    >
                      <div className="name">
                        {`${ev.name} ${ev.surname}`}
                      </div>
                    </LinkOptional>
                  </li>
                );
              })}
          </ul>
        </div>

        <div className="list contrib">
          <div className="title">
            <span className="icon-message"></span>{t('Star_Contributor')}
          </div>
          <div className="subtitle">{t('Posted_Greatest')}</div>
          <ul>
            {get(props.data, 'starContributors', [])
              .filter((u) => !u.leaderboardBlacklisted)
              .map((ev, i) => {
                return i === 0 ? (
                  <li key={ev.id} className="first">
                    {get(ev, 'profilePicture.uri', '') !== '' && (
                      <div className="medias">
                        <span
                          style={{
                            backgroundImage: `url(${
                              process.env.REACT_APP_IMG_URL
                            }/storage/user-picture/${get(ev, 'profilePicture.uri', '')})`,
                          }}
                        ></span>
                      </div>
                    )}
                    {get(ev, 'profilePicture.uri', '') === '' && (
                      <div className="medias">
                        <span
                          style={{ backgroundImage: `url('/assets/images/profile.png')` }}
                        ></span>
                      </div>
                    )}
                    <LinkOptional
                      to={`/profile/${ev.id}`}
                      disabled={!role || (role !== 'admin' && role !== 'super-admin' && role !== 'hotel')}
                    >
                      <div className="name">
                        {`${ev.name} ${ev.surname}`}
                      </div>
                    </LinkOptional>
                  </li>
                ) : (
                  <li key={ev.id}>
                    {get(ev, 'profilePicture.uri', '') !== '' && (
                      <div className="medias">
                        <span
                          style={{
                            backgroundImage: `url(${
                              process.env.REACT_APP_IMG_URL
                            }/storage/user-picture/${get(ev, 'profilePicture.uri', '')})`,
                          }}
                        ></span>
                      </div>
                    )}
                    {get(ev, 'profilePicture.uri', '') === '' && (
                      <div className="medias">
                        <span
                          style={{ backgroundImage: `url('/assets/images/profile.png')` }}
                        ></span>
                      </div>
                    )}
                    <LinkOptional
                      to={`/profile/${ev.id}`}
                      disabled={!role || (role !== 'admin' && role !== 'super-admin' && role !== 'hotel')}
                    >
                      <div className="name">
                        {`${ev.name} ${ev.surname}`}
                      </div>
                    </LinkOptional>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
  );
}

const LinkOptional = ({ to, className, children, disabled }) => {
  if (disabled) {
    return <span className={className}>{children}</span>
  }

  return <Link to={to} className={className}>{children}</Link>
}