import React, { useState } from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { sortByCreated } from "../../utils";
import get from "lodash.get";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

export default function QuizzEpisodesSlider(props) {
  const { serie, sorted } = props;
  if (serie && sorted) {
    serie.sort(sortByCreated);
  }
  const [swiper, setSwiper] = useState(null);
  SwiperCore.use([Navigation]);
  const initSwiper = (s) => {
    setSwiper(s);
  };

  return (
    <Swiper
      onSwiper={(swiper) => initSwiper(swiper)}
      slidesPerView={2}
      spaceBetween={10}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      breakpoints={{
        640: {
          slidesPerView: 3,
          spaceBetween: 16,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 16,
        },
        1280: {
          slidesPerView: 7,
          spaceBetween: 16,
        },
      }}
    >
      {serie &&
        serie.map((v, i) => (
          <SwiperSlide key={v.video.id}>
            <Link
              to={`/video?id=${v.video.id}`}
              style={{ color: "#d2d2d2", textDecoration: "none" }}
            >
              <div className="slide-inner">
                <div className="video-wrapper">
                  <div className="video-wrapper">
                    <div className="play">
                      <span className="icon-playbuttonsmall"></span>
                    </div>
                    {get(v.video, "videoThumbnail.uri", "") !== "" && (
                      <div
                        className="video"
                        style={{
                          backgroundImage:
                            get(v.video, "videoThumbnail.uri", "") !== ""
                              ? `url(${
                                process.env.REACT_APP_IMG_URL
                              }/storage/video-thumbnail/${get(
                                v.video,
                                "videoThumbnail.uri",
                                ""
                              )})`
                              : 'url("/assets/images/video-1.jpg")',
                        }}
                      ></div>
                    )}
                    {get(v.video, "videoThumbnail.uri", "") === "" && (
                      <div className="video-wrapper">
                        <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1}}/>
                        <iframe
                          style={{ width: '100%', height: '89.5px', zIndex: 0, borderRadius: '8px'}}
                          src={`//fast.wistia.net/embed/iframe/${v.video.wistiaHashId}?fitStrategy=cover&controls=false&endVideoBehavior=loop&playbar=false&playButton=false&settingsControl=false&volumeControl=false&silentAutoplay=allow&smallPlayButton=false&fullscreenButton=false`}
                          frameBorder="0"
                          scrolling="no"
                          // className="video-wrapper-iframe"
                          name="hero_embed"
                        ></iframe>
                      </div>
                    )}
                  </div>
                  <div className="video-infos">
                    <div className="video-title">{v.video.title} - {v.video?.description}</div>
                  </div>
                  </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      <div
        id="prevbutton"
        className="swiper-button-prev swiper-button-disabled"
        onClick={() => swiper.slidePrev()}
      ></div>
      <div
        id="nextbutton"
        className="swiper-button-next"
        onClick={() => swiper.slideNext()}
      ></div>
    </Swiper>
  );
}
