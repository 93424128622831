import React from 'react';

const Rotate = () => {
  return (
    <div className="rotate">
      <img className="rotate__image" src="/assets/rotate.png" alt="rotate" />
    </div>
  );
};

export default Rotate;
