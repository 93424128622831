import React from "react";
import { parseCategoryID } from "../../../utils/";
import get from "lodash.get";
import { Link } from "react-router-dom";

export default function seriesHero(props) {
  const { data } = props;

  return (
    <section className="series-header">
      <div className="viewport">
        <div className="inner">
          <div className="top">
            <div className="txt-wrapper">
              <h1>{data.title}</h1>
              <div className="author">
                {!data.speakers || !data.speakers?.length || !data.speakers?.filter(speaker => speaker !== null).length ? null : (
                  <span>with </span>
                )}
                {data &&
                  data.speakers &&
                  data.speakers.filter(speaker => speaker !== null).map((s, i) =>
                    data.speakers.length > 1 && i < data.speakers.length - 1 ? (
                      <Link
                        key={s.id}
                        to={`/speaker/${s.id}`}
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        <span className="hover-opacity">{s.name} {s.surname}, </span>
                      </Link>
                    ) : (
                      <Link
                        key={s.id}
                        to={`/speaker/${s.id}`}
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        <span className="hover-opacity">{s.name} {s.surname}</span>
                      </Link>
                    )
                  )}
              </div>
              <div className={`cat`}>
                <Link
                  to={`/category/${
                    parseCategoryID(data.category).categorySlug
                  }`}
                  style={{
                    color: `${parseCategoryID(data.category).categoryColor}`,
                    textDecoration: "none",
                  }}
                >{`${parseCategoryID(data.category).categoryName}`}</Link>
                <span> SERIES</span>
              </div>
              {data.isSustainability && (
                <div className="sustainability-tag">
                  <div className="sustainability-tag-wrapper">
                    <span className="icon-sustainability"></span>
                    <span className="text-sustainability">sustainability</span>
                  </div>
                </div>
              )}
            </div>
            <div className="img-wrapper">
              <div className="gradient"></div>
              <div className="medias">
                {get(data, "seriePicture.uri", "") &&
                  <span
                    style={{
                      backgroundPosition: "top",
                      backgroundImage: `url(${
                        process.env.REACT_APP_IMG_URL
                      }/storage/serie-picture/${get(data, "seriePicture.uri", "")}?format=Horizontal_North`,
                    }}
                  />
                }
              </div>
            </div>
          </div>
          <div className="description">
            <h2>{data.subtitle}</h2>
            <p>{data.description}</p>
          </div>
        </div>
      </div>
    </section>
  );
}
