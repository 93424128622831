import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import SeriesSlider from '../../ui/seriesSlider';
import { filterUnreleased, getNthItemFromPathname, sortByReleased } from '../../../utils';
import { getSpeaker, searchVideos, getSeriesBySpeaker } from '../../../utils/data';
import get from 'lodash.get';
import VideoItem from '../../ui/VideoItem';
import Banner1 from '../../layout/banner1';
import { useGlobalContext } from '../../../store/global'

const Speaker = (props) => {

  const { setLoading } = useGlobalContext()

  const [pageData, setPageData] = useState({
    speakerInfo: {},
    videosBySpeaker: [],
    seriesBySpeaker: [],
    videoLimit: 9,
  });

  useEffect(() => {
    const speakerId = getNthItemFromPathname(props.location.pathname, 2);
    async function getPageData() {
      setLoading(true)
      const speakerInfo = await getSpeaker(speakerId);
      const seriesBySpeaker = await getSeriesBySpeaker(speakerId);
      const videosBySpeaker = await searchVideos({ speakers: speakerId });
      setPageData((prevState) => ({
        ...prevState,
        speakerInfo: speakerInfo.data,
        videosBySpeaker: videosBySpeaker.data,
        seriesBySpeaker: seriesBySpeaker.data,
      }));
      setLoading(false)
    }
    getPageData();
  }, [props.location.pathname]);

  const displayMore = async () => {
    setPageData((prevState) => ({
      ...prevState,
      videoLimit: prevState.videoLimit + 9,
    }));
  };
  // {`${get(pageData, 'speakerInfo.city.name', '')}, ${get(pageData, 'speakerInfo.country.name', '')} - ${get(pageData, 'speakerInfo.hotel.name', '')}`}
  return (
    <div id="content">
      <section className="speaker-header">
        <div className="viewport">
          <div className="inner">
            <div className="top">
              <div className="txt-wrapper">
                <h1>{pageData.speakerInfo.name} {pageData.speakerInfo.surname}</h1>
                <div className="job-title">{pageData.speakerInfo.jobTitle}</div>
                {get(pageData, 'speakerInfo.city.name', '') !== '' &&
                  get(pageData, 'speakerInfo.country.name', '') !== '' &&
                  get(pageData, 'speakerInfo.hotel.name', '') !== '' && (
                    <div className="infos">{`${get(pageData, 'speakerInfo.city.name', '')}, ${get(
                      pageData,
                      'speakerInfo.country.name',
                      ''
                    )} ${get(pageData, 'speakerInfo.hotel.name', '')} ${get(
                      pageData,
                      'speakerInfo.hotel.name',
                      ''
                    )}`}</div>
                  )}
              </div>
              <div className="img-wrapper">
                <div className="gradient"></div>
                <div className="medias">
                  <span
                    style={{
                      backgroundPosition: "top",
                      backgroundImage: `url(${
                        process.env.REACT_APP_IMG_URL
                      }/storage/speaker-picture/${get(
                        pageData.speakerInfo,
                        'speakerPicture.uri',
                        ''
                      )}?format=Horizontal_North`,
                    }}
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="series">
        <div className="viewport">
          <div className="inner">
            <h2 className="title">Series by this speaker</h2>
            <SeriesSlider series={pageData.seriesBySpeaker} sorted />
          </div>
        </div>
      </section>

      <section className="series-videos">
        <div className="viewport">
          <div className="inner">
            <h2 className="title">
              All Videos{' '}
              <span className="vid-count">
                {`(${pageData.videosBySpeaker.filter(filterUnreleased).length} video${pageData.videosBySpeaker.filter(filterUnreleased).length > 1 ? 's' : ''})`}
              </span>
            </h2>
            <div className="video-list">
              {pageData.videosBySpeaker
                .sort(sortByReleased)
                .filter(filterUnreleased)
                .map((v, i) =>
                  i < pageData.videoLimit ? <VideoItem key={v.id} video={v} /> : null
                )}
              {pageData.videosBySpeaker.length > pageData.videoLimit && (
                <div className="load-more" style={{ margin: '0px auto' }}>
                  <button className="small tertiary" onClick={displayMore}>
                    <span>Load more</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <Banner1 />
    </div>
  );
};

export default withRouter(Speaker);
