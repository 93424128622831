import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { parseCategoryID } from "../../utils/index";
import get from "lodash.get";
import {getQuizById} from "../../utils/data";
import { useTranslation } from 'react-i18next'

function QuizItem(props) {
  const [quiz, setQuiz] = useState(props.quiz);

  const [isPassed, setIsPassed] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const { t } = useTranslation();

  useEffect( () => {
    getQuizzes();
  }, [quiz]);

  const getQuizzes = async () => {
    if (!quiz) return;

    let quizResults = await getQuizById(quiz.id);
    let prevQuiz = quizResults.data.previousResults;

    setIsPassed(false);
    setIsDone(false);

    if (prevQuiz && prevQuiz.length) {
      setIsDone(true);

      let orderedQuizzes = [...prevQuiz];
      orderedQuizzes.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });

      console.log("kkkk order", orderedQuizzes);

      orderedQuizzes = orderedQuizzes.filter((e) => e.passed);

      console.log("kkkk filted", orderedQuizzes);

      let biggerQuizz;
      for (const ans of orderedQuizzes) {
        if (!biggerQuizz || ans.score > biggerQuizz.score) {
          biggerQuizz = ans;
        }
      }

      console.log("kkkk selected", biggerQuizz);

      if (biggerQuizz) {
        setIsPassed(true);
        return;
      }
    }
  }

  return (
    <div className="quiz-item">
      <div className="inner">
        <div className="player-wrapper">
          <div className="cover"></div>
          {get(quiz, "thumbnail.uri", "") !== "" && (
            <div
              className="quiz-wrapper"
              style={{
                backgroundImage:
                  get(quiz, "thumbnail.uri", "") !== ""
                    ? `url(${process.env.REACT_APP_IMG_URL
                    }/storage/quiz-thumbnail/${get(
                      quiz,
                      "thumbnail.uri",
                      ""
                    )})`
                    : 'url("/assets/images/video-1.jpg")',
              }}
            ></div>
          )}
          {isDone === true && isPassed === true && (
              <div className="quiz-passed"><img className="icon" src="/assets/images/passed.png" />{t('Passed')}</div>
          )}
          {isDone === true && isPassed === false && (
              <div className="quiz-failed">{t('Failed')}</div>
          )}
        </div>
        <div className="infos">
          <div className="category-wrapper">
            <div
              className={`cat ${parseCategoryID(get(quiz, "category.id", "")).categoryShorthand
                }`}
            >
              {parseCategoryID(get(quiz, "category.id", "")).categoryName}
            </div>
            {props.isSustainability && (
              <div className="sustainability-tag-wrapper">
                <span className="icon-sustainability"></span>
                <span className="text-sustainability">sustainability</span>
              </div>
            )}
          </div>
          <div className="quiz-title">{quiz?.title}</div>
          <div className="quiz-description">{quiz?.description}</div>
          <div className="quiz-buttons">
            {quiz.serie && (
              <Link
                className="quiz-button series"
                to={`/series/${quiz?.serie}`}
              >
                <span className="icon-playbuttonbold"></span>
                {t('Watch_The_Series')}
              </Link>
            )}

            <Link
              className="quiz-button link"
              to={`/quiz?id=${quiz?.id}`}
            >
              {isDone ? t("Retake") : t("Start")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(QuizItem);
