import React, { useState, useEffect, useCallback, useMemo } from "react";
import { withRouter } from "react-router";
import Notifications from "./notifications";
import { useGlobalContext } from "../../store/global";
import { Link, useLocation } from "react-router-dom";
import {
  getMyNotifications,
  getProfile,
  updateUserLanguage,
} from "../../utils/data";
import tracking from "../../utils/tracking";
import moment from "moment";
import throttle from "lodash.throttle";
import { getAnnouncement } from "../../utils/data";

import NavSearchBar from "./navSearchBar";
import NavCategoriesSelector from "./navCategoriesSelector";
import NavLanguageSwitcher from "./navLanguageSwitcher";
import NavProfileSelector from "./navProfileSelector";
import NavMobile from "./navMobile";
import { useTranslation } from "react-i18next";
// import Notifications from './notifications';

const isActive = (path, location) => {
  if (path === "/" && !location) {
    return "active";
  }

  if (path === "/my-list" && location === "my-list") return "active";

  if (path === "/search" && location === "search") return "active";

  if (path === "/leaderboard" && location === "leaderboard") return "active";

  if (path === "/profile" && location === "profile") return "active";

  if (path.includes("/category")) {
    if (path.includes("/fb") && location === "fb") return "active";

    if (path.includes("/operations") && location === "operations")
      return "active";

    if (path.includes("/sm") && location === "sm") return "active";
  }

  return "";
};

const Header = (props) => {
  const { t } = useTranslation();
  let location = useLocation();
  const {
    logout,
    hasRegistered,
    openModal,
    closeModal,
    setHasRegistered,
    userAuthed,
    role,
    setUser,
  } = useGlobalContext();
  const [pageData, setPageData] = useState({
    showReg: false,
    showAnnouncement: false,
  });
  const [notifsList, setNotifsList] = useState([]);
  const [currentLang, setCurrentLang] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [showModal, setShowModal] = useState(false);

  let menuState = false;

  const handleScroll = () => {
    if (window.pageYOffset > 50) {
      document.body.classList.add("scrolled");
    } else if (document.body.classList.contains("scrolled")) {
      document.body.classList.remove("scrolled");
    }
  };

  const callThrottle = useCallback(throttle(handleScroll, 300), []);

  const haveNewNotif = useMemo(() => {
    for (const notif of notifsList) {
      if (notif.status !== "read") {
        return true;
      }
    }

    return false;
  }, [notifsList]);

  const languages = {
    english: "English",
    japanese: "日本語",
    mandarin: "中文",
  };

  useEffect(() => {
    if (window.pageYOffset > 50) document.body.classList.add("scrolled");

    window.addEventListener("scroll", callThrottle);
    return () => window.removeEventListener("scroll", callThrottle);
  }, []);

  useEffect(() => {
    if (!hasRegistered && userAuthed) {
      getProfile().then((res) => {
        setUserInfo(res?.data);
        setUser(res?.data);
        setCurrentLang(
          res?.data?.preferredLanguage
            ? languages[res.data.preferredLanguage]
            : "English"
        );

        if (!localStorage.getItem("lang")) {
          localStorage.setItem(
            "lang",
            res?.data?.preferredLanguage
              ? res.data.preferredLanguage
              : "english"
          );
          window.location.reload();
        }
        localStorage.setItem(
          "lang",
          res?.data?.preferredLanguage ? res.data.preferredLanguage : "english"
        );

        if (!res?.data?.country) {
          setPageData((prevState) => ({
            ...prevState,
            showReg: true,
          }));
        } else {
          const lastSeen = res?.data.latestAnnouncementSeen;
          if (!lastSeen || !moment().isSame(lastSeen, "day")) {
            getAnnouncement().then((res) => {
              if (res?.data.length > 0) {
                setPageData((prevState) => ({
                  ...prevState,
                  showAnnouncement: true,
                }));
              }
            });
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (pageData.showReg) {
      openModal("registration", false, regStepOne);
    }
    if (!pageData.showReg && pageData.showAnnouncement) {
      openModal("announcement", true);
    }
  }, [pageData.showReg, pageData.showAnnouncement, hasRegistered]);

  useEffect(() => {
    fetchNotif();
    const interval = setInterval(() => {
      fetchNotif();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const fetchNotif = async () => {
    const notifs = await getMyNotifications();
    setNotifsList(notifs?.data || []);
  };

  const regStepOne = (e) => {
    e.preventDefault();
    setHasRegistered(true);
    openModal("profile-pic", true, regStepTwo, () =>
      openModal("registration", true, regStepOne)
    );
  };

  const regStepTwo = () => {
    closeModal();
  };

  const toggleMenu = () => {
    if (!menuState) {
      document.getElementById("body").classList.add("menu-opened");
      menuState = true;
    } else {
      document.getElementById("body").classList.remove("menu-opened");
      menuState = false;
    }
  };

  const handleSwitchLang = (lang) => {
    setCurrentLang(languages[lang]);
    updateUserLanguage(userInfo, lang);
    localStorage.setItem("lang", lang);

    document.getElementById("languages-content").classList.remove("tab-opened");
    document.getElementById("languages-tab").classList.remove("active");
    document
      .getElementById("languages-content-mobile")
      .classList.remove("tab-opened");
    document.getElementById("languages-tab-mobile").classList.remove("active");

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleLogout = () => {
    logout();
    window.location.href = process.env.REACT_APP_SSO_URL;
  };

  const openNotifications = () => {
    document.getElementById("body").classList.add("notifications-opened");
  };

  const closeNotifications = (e) => {
    document.getElementById("body").classList.remove("notifications-opened");
  };

  const pageClicked = (page, value) => {
    tracking.event("header", page, value);
  };

  const handleOpenPopup = () => {
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <header>
      <div className="nav-menu left">
        <div
          className="visible-md burger icon icon-burger hidden-md"
          onClick={toggleMenu}
        ></div>
        <Link
          to="/"
          onClick={() => pageClicked("/home")}
          className={`home icon icon-home hidden-md`}
        >
          <span>{t("Home")}</span>
        </Link>
        {/*<Link*/}
        {/*  to="/category/fb"*/}
        {/*  onClick={() => pageClicked("/category", "F&B")}*/}
        {/*  className={`${isActive(location.pathname, "fb")} hidden-md`}*/}
        {/*>*/}
        {/*  F&B*/}
        {/*</Link>*/}
        {/*<Link*/}
        {/*  to="/category/operations"*/}
        {/*  onClick={() => pageClicked("/category", "OPS|HR")}*/}
        {/*  className={`${isActive(location.pathname, "operations")} hidden-md`}*/}
        {/*>*/}
        {/*  OPS | HR*/}
        {/*</Link>*/}
        {/*<Link*/}
        {/*  to="/category/sm"*/}
        {/*  onClick={() => pageClicked("/category", "S&M")}*/}
        {/*  className={`${isActive(location.pathname, "sm")} hidden-md`}*/}
        {/*>*/}
        {/*  S&M*/}
        {/*</Link>*/}

        <NavCategoriesSelector pageClicked={pageClicked} />

        {/*<Link*/}
        {/*  to="/search"*/}
        {/*  onClick={() => pageClicked("/search")}*/}
        {/*  className={`${isActive(location.pathname, "search")} hidden-md`}*/}
        {/*>*/}
        {/*  Search*/}
        {/*  <div className="search-button" />*/}
        {/*</Link>*/}

        {/* <NavSearchBar
          pageClicked={pageClicked}
          isMobile={false}
        /> */}

        <Link
          to="/search"
          onClick={() => pageClicked("/search")}
          // className={`${isActive(location.pathname, "leaderboard")} hidden-md`}
          className={`hidden-md`}
        >
          {t("Search")}
          <span className="icon-search" />
        </Link>

        {/* <Link
          to="/leaderboard"
          onClick={() => pageClicked("/leaderboard")}
          // className={`${isActive(location.pathname, "leaderboard")} hidden-md`}
          className={`hidden-md`}
        >
          {t('Leaderboard')}
        </Link> */}
        {(userInfo?.role === "admin" || userInfo?.role === "super-admin") && (
          <div style={{ marginTop: 4 }}>
            <a
              target="_blank"
              className={`hidden-md`}
              href={`/admin/manager-statistics`}
              style={{
                borderRadius: 8,
                padding: "4px 12px",
                background: "#1D1D1D",
              }}
            >
              Hotel statistics
            </a>
          </div>
        )}
        {userInfo?.role === "hotel" && (
          <div style={{ marginTop: 4 }}>
            <Link
              to="/statistics"
              className={`hidden-md`}
              style={{
                borderRadius: 8,
                padding: "4px 12px",
                background: "#1D1D1D",
              }}
            >
              Hotel statistics
            </Link>
          </div>
        )}
        {/* <Link to="/" className="icon-IDEAS second-logo"></Link> */}
      </div>

      <Link
        to="/"
        onClick={() => {
          setShowModal(false);
          document
            .getElementById("mobile-menu-tab")
            .classList.remove("menu-opened")
        }}
        className="logo icon-IDEAS"></Link>
      <div className="visible-sm nav-menu right">
        {/*<Link to="/search" className="visible-md icon icon-search"></Link>*/}
        {/*<Link*/}
        {/*  to="/search?q=&tags=&filter=quiz"*/}
        {/*  onClick={() => pageClicked("/search", "quiz")}*/}
        {/*  className={`${isActive(location.pathname, "quizzes")} hidden-md`}*/}
        {/*>*/}
        {/*  Quizzes*/}
        {/*</Link>*/}
        <a
          target="_blank"
          className={`hidden-md`}
          href="https://www.hyattdigitalexhibition.com/"
        >
          <img src="/assets/images/hde.png" />
        </a>


        <NavLanguageSwitcher
          currentLang={currentLang}
          handleSwitchLang={handleSwitchLang}
          isMobile={false}
        />
        {/*<Link*/}
        {/*  to="/my-list"*/}
        {/*  onClick={() => pageClicked("/my-list")}*/}
        {/*  className={`${isActive(location.pathname, "my-list")} hidden-md`}*/}
        {/*>*/}
        {/*  My lists*/}
        {/*</Link>*/}
        {/*<Link*/}
        {/*  to="/profile"*/}
        {/*  onClick={() => pageClicked("/profile")}*/}
        {/*  className={`${isActive(location.pathname, "profile")} hidden-md`}*/}
        {/*>*/}
        {/*  Account*/}
        {/*</Link>*/}

        <NavProfileSelector
          pageClicked={pageClicked}
          handleLogout={handleLogout}
          userInfo={userInfo}
          isMobile={false}
          handleOpenPopup={handleOpenPopup}
        />

        <Notifications inWrapper={false} />

        {/*<Link*/}
        {/*  to="/"*/}
        {/*  className="hidden-md icon logout icon-logout"*/}
        {/*  onClick={handleLogout}*/}
        {/*></Link>*/}

        {/*<div*/}
        {/*  // className="visible-md burger icon icon-burger"*/}
        {/*  className={"visible-md burger icon " + (haveNewNotif ? "icon-burger red" : "icon-burger")}*/}
        {/*  onClick={toggleMenu}*/}
        {/*></div>*/}

        <NavMobile
          pageClicked={pageClicked}
          haveNewNotif={haveNewNotif}
          currentLang={currentLang}
          handleSwitchLang={handleSwitchLang}
          handleLogout={handleLogout}
          userInfo={userInfo}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </div>

      {/*<div className="nav-wrapper" onClick={toggleMenu}>*/}
      {/*  <div className="nav-inner" onClick={(e) => e.stopPropagation()}>*/}
      {/*    <div className="close icon-x" onClick={toggleMenu}></div>*/}
      {/*    <h2>Browse I.D.E.A.S.</h2>*/}
      {/*    <ul>*/}
      {/*      <li>*/}
      {/*        <Link*/}
      {/*          to="/category/fb"*/}
      {/*          onClick={() => {*/}
      {/*            toggleMenu();*/}
      {/*            pageClicked("/category", "F&B");*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          F&B*/}
      {/*        </Link>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        <Link*/}
      {/*          to="/category/operations"*/}
      {/*          onClick={() => {*/}
      {/*            toggleMenu();*/}
      {/*            pageClicked("/category", "OPS|HR");*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          OPS | HR*/}
      {/*        </Link>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        <Link*/}
      {/*          to="/category/sm"*/}
      {/*          onClick={() => {*/}
      {/*            toggleMenu();*/}
      {/*            pageClicked("/category", "S&M");*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          S&M*/}
      {/*        </Link>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        <Link*/}
      {/*          to="/leaderboard"*/}
      {/*          onClick={() => {*/}
      {/*            toggleMenu();*/}
      {/*            pageClicked("/leaderboard");*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          Leaderboard*/}
      {/*        </Link>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        <Link*/}
      {/*          to="/search?q=&tags=&filter=quiz"*/}
      {/*          onClick={() => {*/}
      {/*            toggleMenu();*/}
      {/*            pageClicked("/search", "quiz");*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          Quizzes*/}
      {/*        </Link>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        <Link*/}
      {/*          to="/my-list"*/}
      {/*          onClick={() => {*/}
      {/*            toggleMenu();*/}
      {/*            pageClicked("/my-list");*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          My List*/}
      {/*        </Link>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        <Link*/}
      {/*          to="/profile"*/}
      {/*          onClick={() => {*/}
      {/*            toggleMenu();*/}
      {/*            pageClicked("/profile");*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          Account*/}
      {/*        </Link>*/}
      {/*      </li>*/}
      {/*    </ul>*/}

      {/*    <a className="logout" onClick={logout}>*/}
      {/*      <span className="icon-logout"></span> Logout*/}
      {/*    </a>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </header>
  );
};

export default withRouter(Header);
