import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import NavCategoriesSelector from "./navCategoriesSelector";
import NavLanguageSwitcher from "./navLanguageSwitcher";
import Notifications from "./notifications";
import NavProfileSelector from "./navProfileSelector";
import NavSearchBar from "./navSearchBar";
import { useTranslation } from "react-i18next";

const NavMobile = ({
  pageClicked,
  haveNewNotif,
  currentLang,
  handleSwitchLang,
  handleLogout,
  userInfo,
  showModal,
  setShowModal
}) => {
  const { t } = useTranslation();
  const ref = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    setShowModal((prev) => {
      if (prev) {
        if (!ref.current.contains(event.target)) {
          handleClick(event);
        }
      }

      return prev;
    });
  };

  const handleClick = (e) => {
    setShowModal((prev) => {
      if (prev) {
        document
          .getElementById("mobile-menu-tab")
          .classList.remove("menu-opened");
      } else {
        document.getElementById("mobile-menu-tab").classList.add("menu-opened");
        return true;
      }
      return false;
    });
  };

  const handleOpenPopup = () => {
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <>
      <NavSearchBar
        pageClicked={pageClicked}
        isMobile={true}
        parentModalClick={handleClick}
        handleClick={() => {
          setShowModal(false);
          document
            .getElementById("mobile-menu-tab")
            .classList.remove("menu-opened");
        }}
      />
      <div
        className={
          "visible-md burger icon " +
          (haveNewNotif ? "icon-burger red" : "icon-burger")
        }
        onClick={handleClick}
      ></div>

      <div id="mobile-menu-tab" className="visible-md nav-mobile-wrapper">
        <div id="mobile-menu-content" className="tab-select" ref={ref}>
          <NavCategoriesSelector
            pageClicked={pageClicked}
            isMobile={true}
            parentModalClick={handleClick}
          />
          {/* <Link
            to="/leaderboard"
            onClick={() => {
              handleClick();
              pageClicked("/leaderboard");
            }}
            className="tab-name"
          >
            {t("Leaderboard")}
          </Link> */}
          <NavLanguageSwitcher
            currentLang={currentLang}
            handleSwitchLang={handleSwitchLang}
            isMobile={true}
            parentModalClick={handleClick}
          />
          <Notifications
            inWrapper={true}
            isMobile={true}
            parentModalClick={handleClick}
          />
          <NavProfileSelector
            pageClicked={pageClicked}
            handleLogout={handleLogout}
            userInfo={userInfo}
            isMobile={true}
            parentModalClick={handleClick}
            handleOpenPopup={handleOpenPopup}
          />
          {(userInfo?.role === "admin" || userInfo?.role === "super-admin") && (
            <a
              target="_blank"
              href={`/admin/manager-statistics`}
              className="tab-name"
            >
              Hotel statistics
            </a>
          )}
          {userInfo?.role === "hotel" && (
            <Link
              to="/statistics"
              onClick={() => {
                handleClick();
                pageClicked("/statistics");
              }}
              className="tab-name"
            >
              Hotel statistics
            </Link>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a target="_blank" href="https://www.hyattdigitalexhibition.com/">
              <img src="/assets/images/hde.png" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavMobile;
