import React from 'react';
import photo1 from './compressed/classics-1.jpg';
import photo2 from './compressed/classics-2.jpg';
import photo3 from './compressed/classics-3.jpg';
import photo4 from './compressed/classics-4.jpg';
import { useTranslation } from 'react-i18next'
import Hyatt from './hyatt-logo.png';
import Classic from './classics-logo.png';

const IMAGE_LIST = [
    { tag: 'Buffets', src: photo1 },
    { tag: 'Dinner', src: photo2 },
    { tag: 'Buffets', src: photo3 },
    { tag: 'Afternoon tea', src: photo4 },
]

const HyattPhotography = () => {
    const { t } = useTranslation();

    return (
        <section className="hyattfbphoto">
            <div className="viewport">
                <div className="inner">
                    <div className='window-wrapper'>
                        <div className='info-wrapper'>
                            <div className='title-wrapper'>
                                <img src={Hyatt} className='hyatt-logo' alt='hyatt-logo' />
                                <img src={Classic} className='classic-logo' alt='classic-logo' />
                                <span className='title orange'>
                                    {t('Recipes')}
                                </span>
                                {/* <div>
                                    <span className='title'>
                                        EVENTS + F&B
                                    </span>
                                    <span className='title orange'>
                                        {' LIBRARY'}
                                    </span>
                                </div> */}
                            </div>

                            <a className='button' href="/classics">
                                <span className='text'>
                                    {t("Explore")}
                                </span>
                            </a>
                        </div>

                        <div className='items-wrapper'>
                            {IMAGE_LIST.map((e,  i) =>
                                <div
                                    key={i}
                                    className='item'
                                    style={{ position: 'relative' }}
                                >
                                    <img src={e.src} alt={e.tag} style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                        borderRadius: 16 }}/>
                                    {/* <div className='tag-wrapper' style={{ position: 'absolute' }}>
                                        <span className='tag-text'>
                                            {e.tag}
                                        </span>
                                    </div> */}
                                </div>
                            )}
                            <div className='layer-mobile' />
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default HyattPhotography;