import React from "react";
import { useTranslation } from 'react-i18next'

const SearchBar = ({
  searchValue,
  onSearchChange,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className="center-title">DIRECTORY</h2>
                
      <div className="form-item form-search">
        <div
          className="search-button"
          onClick={onClick}
        />
        <input
          id="searchbar"
          type="text"
          autoComplete="off"
          // placeholder="Quizzes, episodes, series, speakers"
          placeholder={t('Search_By_Theme_Topi_Or_Speaker')}
          value={searchValue}
          onKeyPress={(e) => {
            if (e.key === "Enter") onClick();
          }}
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </div>
    </>
  );
};

export default SearchBar;