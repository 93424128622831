import React, { useState, useEffect } from "react";
import Wrapper from "../wrapper";
import SearchBar from "./searchBar";
import moment from "moment/moment";
import Select from "react-select";
import "./styles.scss";
import { searchSeries } from "../../../utils/data";
import { Link } from "react-router-dom";
import category from "../category";
import { useTranslation } from "react-i18next";

const PAGE_SIZE = 30;

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay ?? 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

const Directory = () => {
  // const ref = useRef(null);
  const { t } = useTranslation();
  const [q, setQ] = useState("");
  const debouncedQ = useDebounce(q, 500);
  const [selectedCategory, setSelectedCategory] = useState("fb");
  const [selectedSerie, setSelectedSerie] = useState();
  const [showOrder, setShowOrder] = useState(false);
  const [order, setOrder] = useState("latest");
  const [series, setSeries] = useState();
  const [totalSeries, setTotalSeries] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  // useOutsideAlerter(ref, () => {});

  const onCategoryClick = (category) => {
    setSelectedCategory(category);
    setSelectedSerie(undefined);
  };

  const onSerieClick = (serie) => {
    if (serie === selectedSerie) {
      setSelectedSerie(undefined);
      return;
    }

    setSelectedSerie(serie);
  };

  const onOrderClick = (order) => {
    setOrder((e) => (e === order ? undefined : order));
    setShowOrder(false);
  };

  const getNextPage = async () => {
    setIsLoading(true);
    const nexPage = page + 1;
    const res = await searchSeries({
      q: debouncedQ,
      range: `[${nexPage * PAGE_SIZE},${
        nexPage * PAGE_SIZE + (PAGE_SIZE - 1)
      }]`,
      category: CATS[selectedCategory].id,
      sort: `["createdAt", "${order === 'earliest' ? 'ASC' : 'DESC'}"]`
    });
    setSeries((e) => e.concat(res?.data));
    setTotalSeries(
      parseInt(res?.headers?.["content-range"]?.split(" ")[1]?.split("/")[1])
    );
    setPage((e) => (res.data.length < PAGE_SIZE ? null : nexPage));
    setIsLoading(false);
  };

  useEffect(() => {
    const launchSearch = async () => {
      const res = await searchSeries({
        q: debouncedQ,
        range: `[0,${PAGE_SIZE - 1}]`,
        category: CATS[selectedCategory].id,
        sort: `["createdAt", "${order === 'earliest' ? 'ASC' : 'DESC'}"]`
      });
      setSeries(res?.data);
      setTotalSeries(
        parseInt(res?.headers?.["content-range"]?.split(" ")[1]?.split("/")[1])
      );
      if (res.data.length < PAGE_SIZE) {
        setPage(null)
      }
      setIsLoading(false);
    };

    setIsLoading(true);
    setSeries(undefined);
    launchSearch();
    setPage(0);
  }, [debouncedQ, selectedCategory, order]);

  const CATS = {
    fb: {
      id: process.env.REACT_APP_FB,
      label: "FOOD & BEVERAGE",
      src: "/assets/images/logo-fnb.png",
    },
    ops: {
      id: process.env.REACT_APP_OPERATIONS,
      label: <>OPERATIONS & <span style={{ whiteSpace: 'nowrap' }}>HUMAN RESOURCE</span></>,
      src: "/assets/images/logo-ope.png",
    },
    sm: {
      id: process.env.REACT_APP_SM,
      label: "SALES & MARKETING",
      src: "/assets/images/logo-snm.png",
    },
  };

  const parseTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.round(time - minutes * 60, 2);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const isEmpty = !isLoading && !series?.length;

  return (
    <Wrapper>
      <div id="content">
        <section className={`directory ${selectedCategory}`}>
          <div className="viewport">
            <div className="inner">
              <SearchBar
                searchValue={q}
                onSearchChange={setQ}
                onClick={() => {}}
              />

              <div className="tab-wrapper">
                {Object.keys(CATS).map((cat, index) => (
                  <div key={cat.id} onClick={() => onCategoryClick(cat)}>
                    <img src={CATS[cat].src} />
                    <span>{CATS[cat].label}</span>
                  </div>
                ))}
              </div>

              {/* <div className="filter-wrapper">
                                <div className="filter">
                                    <div className="text">
                                    <span className="icon-sorting"></span>
                                    Sort by
                                    </div>

                                    <div className="form-item form-filter">
                                    <Select
                                        className={`search-categories_container category`}
                                        classNamePrefix="search-categories"
                                        isSearchable={false}
                                        value={selectedCategory || { value: '', label: 'Default' }}
                                        onChange={(e) => setSelectedCategory(e)}
                                        options={[
                                            { value: 'latest', label: 'Latest' },
                                            { value: 'earliest', label: 'Earliest' },
                                        ]}
                                    />
                                    </div>
                                </div>
                            </div> */}

              <div className="summary">
                <span>Summary</span>

                {!isLoading && <div className="order">
                  <span className="label">
                    <span className="icon-sorting" />
                    Sort by
                  </span>

                  <span
                    className="value"
                    onClick={() => setShowOrder((e) => !e)}
                  >
                    {!order
                      ? "Default"
                      : { latest: "Latest", earliest: "Earliest" }[order]}

                    <div className={`overlay${showOrder ? " open" : ""}`} />

                    <div
                      // ref={ref}
                      className={`popup${showOrder ? " open" : ""}`}
                    >
                      <div
                        className={order === "latest" ? "active" : ""}
                        onClick={() => onOrderClick("latest")}
                      >
                        <span className="icon-check" />
                        <span>Latest</span>
                      </div>
                      <div
                        className={order === "earliest" ? "active" : ""}
                        onClick={() => onOrderClick("earliest")}
                      >
                        <span className="icon-check" />
                        <span>Earliest</span>
                      </div>
                    </div>
                  </span>
                </div>}
              </div>

              <div>
                {series?.map((serie, index) => (
                  <div
                    key={serie.id}
                    className={`item${
                      serie.id === selectedSerie ? " active" : ""
                    }`}
                  >
                    <div
                      className="title"
                      onClick={() => onSerieClick(serie.id)}
                    >
                      <span className="name">{`${order === 'earliest' ? index + 1 : totalSeries - index}. ${
                        serie.title
                      }`}</span>

                      <div className="total">
                        <span>{`${serie.videos?.length} Episode${serie.videos?.length > 1 ? 's': ''}`}</span>
                        <span className="icon-chevronright2" />
                      </div>
                    </div>
                    <div className="episodes">
                      {serie.videos
                        ?.sort((a, b) => a.order - b.order)
                        .map((episode, jndex) => (
                          <Link
                            key={`${index}-${jndex}`}
                            to={`/video?id=${episode.video.id}`}
                            className={`episode${
                              episode.video.userComplete ? " watched" : ""
                            }`}
                          >
                            <div className="wrapper-info">
                              <div className="name">
                                <span className="icon-check" />
                                <span>{`${jndex + 1}. ${episode.video.description}`}</span>
                                {/* <span>{episode.video.title}</span> */}
                              </div>
                              <div className="time">
                                <span>{parseTime(episode.video.duration)}</span>
                                <span>{`Realeased on ${moment(
                                  episode.video.releaseDate
                                ).format("MMM DD, YYYY")}`}</span>
                              </div>
                            </div>
                            <span className="mobile-released">{`Realeased on ${moment(
                              episode.video.releaseDate
                            ).format("MMM DD, YYYY")}`}</span>
                          </Link>
                        ))}
                    </div>
                  </div>
                ))}
              </div>

              {isEmpty && (
                <div className="empty">
                  <span style={{ fontSize: 17 }}>No series found</span>
                </div>
              )}

              {page !== null && !isEmpty && (
                <div className="btn-wrapper">
                  <button
                    disabled={isLoading}
                    className="tertiary small"
                    onClick={getNextPage}
                  >
                    <span>{isLoading ? t("Loading") : t("Discover_More")}</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

export default Directory;
