import React from 'react';
import { useGlobalContext } from '../../../store/global';
import { withRouter } from 'react-router';

const ResetSuccess = (props) => {
  const { closeModal } = useGlobalContext();

  const goToAuth = () => {
    closeModal();
    props.history.push('/auth');
  };

  return (
    <div className="popup-inner" style={{ width: 'unset' }}>
      <div className="popup-title">
        <h2>Password Reset</h2>
        <div className="close icon-x" onClick={closeModal}></div>
      </div>

      <div className="popup-content">
        <p>Your password has been reset successfully</p>
        <div className="popup-action">
          <button className="primary big" onClick={goToAuth}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ResetSuccess);
