import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next'

export default function Stats(props) {
  const { stats } = props;

  const [active, setActive] = useState(true);

  const { t } = useTranslation();

  return (
    <VisibilitySensor active={active}>
      {({ isVisible }) => (
        <section className="infographics">
          <div className="viewport">
            <div className="inner">
              <div className="txt-wrapper">
                <div className="home-header-ideas">
                  <p className="home-header-ideas__word">
                    {t('INSPIRATION')}
                  </p>
                  <p className="home-header-ideas__word">
                    {t('DIRECTION')}
                  </p>
                  <p className="home-header-ideas__word">
                    {t('EXCELLENCE')}
                  </p>
                  <p className="home-header-ideas__word">
                    {t('APPLICATION')}
                  </p>
                  <p className="home-header-ideas__word">
                    {t('SOLUTION')}
                  </p>
                </div>
                <p className="description">{stats.text}</p>
              </div>
            </div>
            <div className="inner">
              <div className="item">
                <div className="info-inner">
                  <CountUp
                    end={stats.countries}
                    duration={2}
                    start={isVisible ? 0 : null}
                    delay={0}
                    // onEnd={() => setActive(false)}
                    onStart={() => setActive(false)}
                  >
                    {({ countUpRef }) => (
                      <div ref={countUpRef} className="num">
                        {active ? 0 : stats.countries}
                      </div>
                    )}
                  </CountUp>
                  {/*<div className="unit">Countries</div>*/}
                  <div className="unit">{t('Markets')}</div>
                </div>
              </div>
              <div className="item">
                <div className="info-inner">
                  <CountUp
                    end={stats.hotels}
                    duration={2}
                    start={isVisible ? 0 : null}
                    delay={0}
                    onStart={() => setActive(false)}
                  >
                    {({ countUpRef }) => (
                      <div ref={countUpRef} className="num">
                        {active ? 0 : stats.hotels}
                      </div>
                    )}
                  </CountUp>
                  <div className="unit">{t('Hotels')}</div>
                </div>
              </div>
              <div className="item">
                <div className="info-inner">
                  <CountUp
                    end={stats.series}
                    duration={2}
                    start={isVisible ? 0 : null}
                    delay={0}
                    onStart={() => setActive(false)}
                  >
                    {({ countUpRef }) => (
                      <div ref={countUpRef} className="num">
                        {active ? 0 : stats.series}
                      </div>
                    )}
                  </CountUp>
                  <div className="unit">{t('Series')}</div>
                </div>
              </div>
              <div className="item">
                <div className="info-inner">
                  <CountUp
                    end={stats.episodes}
                    duration={2}
                    start={isVisible ? 0 : null}
                    delay={0}
                    onStart={() => setActive(false)}
                  >
                    {({ countUpRef }) => (
                      <div ref={countUpRef} className="num">
                        {active ? 0 : stats.episodes}
                      </div>
                    )}
                  </CountUp>
                  <div className="unit">{t('Episodes')}</div>
                </div>
              </div>
              <div className="item">
                <div className="info-inner">
                  <CountUp
                      end={stats.quizzes}
                      duration={3}
                      start={isVisible ? 0 : null}
                      delay={0}
                      onStart={() => setActive(false)}
                  >
                    {({ countUpRef }) => (
                        <div ref={countUpRef} className="num">
                          {active ? 0 : stats.quizzes}
                        </div>
                    )}
                  </CountUp>
                  <div className="unit">{t('Certificates')}</div>
                </div>
              </div>
              <div className="item">
                <div className="info-inner">
                  <CountUp
                    end={stats.users}
                    duration={3}
                    start={isVisible ? 0 : null}
                    delay={0}
                    onStart={() => setActive(false)}
                  >
                    {({ countUpRef }) => (
                      <div ref={countUpRef} className="num">
                        {active ? 0 : stats.users}
                      </div>
                    )}
                  </CountUp>
                  <div className="unit">{t('Users')}</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </VisibilitySensor>
  );
}
