import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router';
import {
  getBrands,
  getCountries,
  getCities,
  getHotels,
  register,
  getProfile,
  uploadPhoto,
} from '../../../utils/data';
import { orderByName } from '../../../utils';
import { useDropzone } from 'react-dropzone';
import get from 'lodash.get';
import { useGlobalContext } from '../../../store/global';

const ProfileEdit = (props) => {
  const { setLoading } = useGlobalContext()
  const [pageData, setPageData] = useState({
    countries: [],
    cities: [],
    brands: [],
    hotels: [],
    countryComplete: false,
    cityComplete: false,
    nameComplete: true,
    surnameComplete: true,
    brandComplete: true,
    hotelComplete: true,
    uploadedFile: null,
    fileSrc: null,
    formComplete: false,
    profile: null,
  });

  useEffect(() => {
    setLoading(true)
    getProfile().then((res) => {
      getLists(res.data);
      setPageData((prevState) => ({
        ...prevState,
        profile: res.data,
      }));
      setLoading(false)
    });
  }, []);

  useEffect(() => {
    validateForm();
  }, [pageData.countries, pageData.cities, pageData.brands, pageData.hotels]);

  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader();
    reader.onload = () => {
      const res = reader.result;
      setPageData((prevState) => ({
        ...prevState,
        fileSrc: res,
        uploadedFile: acceptedFiles[0],
      }));
    };
    if (acceptedFiles[0]) {
      reader.readAsDataURL(acceptedFiles[0]);
    }
  }, []);

  const handleFileUpload = () => {
    const fd = new FormData();
    fd.append('file', pageData.uploadedFile);
    return uploadPhoto(fd);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const getLists = async (profile) => {
    const countries = await getCountries();
    const brands = await getBrands();
    let cities = [];
    let hotels = [];
    if (get(profile, 'country.id', '') !== '') {
      cities = await getCities(get(profile, 'country.id', ''));
    }
    if (get(profile, 'brand.id', '') !== '') {
      hotels = await getHotels(get(profile, 'city.id', ''), get(profile, 'brand.id', ''));
    }
    setPageData((prevState) => ({
      ...prevState,
      countries: countries.data,
      brands: brands.data,
      cities: cities.data || [],
      hotels: hotels.data || [],
    }));
  };

  const updateCities = async () => {
    const countryValue = document.getElementById('country');
    const countryId = pageData.countries.filter((c) => c.id === countryValue.value)[0];
    let hotels = [];
    if (countryValue.value && countryId) {
      const cities = await getCities(countryId.id);
      setPageData((prevState) => ({
        ...prevState,
        countryComplete: true,
        cities: cities.data,
        hotels,
      }));
    }
  };

  const updateHotels = async () => {
    const brandValue = document.getElementById('brand');
    const cityValue = document.getElementById('city');
    const brandId = pageData.brands.filter((c) => c.id === brandValue.value)[0];

    if (brandValue.value && brandId) {
      const hotels = await getHotels(cityValue.value, brandId.id);
      setPageData((prevState) => ({
        ...prevState,
        brandComplete: true,
        hotels: hotels.data,
      }));
    } else {
      setPageData((prevState) => ({
        ...prevState,
        hotels: [],
      }));
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();
    const name = document.getElementById('name');
    const surname = document.getElementById('surname');
    const country = document.getElementById('country');
    const city = document.getElementById('city');
    const brand = document.getElementById('brand');
    const hotel = document.getElementById('hotel');
    if ((name.value, surname.value, country.value, city.value, brand.value)) {
      const data = {
        name: name.value,
        surname: surname.value,
        country: country.value,
        city: city.value,
        brand: brand.value,
        hotel: hotel.value,
      };
      register(data);
      if (!pageData.uploadedFile) {
        props.history.push('/profile');
      }
    }
    if (pageData.uploadedFile) {
      handleFileUpload().then(() => {
        props.history.push('/profile');
      });
    }
  };

  const validateForm = () => {
    const name = document.getElementById('name');
    const surname = document.getElementById('surname');
    const country = document.getElementById('country');
    const city = document.getElementById('city');
    const brand = document.getElementById('brand');
    const hotel = document.getElementById('hotel');

    let formComplete = false;
    if (name.value && surname.value && country.value && city.value && brand.value && hotel.value) {
      formComplete = true;
    }

    setPageData((prevState) => ({
      ...prevState,
      countryComplete: country.value ? true : false,
      cityComplete: city.value ? true : false,
      nameComplete: name.value ? true : false,
      surnameComplete: surname.value ? true : false,
      brandComplete: brand.value ? true : false,
      hotelComplete: hotel.value ? true : false,
      formComplete,
    }));
  };

  return (
    <div id="content">
      <section className="edit-profile">
        <div className="viewport">
          <div className="inner">
            <h2 className="center-title">edit your profile</h2>
            <form action="" onChange={validateForm}>
              <div className="form-item">
                <div className="file-upload">
                  {pageData.fileSrc && (
                    <div
                      className="img-wrapper"
                      {...getRootProps()}
                      style={{ backgroundImage: `url(${pageData.fileSrc})` }}
                    >
                      <img id="output" border="0" />
                    </div>
                  )}
                  {!pageData.fileSrc && get(pageData, 'profile.profilePicture.uri', '') !== '' && (
                    <div
                      className="img-wrapper"
                      {...getRootProps()}
                      style={{
                        backgroundImage: `url(${
                          process.env.REACT_APP_IMG_URL
                        }/storage/user-picture/${get(pageData, 'profile.profilePicture.uri', '')})`,
                      }}
                    >
                      <img id="output" border="0" />
                    </div>
                  )}
                  {!pageData.fileSrc && get(pageData, 'profile.profilePicture.uri', '') === '' && (
                    <div
                      className="img-wrapper"
                      {...getRootProps()}
                      style={{ backgroundImage: `url(/assets/images/profile.png)` }}
                    >
                      <img id="output" border="0" />
                    </div>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    id="file"
                    style={{ display: 'none' }}
                    {...getInputProps()}
                  />
                  <label htmlFor="file" style={{ cursor: 'pointer' }} className="upload">
                    {' '}
                    <span className="icon-upload"></span> Upload Image
                    <div className="upload-limit">(Limit 20mb)</div>
                  </label>
                </div>
              </div>
              <div className="form-item">
                <label htmlFor="">
                  Name <span>*</span>
                </label>
                <input
                  id="name"
                  type="text"
                  defaultValue={get(pageData, 'profile.name', '') || ''}
                />
              </div>
              <div className="form-item">
                <label htmlFor="">
                  Surname <span>*</span>
                </label>
                <input
                  id="surname"
                  type="text"
                  disabled={!pageData.nameComplete}
                  defaultValue={get(pageData, 'profile.surname', '') || ''}
                />
              </div>
              <div className="form-item">
                <label htmlFor="">
                  Country/Region <span>*</span>
                </label>
                <div className="select icon-chevrondown">
                  <select
                    name="countries"
                    id="country"
                    onChange={updateCities}
                    disabled={!pageData.surnameComplete || get(pageData, 'profile.role') === 'hotel'}
                  >
                    <option
                      value=""
                      selected={get(pageData, 'profile.country.id', '') === '' ? true : false}
                    ></option>
                    {pageData.countries.sort(orderByName).map((c) => (
                      <option
                        key={c.id}
                        value={c.id}
                        selected={get(pageData, 'profile.country.id', '') === c.id ? true : false}
                      >
                        {c.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-item">
                <label htmlFor="">
                  City <span>*</span>
                </label>
                <div className="select icon-chevrondown">
                  <select name="city" id="city" onChange={updateHotels} disabled={get(pageData, 'profile.role') === 'hotel'}>
                    <option
                      value=""
                      selected={get(pageData, 'profile.city.id', '') === '' ? true : false}
                    ></option>
                    {pageData.cities.sort(orderByName).map((c) => (
                      <option
                        key={c.id}
                        value={c.id}
                        selected={get(pageData, 'profile.city.id', '') === c.id ? true : false}
                      >
                        {c.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-item">
                <label htmlFor="">
                  Brand <span>*</span>
                </label>
                <div className="select icon-chevrondown">
                  <select name="brand" id="brand" onChange={updateHotels} disabled={get(pageData, 'profile.role') === 'hotel'}>
                    <option
                      value=""
                      selected={get(pageData, 'profile.brand.id', '') === '' ? true : false}
                    ></option>
                    {pageData.brands.map((b) => (
                      <option
                        key={b.id}
                        value={b.id}
                        selected={get(pageData, 'profile.brand.id', '') === b.id ? true : false}
                      >
                        {b.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-item">
                <label htmlFor="">
                  Hotel <span>*</span>
                </label>
                <div className="select icon-chevrondown">
                  <select name="brand" id="hotel" disabled={get(pageData, 'profile.role') === 'hotel'}>
                    <option
                      value=""
                      selected={get(pageData, 'profile.hotel.id', '') === '' ? true : false}
                    ></option>
                    {pageData.hotels.map((h) => (
                      <option
                        key={h.id}
                        value={h.id}
                        selected={get(pageData, 'profile.hotel.id', '') === h.id ? true : false}
                      >
                        {h.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* <div className="form-item">
						<input type="checkbox" id="terms" />
						<label htmlFor="terms">I have read and agreed to the <a href="">Cookie  Preferences, Privacy, and Terms.</a></label>
					</div> */}
              <div className="form-actions">
                <button
                  className="primary big"
                  onClick={handleRegister}
                  disabled={!pageData.formComplete}
                >
                  save
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withRouter(ProfileEdit);
