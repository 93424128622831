import React from 'react';

const TermsFooter = () => (
  <div className="terms-footer">
    <div className="terms-footer-divider large">
      <div className="header-wrap">
        <span className="line" />
        <span className="text">TIMELESS PORTFOLIO</span>
        <span className="line" />
      </div>

      <ul>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.hyatt.com/brands/park-hyatt"
          >
            <span></span>
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.miravalresorts.com/">
            <span></span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.hyatt.com/brands/grand-hyatt"
          >
            <span></span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.hyatt.com/brands/hyatt-regency"
          >
            <span></span>
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.hyatt.com/brands/hyatt">
            <span></span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.hyatt.com/brands/all-inclusive"
          >
            <span></span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.hyatt.com/brands/hyatt-place"
          >
            <span></span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.hyatt.com/brands/hyatt-house"
          >
            <span></span>
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.hyatt.com/brands/urcove">
            <span></span>
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.hyattresidenceclub.com/">
            <span></span>
          </a>
        </li>
      </ul>
    </div>

    <div className="small-medium-container">
      <div className="terms-footer-divider medium">
        <div className="header-wrap">
          <span className="line" />
          <span className="text">BOUNDLESS PORTFOLIO</span>
          <span className="line" />
        </div>

        <ul>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.hyatt.com/brands/andaz">
              <span></span>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.hyatt.com/brands/alila-hotels-and-resorts"
            >
              <span></span>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.hyatt.com/brands/thompson-hotels"
            >
              <span></span>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.hyatt.com/brands/hyatt-centric"
            >
              <span></span>
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.exhalespa.com/">
              <span></span>
            </a>
          </li>
        </ul>
      </div>

      <div className="terms-footer-divider small">
        <div className="header-wrap">
          <span className="line" />
          <span className="text">INDEPENDENT COLLECTIONS</span>
          <span className="line" />
        </div>

        <ul>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.hyatt.com/brands/unbound-collection"
            >
              <span></span>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.hyatt.com/brands/destination-hotels"
            >
              <span></span>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.hyatt.com/brands/joie-de-vivre-hotels"
            >
              <span></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default TermsFooter;
