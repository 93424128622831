import * as React from "react"
const Star = ({color = "#D2D2D2", ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke={color}
      strokeWidth={2}
      d="m10.5 2.604 1.986 4.775.235.564.609.05 5.155.412-3.928 3.365-.464.398.142.594 1.2 5.03-4.414-2.695-.521-.319-.521.319-4.414 2.696 1.2-5.031.142-.595-.464-.397-3.928-3.365 5.155-.413.61-.049.234-.564L10.5 2.604Z"
    />
  </svg>
)
export default Star
