import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { getProfile } from '../../../utils/data';
import get from 'lodash.get';
import LeaderBoard from '../../ui/leaderBoard';
import {
  getLeaderboard,
} from '../../../utils/data';
import { useTranslation } from 'react-i18next'

const Leaderboard = (props) => {
  const { t } = useTranslation();

  const [pageData, setPageData] = useState({
    leaderboard: {},
    user: {},
  });

    useEffect(() => {
      getPageData()
  }, []);

  const getPageData = async () => {

    const lb = await getLeaderboard();
    const p = await getProfile();

    const leaderboard = lb.data;
    const user = p.data;

    const userCountryId = get(user, 'country.id', '');
    const leaderBoardRegion = get(leaderboard, 'fastestViewers', []).filter(
      (set) => set.country.id === userCountryId
    )[0];

    setPageData({
      leaderboard,
      leaderBoardRegion,
      user,
    })
  }

  return (
    <div>
      <div id="content">
        <section className="leaderboard">
          <div className="viewport">
            <div className="inner">
              <h2 className="center-title">{t('Weekly_Leaderboard')}</h2>
              <LeaderBoard data={pageData.leaderboard} region={pageData.leaderBoardRegion} />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default withRouter(Leaderboard);