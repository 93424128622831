import React, { useState, useEffect } from 'react';
import { useGlobalContext } from '../../../store/global';
import {
  getBrands,
  getCountries,
  getCities,
  getHotels,
  register,
  getProfile,
} from '../../../utils/data';
import { orderByName } from '../../../utils';
import get from 'lodash.get';
import { Link } from 'react-router-dom';

export default function Registration() {
  const { openModal, setHasRegistered } = useGlobalContext();

  const [pageData, setPageData] = useState({
    countries: [],
    cities: [],
    brands: [],
    hotels: [],
    countryComplete: true,
    cityComplete: true,
    nameComplete: true,
    surnameComplete: true,
    brandComplete: true,
    hotelComplete: true,
    formComplete: false,
    profile: null,
  });

  useEffect(() => {
    getProfile().then((res) => {
      getLists(res?.data);
      setPageData((prevState) => ({
        ...prevState,
        profile: res?.data,
      }));
    });
  }, []);

  useEffect(() => {
    validateForm();
  }, [pageData.countries, pageData.cities, pageData.brands, pageData.hotels]);

  const getLists = async (profile) => {
    const countries = await getCountries();
    const brands = await getBrands();
    let cities = [];
    let hotels = [];
    if (get(profile, 'country.id', '') !== '') {
      cities = await getCities(get(profile, 'country.id', ''));
    }
    if (get(profile, 'brand.id', '') !== '') {
      hotels = await getHotels(get(profile, 'city.id', ''), get(profile, 'brand.id', ''));
    }
    setPageData((prevState) => ({
      ...prevState,
      countries: countries.data,
      brands: brands.data,
      cities: cities.data || [],
      hotels: hotels.data || [],
    }));
  };

  const updateCities = async () => {
    const countryValue = document.getElementById('country');
    const countryId = pageData.countries.filter((c) => c.id === countryValue.value)[0];
    // if (pageData.profile) {
    //   getLists(pageData.profile)
    // }
    let hotels = [];
    if (countryValue.value && countryId) {
      const cities = await getCities(countryId.id);
      setPageData((prevState) => ({
        ...prevState,
        countryComplete: true,
        cities: cities.data,
        hotels,
      }));
    }
  };

  const updateHotels = async () => {
    const brandValue = document.getElementById('brand');
    const cityValue = document.getElementById('city');
    const brandId = pageData.brands.filter((c) => c.id === brandValue.value)[0];

    if (brandValue.value && brandId) {
      const hotels = await getHotels(cityValue.value, brandId.id);
      setPageData((prevState) => ({
        ...prevState,
        brandComplete: true,
        hotels: hotels.data,
      }));
    } else {
      setPageData((prevState) => ({
        ...prevState,
        hotels: [],
      }));
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();
    const name = document.getElementById('name');
    const surname = document.getElementById('surname');
    const country = document.getElementById('country');
    const city = document.getElementById('city');
    const brand = document.getElementById('brand');
    const hotel = document.getElementById('hotel');
    if ((name.value, surname.value, country.value, city.value, brand.value)) {
      const data = {
        name: name.value,
        surname: surname.value,
        country: country.value,
        city: city.value,
        brand: brand.value,
        hotel: hotel.value,
      };
      register(data);
      setHasRegistered(true);
      openModal('profile-pic');
    }
  };

  const validateForm = () => {
    const name = document.getElementById('name');
    const surname = document.getElementById('surname');
    const country = document.getElementById('country');
    const city = document.getElementById('city');
    const brand = document.getElementById('brand');
    const hotel = document.getElementById('hotel');
    const terms = document.getElementById('terms');

    let formComplete = false;
    if (
      name.value &&
      surname.value &&
      country.value &&
      city.value &&
      brand.value &&
      hotel.value &&
      terms.checked
    ) {
      formComplete = true;
    }
    setPageData((prevState) => ({
      ...prevState,
      countryComplete: country.value ? true : false,
      cityComplete: city.value ? true : false,
      nameComplete: name.value ? true : false,
      surnameComplete: surname.value ? true : false,
      brandComplete: brand.value ? true : false,
      hotelComplete: hotel.value ? true : false,
      formComplete,
    }));
  };

  return (
    <div className="popup-inner">
      <div className="popup-title">
        <h2>Please complete your profile to continue</h2>
        {/* <div className="close icon-x" onClick={canCloseModal ? closeModal : null}></div> */}
      </div>

      <div className="popup-content">
        <form action="" onChange={validateForm}>
          <h2>Sign up to enjoy all features of I.D.E.A.S.</h2>
          <p>Please fill in our information below.</p>

          <div className="form-item">
            <label htmlFor="">
              Name <span>*</span>
            </label>
            <input id="name" type="text" defaultValue={get(pageData, 'profile.name', '') || ''} />
          </div>
          <div className="form-item">
            <label htmlFor="">
              Surname <span>*</span>
            </label>
            <input
              id="surname"
              type="text"
              defaultValue={get(pageData, 'profile.surname', '') || ''}
            />
          </div>
          <div className="form-item">
            <label htmlFor="">
              Country/Region <span>*</span>
            </label>
            <div className="select icon-chevrondown">
              <select name="countries" id="country" onChange={updateCities}>
                <option
                  value=""
                  selected={get(pageData, 'profile.country.id', '') === '' ? true : false}
                ></option>
                {pageData.countries.sort(orderByName).map((c) => (
                  <option
                    key={c.id}
                    value={c.id}
                    selected={get(pageData, 'profile.country.id', '') === c.id ? true : false}
                  >
                    {c.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-item">
            <label htmlFor="">
              City <span>*</span>
            </label>
            <div className="select icon-chevrondown">
              <select
                name="city"
                id="city"
                disabled={!pageData.countryComplete}
                onChange={updateHotels}
              >
                <option
                  value=""
                  selected={get(pageData, 'profile.city.id', '') === '' ? true : false}
                ></option>
                {pageData.cities.sort(orderByName).map((c) => (
                  <option
                    key={c.id}
                    value={c.id}
                    selected={get(pageData, 'profile.city.id', '') === c.id ? true : false}
                  >
                    {c.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-item">
            <label htmlFor="">
              Brand <span>*</span>
            </label>
            <div className="select icon-chevrondown">
              <select
                name="brand"
                id="brand"
                disabled={!pageData.cityComplete}
                onChange={updateHotels}
              >
                <option
                  value=""
                  selected={get(pageData, 'profile.brand.id', '') === '' ? true : false}
                ></option>
                {pageData.brands.map((b) => (
                  <option
                    key={b.id}
                    value={b.id}
                    selected={get(pageData, 'profile.brand.id', '') === b.id ? true : false}
                  >
                    {b.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-item">
            <label htmlFor="">
              Hotel <span>*</span>
            </label>
            <div className="select icon-chevrondown">
              <select
                name="brand"
                id="hotel"
                disabled={!pageData.brandComplete}
                selected={get(pageData, 'profile.hotel.id', '') === '' ? true : false}
              >
                <option value=""></option>
                {pageData.hotels.map((h) => (
                  <option
                    key={h.id}
                    value={h.id}
                    selected={get(pageData, 'profile.hotel.id', '') === h.id ? true : false}
                  >
                    {h.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-item">
            <input type="checkbox" id="terms" />
            <label htmlFor="terms">
              I have read and agreed to the{' '}
              <Link to="/terms">Cookie Preferences, Privacy, and Terms.</Link>
            </label>
          </div>
          <div className="form-actions">
            <button
              className="primary big"
              onClick={handleRegister}
              disabled={!pageData.formComplete}
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
