import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash.get';
import { parseCategoryID } from '../../../utils';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next'

export default function RelatedSeries(props) {
  const { t } = useTranslation();
  const { series } = props;
  const [swiper, setSwiper] = useState(null);

  const initSwiper = (s) => {
    setSwiper(s);
    SwiperCore.use([Navigation]);
    const prev = document.getElementById('prevbutton');
    const next = document.getElementById('nextbutton');
  };

  return (
    <Swiper
      onSwiper={(swiper) => initSwiper(swiper)}
      className="r-video"
      slidesPerView={1}
      spaceBetween={10}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
    >
      <div className="swiper-wrapper">
        {series &&
          series.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((s, i) => (
            <SwiperSlide key={s.id} className="swiper-slide">
              <Link to={`/series/${s.id}`} style={{ color: 'white', textDecoration: 'none' }}>
                <div className="video-wrapper">
                  <div className="play">
                    <span className="icon-playbuttonsmall"></span>
                  </div>
                  <div className="medias">
                    {get(s, 'seriePicture.uri', '') &&
                      <span
                        style={{
                          backgroundImage: `url(${
                            process.env.REACT_APP_IMG_URL
                          }/storage/serie-picture/${get(s, 'seriePicture.uri', '')}`,
                        }}
                      />
                    }
                  </div>
                </div>
                <div className="video-infos">
                  <div className="video-category">
                    <span className={`cat ${parseCategoryID(s.category).categoryShorthand}`}>
                      {parseCategoryID(s.category).categoryName}
                    </span>{' '}
                    {s.videos.length}{t('XEpisodes')}
                  </div>
                  <div className="video-title" title={s.title}>{s.title}</div>
                  <div className="speaker">
                    <span>{t('Speaker')}: </span>
                    <span>{s.speakers.filter(spkr => spkr !== null).map((spkr, i) => `${spkr.name} ${spkr.surname}`).join(', ')}</span>
                    {/*{s.speakers.map((spkr, i) => {*/}
                    {/*  return s.speakers.length > 1 && i < s.speakers.length - 1 ? (*/}
                    {/*    <span key={spkr.id}>{t('Speaker')}: {spkr.name} {spkr.surname}, </span>*/}
                    {/*  ) : (*/}
                    {/*    <span key={spkr.id}>{t('Speaker')}: {spkr.name} {spkr.surname}</span>*/}
                    {/*  );*/}
                    {/*})}*/}
                  </div>
                  
                </div>
              </Link>
            </SwiperSlide>
          ))}
      </div>
      <div id="prevbutton" className="swiper-button-prev" onClick={() => swiper.slidePrev()}></div>
      <div id="nextbutton" className="swiper-button-next" onClick={() => swiper.slideNext()}></div>
    </Swiper>
  );
}
