import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { getUserProfile, getUserLikedVideo, getProfile } from '../../../utils/data';
import get from 'lodash.get';
import { useGlobalContext } from '../../../store/global';
import VideoList from '../home/videoList';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next'

const Profile = (props) => {
  const { t } = useTranslation();
  const { user_id } = useParams();
  const{ setLoading } = useGlobalContext();
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState({
    profileData: {},
    leaderBoardStats: {},
    likedVideos: [],
    scores: {}
  });

  useEffect(() => {
    getProfile().then((res) => {
      if (res.data.id === user_id) {
        window.location.pathname = '/profile';
      }

      if (
        res?.data.role !== 'admin' &&
        res?.data.role !== 'super-admin' &&
        res?.data.role !== 'hotel'
      ) {
        window.location.pathname = '/profile';
      }
    });
  }, []);

  useEffect(() => {
    setLoading(true)
    const getPageData = async () => {
      const profile = await getUserProfile(user_id);
      const liked = await getUserLikedVideo(user_id);
      setPageData((prevState) => ({
        ...prevState,
        profileData: profile.data,
        likedVideos: liked.data,
        scores: getScore(profile.data.quizScores)
      }));
      setTimeout(() => {
        setLoading(false)
      }, 500)
    };

    getPageData();
  }, []);

  const getScore = (scores = []) => {
    let op = scores.findIndex(
      e =>
        e.category.id === process.env.REACT_APP_OPERATIONS
    )
    let fb = scores.findIndex(
      e =>
        e.category.id === process.env.REACT_APP_FB
    )
    let sm = scores.findIndex(
      e =>
        e.category.id === process.env.REACT_APP_SM
    )

    return {
      op: get(scores, `${op}.score`, 0),
      fb: get(scores, `${fb}.score`, 0),
      sm: get(scores, `${sm}.score`, 0),
    }
  }

  return (
    <div>
      <div id="content">
        {/*<section className="profile-head">*/}
        {/*  <div className="viewport">*/}
        {/*    <div className="inner">*/}
        {/*      <div className="medias">*/}
        {/*        <span style={{ backgroundImage: `url('/assets/images/profile-h.jpg')` }}></span>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</section>*/}

        <section className="profile-info">
          <div className="viewport">
            <div className="inner">
              <div className="info-wrapper">
                <div className="profile-pic">
                  <div className="medias">
                    {get(pageData, 'profileData.profilePicture.uri', '') !== '' && (
                      <span
                        style={{
                          backgroundImage: `url(${
                            process.env.REACT_APP_IMG_URL
                          }/storage/user-picture/${get(
                            pageData,
                            'profileData.profilePicture.uri',
                            ''
                          )}`,
                        }}
                      ></span>
                    )}
                    {get(pageData, 'profileData.profilePicture.uri', '') === '' && (
                      <span style={{ backgroundImage: `url('/assers/images/profile.png')` }}></span>
                    )}
                  </div>
                </div>

                <div className="profile-info">
                  <div className="name">{`${get(pageData, 'profileData.name', '')} ${get(
                    pageData,
                    'profileData.surname',
                    ''
                  )}`}</div>
                  {
                    get(pageData, 'profileData.city.name', '') !== '' &&
                    get(pageData, 'profileData.country.name', '') !== '' &&
                    get(pageData, 'profileData.brand.name', '') &&
                    <div className="branch">{`${get(pageData, 'profileData.city.name', '')}, ${get(
                      pageData,
                      'profileData.country.name',
                      ''
                    )} - ${get(pageData, 'profileData.brand.name', '')}`}</div>
                  }
                  {/*<div className="stats">*/}
                  {/*  <div>{`${get(*/}
                  {/*    pageData,*/}
                  {/*    'profileData.videoViewedCount',*/}
                  {/*    ''*/}
                  {/*  )} videos watched`}</div>*/}
                  {/*  <div>{`${get(pageData, 'profileData.commentCount', '')} comments posted`}</div>*/}
                  {/*  <div>{`${get(pageData, 'profileData.videoLikedCount', '')} videos liked`}</div>*/}
                  {/*</div>*/}

                  {/* <div className="edit">
                    <button
                      className="tertiary small"
                      onClick={() => props.history.push('/profile/edit')}
                    >
                      <span>Edit Profile</span>
                    </button>
                  </div> */}
                </div>
              </div>

              {/* <div className="left">
                <h2>{t('BADGES')}</h2>

                <div className="stats user">
                  <div className="stat expert">
                    <div className="title">
                      <span className="icon-eye"></span>
                      <span>{`X${get(pageData, 'profileData.expertViewerAward', 0)}`}</span>
                    </div>
                    <div className="title">{t('Expert_Viewer')}</div>
                  </div>
                  <div className="stat fastest">
                    <div className="title">
                      <span className="icon-thunder"></span>
                      <span>{`X${get(pageData, 'profileData.fastestViewerAward', 0)}`}</span>
                    </div>
                    <div className="title">{t('Fastest_Viewer')}</div>
                  </div>
                  <div className="stat contrib">
                    <div className="title">
                      <span className="icon-message"></span>
                      <span>{`X${get(pageData, 'profileData.starContributorAward', 0)}`}</span>
                    </div>
                    <div className="title">{t('Star_Contributor')}</div>
                  </div>
                </div>
              </div> */}

              {/* <div className="right">
                <h2>{t('Quizzes')}</h2>

                <div className="stats user">
                  <div className="stat fandb">
                    <div className="title">
                      <span>{t('FNB')}</span>
                    </div>
                    <div className="title">
                      {get(pageData, "scores.fb", 0)}{t('Pts')}
                    </div>
                  </div>
                  <div className="stat operation">
                    <div className="title">
                      <span>{t('OPSHR')}</span>
                    </div>
                    <div className="title">
                      {get(pageData, "scores.op", 0)}{t('Pts')}
                    </div>
                  </div>
                  <div className="stat sandm">
                    <div className="title">
                      <span>{t('SNM')}</span>
                    </div>
                    <div className="title">
                      {get(pageData, "scores.sm", 0)}{t('Pts')}
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <section className="discover">
          <div className="viewport">
            <div className="inner">
              <h2 className="center-title">{t('LIKED_VIDEOS')}</h2>
              {pageData.likedVideos.length === 0 && (
                <div className="empty">
                  <h2 className="title">{t('No_Result_For_Your_Search')}</h2>
                </div>
              )}
              {pageData.likedVideos.length > 0 && (
                <VideoList videos={pageData.likedVideos} limit={9 * page} />
              )}

              {pageData.likedVideos.length > (9 * page) && <div className="load-more">
                <button className="tertiary small" onClick={() => setPage(page + 1)}>
                  <span>
                    {t('More')}
                  </span>
                </button>
              </div>}
              {/* {pageData.displayedVideos.length > pageData.discoverDisplay && (
                <div className="load-more">
                  <button className="tertiary small" onClick={showMoreVideos}>
                    <span>Load more</span>
                  </button>
                </div>
              )} */}
            </div>
          </div>
        </section>

        {/* <section>
          <div className="profile-leaderboard-badges">
            <h2 className="title">{t('LEADERBOARD BADGES')}</h2>

            <div className="stat-wrapper">
              {[
                {
                  icon: 'icon-eye',
                  className: 'expert',
                  value: "profileData.expertViewerAward",
                  label: t('Expert_Viewer'),
                },
                {
                  icon: 'icon-thunder',
                  className: 'fastest',
                  value: "profileData.fastestViewerAward",
                  label: t('Fastest_Viewer'),
                },
                {
                  icon: 'icon-message',
                  className: 'star',
                  value: "profileData.starContributorAward",
                  label: t('Star_Contributor'),
                },
              ].map((e, idx) =>
                <div key={idx} className="stat-item">
                  <div className={"number-wrapper " + e.className}>
                    <span className={"icon " + e.icon} />
                    <span>X{get(pageData, e.value, 0)}</span>
                  </div>
                  <span className="label">{e.label}</span>
                </div>
              )}
            </div>
          </div>
        </section> */}
      </div>
    </div>
  );
};

export default withRouter(Profile);
