import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import VideoEmbed from "./videoEmbed";
import QuizzEmbed from "./QuizzEmbed";
import VideoInfo from "./videoInfo";
import VideoDiscussion from "./videoDiscussion";
import { VideoSeries2 } from "./videoSeries";
import RelatedSeries from "./relatedSeries";
import queryString from "query-string";
import {
  getVideoById,
  getQuizById,
  postComment,
  getSeriesByCategory,
  addToWatchlist,
  getSeriesById,
} from "../../../utils/data";
import { parseCategoryID, orderByOrder, sortByCreated } from "../../../utils/index";
import get from "lodash.get";
import Banner1 from "../../layout/banner1";
import moment from "moment";
import { useGlobalContext } from "../../../store/global";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';


const Video = (props) => {
  const { setLoading } = useGlobalContext();
  const { t } = useTranslation();
  const location = useLocation();
  const isQuiz = location.pathname === "/quiz";
  // if !id redirect to 404
  const [pageData, setPageData] = useState({
    userWatchlist: false,
    videoId: "",
    quizId: "",
    videoIndex: 0,
    tags: [],
    views: null,
    anonViews: null,
    likes: [],
    wistiaID: null,
    releaseDate: "",
    title: "",
    description: "",
    speakers: [],
    comments: [],
    category: [],
    seriesVideos: [],
    seriesQuiz: null,
    shortLink1: "",
    shortLink2: "",
    hideGoBack: false,
  });

  const updateQuiz = async (id, quiz, noloader) => {
    if (!noloader) {
      setLoading(true);
    }
    if (quiz) {
      quiz.replaceWith(id);
    }
    const q = await getQuizById(id);
    const quizData = q.data || {};

    const related = await getSeriesByCategory("category", quizData.category);
    related.data.sort(sortByCreated);
    const seriesData = await getSeriesById(get(quizData, "quiz.series[0].id", ""));

    setPageData((prevState) => ({
      ...prevState,
      //   videoId: videoData.id,
      //   tags: videoData.tags || [],
      //   views: videoData.views || 0,
      //   anonViews: videoData.anonymousViewCount || 0,
      //   likes: videoData.likes || [],
      //   wistiaID: videoData.wistiaHashId || '',
      releaseDate: quizData.quiz.createdAt || "",
      //   speakers: videoData.speakers || [],
      title: quizData.quiz.title || "",
      //   comments: videoData.comments || [],
      //   category: videoData.category || {},
      categoryDisplay: parseCategoryID(quizData.quiz.category),
      //   description: videoData.description || '',
      seriesVideos: get(quizData, "quiz.series[0].videos", []),
      seriesQuiz: get(quizData, "quiz", null),
      quiz: quizData.quiz || {},
      prevQuiz: quizData.previousResults || [],
      relatedSeries:
        related.data.filter(
          (r) => r.id !== get(quizData, "quiz.series[0].id", "")
        ).slice(0, 2) || [],
      selectedIndex: id,
      seriesData: seriesData?.data || {},
      isSustainability: false,
      //   userLike: videoData.userLike,
      //   shortLink1: videoData.shortLink1,
      //   shortLink2: videoData.shortLink2,
    }));

    setTimeout(() => {
      setLoading(false);
    }, 800);
  };

  const updateVideo = async (id, video, noloader) => {
    if (!noloader) {
      setLoading(true);
    }
    if (video) {
      video.replaceWith(id);
    }
    const v = await getVideoById(id);
    const videoData = v.data || [];

    const related = await getSeriesByCategory(
      "category",
      videoData.category.id
    );
    related.data.sort(sortByCreated);

    let selectedIndex;
    // find out which number the video is in its series
    if (
      videoData &&
      videoData.series &&
      videoData.series.length &&
      videoData.series.length > 0
    ) {
      const videoFromSeries = get(videoData, "series[0].videos", []).filter(
        (sv) => sv.video.id === videoData.id
      )[0];
      if (videoFromSeries) {
        selectedIndex = get(videoData, "series[0].videos", [])
          .sort(orderByOrder)
          .map((v) => v.video.id)
          .indexOf(videoData.id);
      }
    }

    setPageData((prevState) => ({
      ...prevState,
      userWatchlist: videoData.userWatchlist || false,
      videoId: videoData.id,
      tags: videoData.tags || [],
      views: videoData.views || 0,
      anonViews: videoData.anonymousViewCount || 0,
      likes: videoData.likes || [],
      wistiaID: videoData.wistiaHashId || "",
      releaseDate: videoData.releaseDate || "",
      speakers: videoData.speakers || [],
      title: videoData.title || "",
      comments: videoData.comments || [],
      category: videoData.category || {},
      categoryDisplay: parseCategoryID(videoData.category.id),
      description: videoData.description || "",
      seriesVideos: get(videoData, "series[0].videos", []),
      seriesQuiz: get(videoData, "series[0].quiz", null),
      relatedSeries:
        related.data.filter(
          (r) => r.id !== get(videoData, "series[0].id", "")
        ).slice(0, 2) || [],
      selectedIndex,
      userLike: videoData.userLike,
      shortLink1: videoData.shortLink1,
      shortLink2: videoData.shortLink2,
      isSustainability: videoData.isSustainability ? true : false,
    }));
    setTimeout(() => {
      setLoading(false);
    }, 800);
  };

  useEffect(() => {
    const { search } = props.location;
    let videoId;
    if (search) {
      const { id } = queryString.parse(search);
      // const id = '601a7876e8e8ea516257abfc'
      if (id) {
        if (isQuiz) {
          updateQuiz(id);
        } else {
          updateVideo(id);
        }
      }
    } else {
      props.history.push("/notfound");
    }
  }, [props.location]);

  const selectFromSeries = (videoId) => {
    updateVideo(videoId);
  };

  const topLevelComment = (message, parent = null, image) => {
    postComment(pageData.videoId, message, undefined, image).then((result) => {
      updateVideo(pageData.videoId, null, true);
    });
  };

  const toTheTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  // console.log('PAGEDATA', pageData)
  const nbVideos = pageData.seriesVideos.filter(
    (v) =>
      moment(v.video.releaseDate).isBefore(moment()) ||
      moment(v.video.releaseDate).isSame(moment(), "day")
  ).length;

  const handleWatchlist = () => {
    setPageData((prevState) => ({
      ...prevState,
      userWatchlist: !prevState.userWatchlist,
    }));

    addToWatchlist(pageData.videoId, !pageData.userWatchlist)
      .then((result) => null)
      .catch((err) => {
        setPageData((prevState) => ({
          ...prevState,
          userWatchlist: !prevState.userWatchlist,
        }));
      });
  };

  const hideGoBack = () => {
    setPageData((prevState) => ({
      ...prevState,
      hideGoBack: true,
    }));
  };

  return (
    <div id="content">
      {!pageData.hideGoBack && (
        <div className="back-button" onClick={() => props.history.goBack()}>
          <span className="icon-arrowup2"></span> {t('Back')}
        </div>
      )}
      <section className="video-page">
        <div className="viewport">
          <div className="inner">
            <div className="bottom">
              {!isQuiz && (
                <div className="main-video">
                  <VideoEmbed
                    id={pageData.wistiaID}
                    apiId={pageData.videoId}
                    category={pageData.category}
                    series={pageData.seriesVideos}
                    quiz={pageData.seriesQuiz}
                    updateVideo={updateVideo}
                    selectedIndex={pageData.selectedIndex}
                  />
                  <VideoInfo
                    hideTop={isQuiz}
                    id={pageData.videoId}
                    views={pageData.views}
                    anonViews={pageData.anonViews}
                    likes={pageData.likes}
                    title={pageData.title}
                    tags={pageData.tags}
                    speakers={pageData.speakers}
                    releaseDate={isQuiz ? null : pageData.releaseDate}
                    description={pageData.description}
                    category={pageData.categoryDisplay}
                    userLike={pageData.userLike}
                    refreshVideo={updateVideo}
                    shortLink1={pageData.shortLink1}
                    shortLink2={pageData.shortLink2}
                    userWatchlist={pageData.userWatchlist}
                    isSustainability={pageData.isSustainability}
                    handleWatchlist={handleWatchlist}
                  />
                  <div className="comments-videos">
                    <VideoDiscussion
                      comments={pageData.comments}
                      videoId={pageData.videoId}
                      topLevelComment={topLevelComment}
                      refreshVideo={updateVideo}
                    />  
                  </div>
                </div>
              )}
              
              {!isQuiz && (
                <div className="other-videos">
                  <div className="next-videos">
                    <h2 className="title">
                      {nbVideos}{nbVideos > 1 ? t("XEpisodes") : t("XEpisode")}
                    </h2>
                    <VideoSeries2
                      series={pageData.seriesVideos}
                      quiz={pageData.seriesQuiz}
                      selectedIndex={pageData.selectedIndex}
                    />
                  </div>
                  
                 {!!pageData.seriesQuiz &&
                    <div style={{
                      backgroundColor: '#414141',
                      // borderRadius: 8,
                      marginBottom: 12,
                      padding: 24,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 24,
                    }}>
                      <div style={{
                        borderBottom: '1px solid #717171',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingBottom: 24,
                      }}>
                        <div style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: 8,
                          flex: 1,
                        }}>
                          <img
                            className="quiztime-pic"
                            src="/assets/images/quiztime_big.png"
                            alt="quiz time"
                            style={{ height: 40 }}
                          />
                          <span style={{
                            fontWeight: 700,
                            fontSize: 20,
                          }}>Quiz</span>
                        </div>

                        <span style={{
                          color: '#D2D2D2',
                          fontSize: 14,
                        }}>
                          <span style={{ fontWeight: 700 }}>
                            {pageData.seriesQuiz.questions?.length}
                          </span> questions
                        </span>
                      </div>
                      <span style={{ fontSize: 14, lineHeight: '24px' }}>
                        Test your knowledge with this interactive quiz, and get a score of 80/100 points or above...<br />
                        Don’t worry if you fail the first time, you can do it again until you pass.
                      </span>

                      <div>
                        <Link
                          to={`/quiz?id=${pageData.seriesQuiz?.id}`}
                          style={{
                            display: 'inline-block',
                            padding: '10px 28px',
                            background: '#2672ec',
                            borderRadius: 6,
                            cursor: 'pointer',
                            fontSize: 14,
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            color: '#fff',
                            textDecoration: 'none',
                          }}
                        >
                          START
                        </Link>
                      </div>
                    </div>
                  }

                  <div className="related-videos">
                    <h2 className="title">{t('Latest_Series')}</h2>
                    <RelatedSeries series={pageData.relatedSeries} />
                  </div>
                </div>
              )}

            </div>


          </div>
        </div>
      </section>

      {isQuiz && (
        <section className="quizz-page">
          <div className="viewport">
            <div className="inner">
              <div className="main-quiz">
                  <QuizzEmbed
                    quiz={pageData.quiz}
                    prevQuiz={pageData.prevQuiz}
                    category={pageData.categoryDisplay}
                    seriesData={pageData.seriesData}
                    seriesVideos={pageData.seriesVideos}
                    hideGoBack={hideGoBack}
                  />
              </div>
            </div>
          </div>
        </section>
      )}

      {!isQuiz && (
        <>
          <section className="back-to-top">
            <div className="wrapper">
              <span className="icon-arrowup" onClick={toTheTop}></span>
              <span className="text">{t('Back_To_Top')}</span>
            </div>
          </section>
        </>
      )}
      <Banner1 />
    </div>
  );
};

export default withRouter(Video);
