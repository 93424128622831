import React, { useState } from 'react';
import ViewedVideoItem from '../../ui/ViewedVideoItem';
import { filterUnreleased } from '../../../utils';
import moment from 'moment';
import { useTranslation } from 'react-i18next'


export default function ViewedVideoList(props) {
  const { t } = useTranslation();

  const { videos, onVideoClicked, hideWatchlist, onViewedClear } = props;

  moment.locale('en');
  if (localStorage.getItem('lang') == 'mandarin') {
    moment.locale('zh-cn', {
      monthsShort : [t('Jan'), t('Feb'), t('Mar'), t('Apr'), t('May'), t('Jun'), t('Jul'), t('Aug'), t('Sep'), t('Oct'), t('Nov'), t('Dec')],
    });
  } else if (localStorage.getItem('lang') == 'japanese') {
    moment.locale('jp', {
      monthsShort : [t('Jan'), t('Feb'), t('Mar'), t('Apr'), t('May'), t('Jun'), t('Jul'), t('Aug'), t('Sep'), t('Oct'), t('Nov'), t('Dec')],
    });
  }

  const todayStarted = moment().startOf('day');
  const yesterdsayStarted = moment(todayStarted).subtract(1, 'days');
  const weekAgoStarted = moment(todayStarted).subtract(1, 'weeks');
  const monthAgoStarted = moment(todayStarted).subtract(1, 'months');

  const todayList = [];

  const yesterdayList = [];

  const betweenYesterdayAndWeekLists = {};

  const weekAgoList = [];

  const monthAgoList = [];

  videos.filter(filterUnreleased).forEach((v, i) => {
    const watchStartedAt = moment(v.createdAt);
    if (watchStartedAt.isBetween(todayStarted, moment())) {
      // is today
      todayList.push(v)
    } else if (watchStartedAt.isBetween(yesterdsayStarted, todayStarted)) {
      // is yesterday
      yesterdayList.push(v);
    } else if (watchStartedAt.isBetween(weekAgoStarted, yesterdsayStarted)) {
      // between yesterday and week ago
      const date = moment(watchStartedAt).format('YYYY-MM-DD');
      if (!betweenYesterdayAndWeekLists[date]) {
        betweenYesterdayAndWeekLists[date] = [];
      }
      betweenYesterdayAndWeekLists[date].push(v)
    } else if (watchStartedAt.isBetween(monthAgoStarted, weekAgoStarted)) {
      // week ago
      weekAgoList.push(v);
    } else if (watchStartedAt.isBefore(monthAgoStarted)) {
      // month ago
      monthAgoList.push(v);
    }
  })


  const handleClear = async (startAt, endAt) => {
    const filters = {
      dateStart: startAt,
      dateEnd: endAt
    }
    onViewedClear(filters);
  }


  return (
  <>
    <div className="viewed-video-list">
      {todayList.length > 0 && (
        <div className='viewed-video-block'>
          <div className='viewed-header'>
            <div className='header-title'>{t('Today')}</div>
            <div onClick={() => handleClear(todayStarted, moment())} className="header-clear">
              {t('Clear')}
            </div>
          </div>
          {todayList.map((v, i) => {
            return <>
              <ViewedVideoItem
                key={v.id}
                video={v.video}
                hideWatchlist={hideWatchlist}
                onVideoClicked={onVideoClicked}
              />
            </>
          })}
        </div>
      )}


      {yesterdayList.length > 0 && (
        <div className='viewed-video-block'>
          <div className='viewed-header'>
            <div className='header-title'>{t('Yesterday')}</div>
            <div onClick={() => handleClear(yesterdsayStarted, todayStarted)} className="header-clear">
              {t('Clear')}
            </div>
          </div>
          {yesterdayList.map((v, i) => {
            return <>
              <ViewedVideoItem
                key={v.id}
                video={v.video}
                hideWatchlist={hideWatchlist}
                onVideoClicked={onVideoClicked}
              />
            </>
          })}
        </div>
      )}

      {Object.keys(betweenYesterdayAndWeekLists).length > 0 && (<>
        {Object.keys(betweenYesterdayAndWeekLists).map((key) => {
          return <>
            <div className='viewed-video-block'>
              <div className='viewed-header'>
                <div className='header-title'>{moment(key).format('DD MMM, YYYY')}</div>
                <div onClick={() => handleClear(moment(key).startOf('day'), moment(key).startOf('day').add(1,'day'))} className="header-clear">
                  {t('Clear')}
                </div>
              </div>
              {betweenYesterdayAndWeekLists[key].map((v, i) => {
                return <>
                  <ViewedVideoItem
                    key={v.id}
                    video={v.video}
                    hideWatchlist={hideWatchlist}
                    onVideoClicked={onVideoClicked}
                  />
                </>
              })}
            </div>
          </>
        })}
        </>
      )}

      {weekAgoList.length > 0 && (
        <div className='viewed-video-block'>
          <div className='viewed-header'>
            <div className='header-title'>{t('A_Week_Ago')}</div>
            <div onClick={() => handleClear(weekAgoStarted, monthAgoStarted)} className="header-clear">
              {t('Clear')}
            </div>
          </div>
          {weekAgoList.map((v, i) => {
            return <>
              <ViewedVideoItem
                key={v.id}
                video={v.video}
                hideWatchlist={hideWatchlist}
                onVideoClicked={onVideoClicked}
              />
            </>
          })}
        </div>
      )}

      {monthAgoList.length > 0 && (
        <div className='viewed-video-block'>
          <div className='viewed-header'>
            <div className='header-title'>{t('A_Month_Ago')}</div>
            <div onClick={() => handleClear(moment('1990-01-01'), monthAgoStarted)} className="header-clear">
              {t('Clear')}
            </div>
          </div>
          {monthAgoList.map((v, i) => {
            return <>
              <ViewedVideoItem
                key={v.id}
                video={v.video}
                hideWatchlist={hideWatchlist}
                onVideoClicked={onVideoClicked}
              />
            </>
          })}
        </div>
      )}
    </div>   
  </>
  );
}
