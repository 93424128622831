import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router";
import {
  getProfile,
  getLikedVideo,
  getUserStatistic,
  getSubmittedQuizzes,
  getQuizzes
} from "../../../utils/data";
import get from "lodash.get";
import { useGlobalContext } from "../../../store/global";
import VideoList from "../home/videoList";
import { useTranslation } from 'react-i18next'

// Progress bar
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// Bar chart
import Picker from 'react-month-picker';
import 'react-month-picker/css/month-picker.css';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  LabelList
} from 'recharts';
import queryString from 'query-string';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

const Profile = (props) => {
  const { t } = useTranslation();

  const { setLoading, openModal } = useGlobalContext();

  // const [showQuizzes, setShowQuizzes] = useState(queryString.parse(window.location.search).showQuizzes || false);
  const showQuizzes = queryString.parse(window.location.search).showQuizzes || false;

  const [pageData, setPageData] = useState({
    profileData: {},
    leaderBoardStats: {},
    likedVideos: [],
    scores: {},
    statistics: {},
    showQuizzes: showQuizzes,
  });
  const [weeksData, setWeeksData] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const [fnbProgress, setFnbProgress] = useState({});
  const [opeProgress, setOpeProgress] = useState({});
  const [snmProgress, setSnmProgress] = useState({});
  const [pickerLang, setPickerLang] = useState({
    months: [t('Jan'), t('Feb'), t('Mar'), t('Apr'), t('May'), t('Jun'), t('Jul'), t('Aug'), t('Sep'), t('Oct'), t('Nov'), t('Dec')],
    from: 'From', to: 'To',
  });
  const [monthFull, setMonthFull] = useState({
    months: [t('January'), t('February'), t('March'), t('April'), t('May'), t('June'), t('July'), t('August'), t('September'), t('October'), t('November'), t('December')]
  });
  const [monthValue, setMonthValue] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1
  });

  const monthPickerRef = useRef(null);

  const [chartMargin, setChartMargin] = useState({ top: 40, right: 40, left: 40, bottom: 40 })
  let autoResize = () => {
    if (window.innerWidth < 768 ){
      setChartMargin({ top: 102, right: 16, left: 0, bottom: 48 });
    }else{
      setChartMargin({ top: 86, right: 40, left: 0, bottom: 40 });
    }
  }

  useEffect(() => {
    const loadQuizzes = async () => {
     const res = await getSubmittedQuizzes({ range: '[0,4]', sort: ["createdAt","DESC"] })

     setQuizzes(res.data)
    }

    loadQuizzes()
}, []);

  useEffect(() => {
    window.addEventListener('resize', autoResize)
    autoResize();
  }, [])

  useEffect(() => {
    if (pageData.showQuizzes) {
      openModal("quizzes");
    }
  }, [pageData.showQuizzes])

  useEffect(() => {
    setLoading(true);
    const getPageData = async () => {
      const profile = await getProfile();
      const liked = await getLikedVideo();

      getStatistics(profile.data.id, monthValue);

      setPageData((prevState) => ({
        ...prevState,
        profileData: profile.data,
        likedVideos: liked.data,
        scores: getScore(profile.data.quizScores),
        // statistics: statistics.data
      }));

      let submittedQuizzes = await getSubmittedQuizzes({ passed: true });
      submittedQuizzes = submittedQuizzes.data;
      let [fnbQuizzes, opeQuizzes, snmQuizzes] = await Promise.all([
        getQuizzes({
          category: process.env.REACT_APP_FB,
        }),
        getQuizzes({
          category: process.env.REACT_APP_OPERATIONS,
        }),
        getQuizzes({
          category: process.env.REACT_APP_SM,
        }),
      ])
      submittedQuizzes.filter((v, i, a) => a.findIndex(v2 => (v2.quiz == v.quiz)) === i)

      var filterQuizzesResults = submittedQuizzes.reduce((unique, o) => {
        if(!unique.some(obj => obj.quiz === o.quiz || obj.quiz?.id === o.quiz?.id)) {
          unique.push(o);
        }
        return unique;
      },[]);

      let fnbNum = 0;
      let opeNum = 0;
      let snmNum = 0;
      for (const i in filterQuizzesResults) {
        switch (filterQuizzesResults[i].category?.id) {
          case process.env.REACT_APP_FB:
            fnbNum++;
            break;
          case process.env.REACT_APP_OPERATIONS:
            opeNum++;
            break;
          case process.env.REACT_APP_SM:
            snmNum++;
            break;
          default:
            break;
        }
      }

      let fnbProgressTemp = {
        percentage: ((fnbNum / fnbQuizzes.data.length) * 100).toFixed(0),
        total: fnbQuizzes.data.length,
        done: fnbNum
      };
      let opeProgressTemp = {
        percentage: ((opeNum / opeQuizzes.data.length) * 100).toFixed(0),
        total: opeQuizzes.data.length,
        done: opeNum
      };
      let snmProgressTemp = {
        percentage: ((snmNum / snmQuizzes.data.length) * 100).toFixed(0),
        total: snmQuizzes.data.length,
        done: snmNum
      };

      setFnbProgress(fnbProgressTemp);
      setOpeProgress(opeProgressTemp);
      setSnmProgress(snmProgressTemp);

      setTimeout(() => {
        setLoading(false);
      }, 500);
    };

    getPageData();
  }, []);

  const getScore = (scores = []) => {
    let op = scores.findIndex(
      (e) => e.category.id === process.env.REACT_APP_OPERATIONS
    );
    let fb = scores.findIndex(
      (e) => e.category.id === process.env.REACT_APP_FB
    );
    let sm = scores.findIndex(
      (e) => e.category.id === process.env.REACT_APP_SM
    );

    return {
      op: get(scores, `${op}.score`, 0),
      fb: get(scores, `${fb}.score`, 0),
      sm: get(scores, `${sm}.score`, 0),
    };
  };

  const getStatistics = async (id, monthValue) => {
    let statistics = await getUserStatistic(id, monthValue);
    let statisticsLastMonth = await getUserStatistic(id, {
      year: monthValue.month > 1 ? monthValue.year : monthValue.year - 1,
      month: monthValue.month > 1 ? monthValue.month - 1 : 12
    });

    setPageData((prevState) => ({
      ...prevState,
      statistics: statistics.data,
      statisticsLastMonth: statisticsLastMonth.data
    }));

    let tempWeeksData = [];
    for (let i = 0; i < (statistics.data.week5 ? 5 : 4); i++) {
      statistics.data[`week${parseInt(i, 10) + 1}`].name = t(`Week_${parseInt(i, 10) + 1}`);
      tempWeeksData.push(statistics.data[`week${parseInt(i, 10) + 1}`]);
    }

    setWeeksData(tempWeeksData);
  }

  // const makeText = m => {
  //   if (m && m.year && m.month) return (monthFull.months[m.month - 1] + ', ' + m.year)
  //   return '?'
  // }

  // const showPicker = () => {
  //   if (monthPickerRef && monthPickerRef.current) {
  //     monthPickerRef.current.show();
  //   }
  // };

  // const hidePicker = () => {
  //   if (monthPickerRef && monthPickerRef.current) {
  //     monthPickerRef.current.dismiss();
  //   }
  // };

  // const handlePickerChange = (...args) => {
  //   setMonthValue({ year: args[0], month: args[1] });
  //   getStatistics(pageData.profileData.id, { year: args[0], month: args[1] });
  //   hidePicker();
  // };

  // const CustomTooltip = ({ active, payload, label }) => {
  //   if (active && payload && payload.length) {
  //     return (
  //       <div className="custom-tooltip">
  //         <p className="label">{label}</p>
  //         <p className="label blue">{`${t('Videos_Watched')} : ${payload[0].value}`}</p>
  //         <p className="label yellow">{`${t('Quizzes_Taken')} : ${payload[1].value}`}</p>
  //         <p className="label purple">{`${t('Comments_Posted')} : ${payload[2].value}`}</p>
  //       </div>
  //     );
  //   }

  //   return null;
  // };

  return (
    <div>
      <div id="content">
        {/*<section className="profile-head">*/}
        {/*  <div className="viewport">*/}
        {/*    <div className="inner">*/}
        {/*      <div className="medias">*/}
        {/*        <span*/}
        {/*          style={{*/}
        {/*            backgroundImage: `url('../assets/images/profile-h.jpg')`,*/}
        {/*          }}*/}
        {/*        ></span>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</section>*/}

        <section className="profile-info">
          <div className="viewport">
            <div className="inner">
              <div className="info-wrapper">
                <div className="profile-pic">
                  <div className="medias">
                    {get(pageData, "profileData.profilePicture.uri", "") !==
                      "" && (
                      <span
                        style={{
                          backgroundImage: `url(${
                            process.env.REACT_APP_IMG_URL
                          }/storage/user-picture/${get(
                            pageData,
                            "profileData.profilePicture.uri",
                            ""
                          )}`,
                        }}
                      ></span>
                    )}
                    {get(pageData, "profileData.profilePicture.uri", "") ===
                      "" && (
                      <span
                        style={{
                          backgroundImage: `url('/assers/images/profile.png')`,
                        }}
                      ></span>
                    )}
                  </div>
                </div>

                <div className="profile-info">
                  <div className="name">{`${get(
                    pageData,
                    "profileData.name",
                    ""
                  )} ${get(pageData, "profileData.surname", "")}`}</div>
                  {get(pageData, "profileData.city.name", "") !== "" &&
                    get(pageData, "profileData.country.name", "") !== "" &&
                    get(pageData, "profileData.brand.name", "") && (
                      <div className="branch">{`${get(
                        pageData,
                        "profileData.city.name",
                        ""
                      )}, ${get(
                        pageData,
                        "profileData.country.name",
                        ""
                      )} - ${get(
                        pageData,
                        "profileData.brand.name",
                        ""
                      )}`}</div>
                    )}
                  {/*<div className="stats">*/}
                  {/*  <div>{`${get(*/}
                  {/*    pageData,*/}
                  {/*    "profileData.videoViewedCount",*/}
                  {/*    ""*/}
                  {/*  )} videos watched`}</div>*/}
                  {/*  <div>{`${get(*/}
                  {/*    pageData,*/}
                  {/*    "profileData.commentCount",*/}
                  {/*    ""*/}
                  {/*  )} comments posted`}</div>*/}
                  {/*  <div>{`${get(*/}
                  {/*    pageData,*/}
                  {/*    "profileData.videoLikedCount",*/}
                  {/*    ""*/}
                  {/*  )} videos liked`}</div>*/}
                  {/*</div>*/}

                  <div className="edit">
                    <button
                      className="tertiary small"
                      onClick={() => props.history.push("/profile/edit")}
                    >
                      <span>{t('EDIT_PROFILE')}</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="profile-quizzes">
                <div className="quizzes">
                  <div className="quizz-logo-wrapper">
                    <img
                      className="quizz-logo"
                      src="/assets/images/quiztime_big.png"
                      alt=""
                    />
                  </div>

                  <QuizProgressItem
                    uri="/assets/images/logo-fnb.png"
                    className="fb"
                    label="F&B"
                    finished={fnbProgress.done}
                    total={fnbProgress.total}
                    points={get(pageData, "scores.fb", 0)}
                  />

                  <QuizProgressItem
                    uri="/assets/images/logo-ope.png"
                    className="ops"
                    label="OPS | HR"
                    finished={opeProgress.done}
                    total={opeProgress.total}
                    points={get(pageData, "scores.op", 0)}
                  />

                  <QuizProgressItem
                    uri="/assets/images/logo-snm.png"
                    className="sm"
                    label="S&M"
                    finished={snmProgress.done}
                    total={snmProgress.total}
                    points={get(pageData, "scores.sm", 0)}
                  />
                </div>
              </div>

              <div className="profile-quizz-list">
                {
                // [
                //   {
                //     id: 1,
                //     title: 'Road to wellness',
                //     className: 'fb',
                //     passed: true,
                //     score: 80,
                //     date: "08/02/2022",
                //   },
                //   {
                //     id: 2,
                //     title: 'Road to wellness',
                //     className: 'fb',
                //     passed: true,
                //     score: 80,
                //     date: "08/02/2022",
                //   },
                //   {
                //     id: 3,
                //     title: 'Road to wellness',
                //     className: 'fb',
                //     passed: true,
                //     score: 80,
                //     date: "08/02/2022",
                //   },
                //   {
                //     id: 4,
                //     title: 'Road to wellness',
                //     className: 'fb',
                //     passed: false,
                //     score: 80,
                //     date: "08/02/2022",
                //   },
                //   {
                //     id: 5,
                //     title: 'Road to wellness',
                //     className: 'fb',
                //     passed: false,
                //     score: 80,
                //     date: "08/02/2022",
                //   },
                // ]
                quizzes.map((e, i) =>
                  <div key={i} className={`quizz-item ${e.passed ? 'passed' : 'failed' }`}>
                    <div className="banner">
                      {/* <span className="icon" /> */}
                      <img className="icon" src="/assets/images/passed.png" />
                      {e.passed ? t('Passed') : t('Failed')}
                    </div>

                    <div className="title">
                      {e.quiz.title}
                    </div>

                    <div className="score-wrapper">
                      <span className="score">{`${e.score}/100`}</span>
                      <span className="date">{moment(e.createdAt).format('DD/MM/YYYY')}</span>
                    </div>

                    <Link to={`/quiz?id=${e.quiz.id}`} className="to-quiz">
                      RE-TAKE
                    </Link>
                  </div>
                )}
              </div>

              <button
                className="tertiary small"
                onClick={() => openModal("quizzes")}
              >
                <span>{t('SHOW_HISTORY')}</span>
              </button>

              <div className="profile-activity">
                <h2 className="title">{t('MY_ACTIVITY')}</h2>

                <div className="activity-wrapper">
                  {[
                    {
                      value: pageData.statistics.monthlyViews,
                      average: 482,
                      label: 'VIDEO WATCHED',
                      avgDisplay: (e) => `${t('Average_of_users_have_watched...')} ${e} ${t('...videos')}`
                    },
                    {
                      value: pageData.statistics.monthlyUniqueQuizTaken,
                      average: 77,
                      label: t('QUIZZES_TAKEN'),
                      avgDisplay: (e) => `${t('Average_of_users_have_taken...')} ${e} ${t('...number_of_quizzes')}`
                    },
                    {
                      value: pageData.statistics.monthlyUniqueQuizPassed,
                      average: 36,
                      label: t('QUIZZES_PASSED'),
                      avgDisplay: (e) => `${t('Average_of_users_have_passed...')} ${e} ${t('...quizzes')}`
                    },
                  ].map((e, idx) =>
                    <div key={idx} className="activity-item">
                      <span className="value">{e.value}</span>
                      <span className="label">{e.label}</span>
                      <span className="average">{e.avgDisplay(e.average)}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="profile-leaderboard-badges">
                <h2 className="title">{t('LEADERBOARD BADGES')}</h2>

                <div className="stat-wrapper">
                  {[
                    {
                      icon: 'icon-eye',
                      className: 'expert',
                      value: "profileData.expertViewerAward",
                      label: t('Expert_Viewer'),
                    },
                    {
                      icon: 'icon-thunder',
                      className: 'fastest',
                      value: "profileData.fastestViewerAward",
                      label: t('Fastest_Viewer'),
                    },
                    {
                      icon: 'icon-message',
                      className: 'star',
                      value: "profileData.starContributorAward",
                      label: t('Star_Contributor'),
                    },
                  ].map((e, idx) =>
                    <div key={idx} className="stat-item">
                      <div className={"number-wrapper " + e.className}>
                        <span className={"icon " + e.icon} />
                        <span>X{get(pageData, e.value, 0)}</span>
                      </div>
                      <span className="label">{e.label}</span>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className="badges-wrapper">
                <h2 className="title">{t('LEADERBOARD BADGES')}</h2>
                <div className="stats">
                  <div className="stat expert">
                    <div className="title">
                      <span className="icon-eye"></span>
                      <span>{`X${get(
                        pageData,
                        "profileData.expertViewerAward",
                        0
                      )}`}</span>
                    </div>
                    <div className="title">{t('Expert_Viewer')}</div>
                  </div>
                  <div className="stat fastest">
                    <div className="title">
                      <span className="icon-thunder"></span>
                      <span>{`X${get(
                        pageData,
                        "profileData.fastestViewerAward",
                        0
                      )}`}</span>
                    </div>
                    <div className="title">{t('Fastest_Viewer')}</div>
                  </div>
                  <div className="stat contrib">
                    <div className="title">
                      <span className="icon-message"></span>
                      <span>{`X${get(
                        pageData,
                        "profileData.starContributorAward",
                        0
                      )}`}</span>
                    </div>
                    <div className="title">{t('Star_Contributor')}</div>
                  </div>
                </div>
              </div> */}

              {/* <div className="quizzes-wrapper">
                <h2 className="title">{t('Quizzes')}</h2>
                <div className="edit">
                  <button
                    className="tertiary small"
                    onClick={() => {
                      openModal("quizzes");
                      }}
                  >
                    <span>{t('SHOW_HISTORY')}</span>
                  </button>
                </div>

                <div className="left">
                  <h3>{t('PROGRESS')}</h3>

                  <div className="stats">
                    <div className="stat fandb">
                      <div className="progress-wrapper">
                        <div className="progressBar">
                          <CircularProgressbar
                            value={fnbProgress.percentage}
                            text={`${fnbProgress.percentage}%`}
                            Counterclockwise
                            styles={buildStyles({
                              pathColor: '#FF6600',
                              textColor: '#D2D2D2',
                              trailColor: '#1F1F1F',
                            })}
                          />
                        </div>
                        <div className="quizz-info">
                          <div className="title">
                            <span>{t('FNB_Blank')}</span>
                          </div>
                          <div className="num">
                            <p><strong>{fnbProgress.done}</strong> / {fnbProgress.total} <strong>{t('Quizzes')}</strong></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="stat operation">
                      <div className="progress-wrapper">
                        <div className="progressBar">
                          <CircularProgressbar
                            value={opeProgress.percentage}
                            text={`${opeProgress.percentage}%`}
                            Counterclockwise
                            styles={buildStyles({
                              pathColor: '#76BD87',
                              textColor: '#D2D2D2',
                              trailColor: '#1F1F1F',
                            })}
                          />
                        </div>
                        <div className="quizz-info">
                            <div className="title">
                              <span>{t('OPSHR')}</span>
                            </div>
                            <div className="num">
                              <p><strong>{opeProgress.done}</strong> / {opeProgress.total} <strong>{t('Quizzes')}</strong></p>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div className="stat sandm">
                      <div className="progress-wrapper">
                        <div className="progressBar">
                          <CircularProgressbar
                            value={snmProgress.percentage}
                            text={`${snmProgress.percentage}%`}
                            Counterclockwise
                            styles={buildStyles({
                              pathColor: '#FF7DD7',
                              textColor: '#D2D2D2',
                              trailColor: '#1F1F1F',
                            })}
                          />
                        </div>
                        <div className="quizz-info">
                          <div className="title">
                            <span>{t('SNM_Blank')}</span>
                          </div>
                          <div className="num">
                            <p><strong>{snmProgress.done}</strong> / {snmProgress.total} <strong>{t('Quizzes')}</strong></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="right">
                  <h3>{t('PERFORMANCE')}</h3>

                  <div className="stats">
                    <div className="stat fandb">
                      <div className="title">
                      <span>{t('FNB_Blank')}</span>
                      </div>
                      <div className="num">
                        <span>{get(pageData, "scores.fb", 0)} {t('Pts')}</span>
                      </div>
                    </div>
                    <div className="stat operation">
                      <div className="title">
                      <span>{t('OPSHR')}</span>
                      </div>
                      <div className="num">
                        <span>{get(pageData, "scores.op", 0)} {t('Pts')}</span>
                      </div>
                    </div>
                    <div className="stat sandm">
                      <div className="title">
                      <span>{t('SNM_Blank')}</span>
                      </div>
                      <div className="num">
                        <span>{get(pageData, "scores.sm", 0)} {t('Pts')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        {/* <section className="statistics">
          <div className="viewport">
            <div className="inner">
              <div className="title">{t('MY_ACTIVITY')}</div>
              <Picker
                ref={monthPickerRef}
                value={monthValue}
                years={{min: {year: new Date().getFullYear() - 4, month: 1}, max: {year: new Date().getFullYear(), month: new Date().getMonth() + 1}}}
                lang={pickerLang.months}
                onChange={handlePickerChange}
                onDismiss={() => console.log('onDismiss')}
                theme="dark"
              >
                <span onClick={showPicker}>{makeText(monthValue)}</span>
                <span className="icon-chevronright2" onClick={showPicker}></span>
              </Picker>

              <div className="data-wrapper">
                <div className="data">
                  <div className="num">{pageData.statistics.monthlyViews}</div>
                  <div className="text">{t('VIDEOS')}</div>
                  {pageData.statistics?.monthlyViews > pageData.statisticsLastMonth?.monthlyViews && (
                    <div className="arrow">
                      <span className="icon-arrowup"></span>
                      {pageData.statistics?.monthlyViews - pageData.statisticsLastMonth?.monthlyViews}
                    </div>
                  )}
                  {pageData.statistics?.monthlyViews < pageData.statisticsLastMonth?.monthlyViews && (
                    <div className="arrow">
                      <span className="icon-arrowup1"></span>
                      {pageData.statisticsLastMonth?.monthlyViews - pageData.statistics?.monthlyViews}
                    </div>
                  )}
                </div>
                <div className="data">
                  <div className="num">{pageData.statistics.monthlyUniqueQuizTaken}</div>
                  <div className="text">{t('QUIZZES_TAKEN')}</div>
                  {pageData.statistics?.monthlyUniqueQuizTaken > pageData.statisticsLastMonth?.monthlyUniqueQuizTaken && (
                    <div className="arrow">
                      <span className="icon-arrowup"></span>
                      {pageData.statistics?.monthlyUniqueQuizTaken - pageData.statisticsLastMonth?.monthlyUniqueQuizTaken}
                    </div>
                  )}
                  {pageData.statistics?.monthlyUniqueQuizTaken < pageData.statisticsLastMonth?.monthlyUniqueQuizTaken && (
                    <div className="arrow">
                      <span className="icon-arrowup1"></span>
                      {pageData.statisticsLastMonth?.monthlyUniqueQuizTaken - pageData.statistics?.monthlyUniqueQuizTaken}
                    </div>
                  )}
                </div>
                <div className="data">
                  <div className="num">{pageData.statistics.monthlyUniqueQuizPassed}</div>
                  <div className="text">{t('QUIZZES_PASSED')}</div>
                  {pageData.statistics?.monthlyUniqueQuizPassed > pageData.statisticsLastMonth?.monthlyUniqueQuizPassed && (
                    <div className="arrow">
                      <span className="icon-arrowup"></span>
                      {pageData.statistics?.monthlyUniqueQuizPassed - pageData.statisticsLastMonth?.monthlyUniqueQuizPassed}
                    </div>
                  )}
                  {pageData.statistics?.monthlyUniqueQuizPassed < pageData.statisticsLastMonth?.monthlyUniqueQuizPassed && (
                    <div className="arrow">
                      <span className="icon-arrowup1"></span>
                      {pageData.statisticsLastMonth?.monthlyUniqueQuizPassed - pageData.statistics?.monthlyUniqueQuizPassed}
                    </div>
                  )}
                </div>
                <div className="data">
                  <div className="num">{pageData.statistics.monthlyComment}</div>
                  <div className="text">{t('COMMENTS')}</div>
                  {pageData.statistics?.monthlyComment > pageData.statisticsLastMonth?.monthlyComment && (
                    <div className="arrow">
                      <span className="icon-arrowup"></span>
                      {pageData.statistics?.monthlyComment - pageData.statisticsLastMonth?.monthlyComment}
                    </div>
                  )}
                  {pageData.statistics?.monthlyComment < pageData.statisticsLastMonth?.monthlyComment && (
                    <div className="arrow">
                      <span className="icon-arrowup1"></span>
                      {pageData.statisticsLastMonth?.monthlyComment - pageData.statistics?.monthlyComment}
                    </div>
                  )}
                </div>
              </div>

              <ResponsiveContainer
                width="100%"
                height={360}
              >
                <BarChart
                  data={weeksData}
                  margin={chartMargin}
                  maxBarSize={25}
                >
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip cursor={false} content={<CustomTooltip />} />
                  <Legend verticalAlign="top" align="right" iconType="circle" />
                  <Bar
                    dataKey="monthlyViews"
                    name={t('Videos_Watched')}
                    fill="#53C1FF"
                  >
                    <LabelList dataKey="monthlyViews" position="top" />
                  </Bar>
                  <Bar
                    dataKey="monthlyQuizTaken"
                    name={t('Quizzes_Taken')}
                    fill="#FFEA32"
                  >
                    <LabelList dataKey="monthlyQuizTaken" position="top" />
                  </Bar>
                  <Bar
                    dataKey="monthlyComment"
                    name={t('Comments_Posted')}
                    fill="#9A92FF"
                  >
                    <LabelList dataKey="monthlyComment" position="top" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </section> */}

        {/*<section className="discover">*/}
        {/*  <div className="viewport">*/}
        {/*    <div className="inner">*/}
        {/*      <h2 className="center-title">liked videos</h2>*/}
        {/*      {pageData.likedVideos.length === 0 && (*/}
        {/*        <div className="empty">*/}
        {/*          <h2 className="title">No result for your search</h2>*/}
        {/*        </div>*/}
        {/*      )}*/}
        {/*      {pageData.likedVideos.length > 0 && (*/}
        {/*        <VideoList*/}
        {/*          videos={pageData.likedVideos}*/}
        {/*          limit={pageData.likedVideos.length}*/}
        {/*        />*/}
        {/*      )}*/}
        {/*      /!* {pageData.displayedVideos.length > pageData.discoverDisplay && (*/}
        {/*        <div className="load-more">*/}
        {/*          <button className="tertiary small" onClick={showMoreVideos}>*/}
        {/*            <span>Load more</span>*/}
        {/*          </button>*/}
        {/*        </div>*/}
        {/*      )} *!/*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</section>*/}
      </div>
    </div>
  );
};

export default withRouter(Profile);


const QuizProgressItem = ({ uri, className, label, finished, total, points }) => {
  const { t } = useTranslation();
  const percent = Math.round(finished * 100 / total);

  return (
    <div className={"quizz-line " + className}>
      <div className="category-wrapper">
        <img className="category-icon" src={uri} alt="" />
        <span className="category-label">{label}</span>
      </div>

      <div className="progress-wrapper">
        <span className="progress-title">{t('PROGRESS')}</span>

        <div className="progress-info">
          <div className="progress-bar-wrapper">
            <div className="progress-bar" style={{ width: `${percent}%` }} />
          </div>

          <div className="progress-numbers-wrapper">
            <span className="progress-numbers">
              <strong>{finished} / </strong>{total}
            </span>
            <span className="progress-percent">{percent}%</span>
          </div>
        </div>
      </div>

      <div className="total-points-wrapper">
        <span className="total-points-wrapper">Total points</span>
        <span className="total-points">{points} pts</span>
      </div>
    </div>
  )
}