import React, { useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import get from "lodash.get";
import { useTranslation } from 'react-i18next';

function NavSearchItem(props) {
  const { t } = useTranslation();
  const { item, path, type, handleClick } = props;

  return (
    <Link
      className="result-item"
      to={`${path}${item.id}`}
      onClick={() => {
        handleClick();
      }}
    >
      <div className="image">
        <div className="medias">
          {type == "series" && get(item, "seriePicture.uri", "") &&
            <span
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_IMG_URL
                }/storage/serie-picture/${get(item, "seriePicture.uri", "")}`,
              }}
            />
          }
          {type == "quiz" && get(item, "thumbnail.uri", "") &&
            <span
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_IMG_URL
                }/storage/quiz-thumbnail/${get(item, 'thumbnail.uri', '')}`,
              }}
            />
          }
          {type == "speakers" && get(item, "speakerPicture.uri", "") &&
            <span
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_IMG_URL
                }/storage/speaker-picture/${get(item, 'speakerPicture.uri', '')})`,
              }}
            />
          }
        </div>
      </div>
      <div className="info">
        <div className="title">{type == "speakers" ? item.name : item.title}</div>

        {type == "series" && (
          <div className="description">{item.videos.length} {item.videos.length == 1 ? t('XEpisode') : t('XEpisodes')}</div>
        )}
        {type == "speakers" && (
          <>
            <div className="description">{item.jobTitle}</div>
            <div className="description">{item.hotel?.name}</div>
          </>
        )}
      </div>
    </Link>
  );
}

export default withRouter(NavSearchItem);
