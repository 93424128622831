import React, { Fragment, useEffect, useState } from 'react';
import Wrapper from "../wrapper";
import "./styles.scss";
import SearchBar from "./searchBar";
import moment from "moment/moment";
import { useGlobalContext } from "../../../store/global";
import { getRecipes, getRecipeCategories } from '../../../utils/data'
import { useTranslation } from "react-i18next";

const PAGE_SIZE = 10;

// const concatRecipe = (previousRecipes, newRecipes) => {
//     const joinedRecipes = { ...previousRecipes }

//     for (const recipe of newRecipes) {
//         if (!!joinedRecipes[recipe.category.id]) {
//             joinedRecipes[recipe.category.id].recipes.push(recipe)
//         } else {
//             joinedRecipes[recipe.category.id] = {
//                 ...recipe.category,
//                 recipes: [recipe]
//             }
//         }
//     }

//     return joinedRecipes
// }

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay ?? 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

const parseTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.round(time - minutes * 60, 2);

    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

const Directory = () => {
    const { t } = useTranslation();
    const [q, setQ] = useState("");
    const debouncedQ = useDebounce(q, 500);

    // const [order, setOrder] = useState("ASC");
    const [isLoading, setIsLoading] = useState(false);
    // const [showOrder, setShowOrder] = useState(false);
    const [categories, setCategories] = useState();

    const [isAllEmpty, setIsAllEmpty] = useState({
        catCount: 0,
        emptyCount: 0,
        fullCount: 0,
    });

    useEffect(() => {
        setIsLoading(true)
        getRecipeCategories().then((res) => {
            setIsLoading(false)
            setCategories(res.data)
            setIsAllEmpty(e => ({ ...e, catCount: res.data.length || 0}))
        })
    }, []);

    useEffect(() => {
        setIsAllEmpty(e => ({
            ...e,
            emptyCount: 0,
            fullCount: 0
        }))
    }, [debouncedQ]);

    return (
        <Wrapper>
            <div id="content">
                <section className="classics">
                    <div className="viewport">
                        <div className="inner">
                            <SearchBar
                                searchValue={q}
                                onSearchChange={setQ}
                                onClick={() => {}}
                            />

                            {/* <div className='order-wrapper'>
                                {!isLoading &&
                                    <div className="order">
                                        <span className="label">
                                            <span className="icon-sorting" />
                                            Sort by
                                        </span>

                                        <span
                                            className="value"
                                            onClick={() => setShowOrder((e) => !e)}
                                        >
                                            {{
                                                'ASC': 'A-Z',
                                                'DESC': 'Z-A',
                                            }[order]}

                                            <div className={`overlay${showOrder ? " open" : ""}`} />

                                            <div className={`popup${showOrder ? " open" : ""}`}>
                                                <div
                                                    className={order === "ASC" ? "active" : ""}
                                                    onClick={() => onOrderClick("ASC")}
                                                >
                                                    <span className="icon-check" />
                                                    <span>A-Z</span>
                                                </div>
                                                <div
                                                    className={order === "DESC" ? "active" : ""}
                                                    onClick={() => onOrderClick("DESC")}
                                                >
                                                    <span className="icon-check" />
                                                    <span>Z-A</span>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                }
                            </div> */}

                            <div className='list'>
                                {categories?.sort((a, b) => a.order - b.order).map((cat, index) =>
                                    <RecipeList
                                        key={cat.id}
                                        category={cat.id}
                                        name={cat.name}
                                        q={debouncedQ}
                                        onLoadedFull={() => setIsAllEmpty((e) => ({
                                            ...e,
                                            fullCount: e.fullCount + 1
                                        }))}
                                        onLoadedEmpty={() => setIsAllEmpty((e) => ({
                                            ...e,
                                            emptyCount: e.emptyCount + 1
                                        }))}
                                    />
                                )}
                                <div className='loader-wrapper'>
                                    {(isLoading || isAllEmpty.catCount > (isAllEmpty.emptyCount + isAllEmpty.fullCount)) &&
                                        `${t('Loading')}...`
                                    }
                                    {(!isLoading && isAllEmpty.catCount === isAllEmpty.emptyCount) &&
                                        t('No_result_for_your_search')
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Wrapper>
    )
}

export default Directory;

const RecipeList = ({ category, name, q, onLoadedFull, onLoadedEmpty }) => {
    const { t } = useTranslation();
    const [recipes, setRecipes] = useState();
    const [page, setPage] = useState(0);
    const [totalRecipes, setTotalRecipes] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const { openRecipeModal } = useGlobalContext();

    const getNextPage = async () => {
        setIsLoading(true);
        const nexPage = page + 1;
        const res = await getRecipes({
        q,
        category,
        range: `[${nexPage * PAGE_SIZE},${
            nexPage * PAGE_SIZE + (PAGE_SIZE - 1)
        }]`,
        sort: `["title", "ASC"]`
        });
        setRecipes((e) => [...(e || []), ...(res?.data || [])]);
        setTotalRecipes(
            parseInt(res?.headers?.["content-range"]?.split(" ")[1]?.split("/")[1])
        );
        setPage((e) => (res?.data.length < PAGE_SIZE ? null : nexPage));
        setIsLoading(false);
    };

    useEffect(() => {
        const launchSearch = async () => {
            const res = await getRecipes({
                q,
                category,
                range: `[0,${PAGE_SIZE - 1}]`,
                sort: `["title", "ASC"]`
            });
            setRecipes((e) => [...(e || []), ...(res?.data || [])]);
            setTotalRecipes(
                parseInt(res?.headers?.["content-range"]?.split(" ")[1]?.split("/")[1])
            );
            if (res?.data.length < PAGE_SIZE) {
                setPage(null)
            }

            if (res?.data.length > 0) {
                onLoadedFull()
            } else {
                onLoadedEmpty()
            }

            setIsLoading(false);
        };

        setIsLoading(true);
        setRecipes(undefined);
        launchSearch();
        setPage(0);
    }, [q]);

    if (recipes === undefined) return null;

    const isEmpty = !isLoading && !recipes?.length;

    if (isEmpty) return null

    return (
        <Fragment>
            <div className='category'>
                <span className='name'>
                    {name}
                </span>
                <span className='count'>
                    {`${totalRecipes || 0} ${totalRecipes > 1 ? t('Recipes') : t('Recipe')}`}
                </span>
            </div>
            <div>
                {isEmpty && <div className='empty'><span>No recipes</span></div>}
                {recipes?.map((recipe) => (
                    <div
                        key={recipe.id}
                        className={`recipe${
                            recipe.userComplete ? " watched" : ""
                        }`}
                        onClick={() => openRecipeModal({
                            apId: recipe.id,
                            wistiaHashId: recipe.wistiaHashId,
                            description: recipe.ingredients,
                        })}
                    >
                        <div className="wrapper-info">
                        <div className="name">
                            <span className="icon-check" />
                            <span>{recipe.title}</span>
                        </div>
                        <div className="time">
                            <span>{parseTime(recipe.duration)}</span>
                            <span>{`Realeased on ${moment(recipe.createdAt).format("MMM DD, YYYY")}`}</span>
                        </div>
                        </div>
                        <span className="mobile-released">{`Realeased on ${moment().format("MMM DD, YYYY")}`}</span>
                    </div>
                ))}

                {page !== null && !isEmpty && (
                    <div className='load-wrapper'>
                        <button
                            disabled={isLoading}
                            className="tertiary vsmall"
                            onClick={getNextPage}
                        >
                            <span>{isLoading ? t("Loading") : t("Load_More")}</span>
                        </button>
                    </div>
                )}
            </div>
        </Fragment>
    )
}