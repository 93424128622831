import createContextStore from './hooks/createContextStore';
import moment from 'moment';
import http from '../utils/axios';

const checkAuth = () => {
  const token = localStorage.getItem('token');
  if (token) {
    return true;
  }
  localStorage.clear();
  return false;
};

// const getUser = () => {
//   const savedUser = localStorage.getItem('user')

//   try {
//     if (savedUser) {
//       return JSON.parse(savedUser)
//     } else {
//       return {}
//     }    
//   } catch (error) {
//     return {}
//   }
// }

const initialState = {
  userAuthed: checkAuth(),
  modalOpen: false,
  canCloseModal: true,
  modalType: '',
  modalCallback: null,
  modalOnBack: null,
  modalData: null,
  loading: false,
  token: localStorage.getItem('token'),
  // user: getUser(),
  hasRegistered: false,
};

const [GlobalProvider, useGlobalContext] = createContextStore(({ state, setState }) => {
  const logout = () => {
    localStorage.clear();
    setState({
      userAuthed: false,
      user: {},
      token: null,
    });
  };

  const login = async (email, password) => {
    setLoading(true);
    try {
      const res = await http.post(
        `/auth/login`,
        {
          email,
          password,
        },
        {}
      );
      if (res.status === 200 && res.data) {
        const { /*user,*/ token, expireAt } = res.data;
        localStorage.setItem('token', token);
        localStorage.setItem('expire', expireAt);
        // localStorage.setItem('user', JSON.stringify(user));
        setState({
          // user,
          token,
          userAuthed: true,
        });
      }
    } catch (e) {
      setLoading(false);
      openModal('credentials');
      console.error(e);
    }
  };

  const ssologin = async (token) => {
    try {
      localStorage.setItem('token', token);
      const res = await http.get('/auth/token', {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      if (res.data.success) {
        setState({
          userAuthed: true,
        });
      } else {
        localStorage.clear();
        openModal('ssofail');
        setLoading(false);
      }
    } catch (e) {
      openModal('ssofail');
      setLoading(false);
      console.error(e);
    }
  };

  const openModal = (modalType, canClose = true, onOk = null, onBack = null, data = null) => {
    document.body.classList.add('popup-opened');
    setState({
      modalOpen: true,
      modalType,
      canCloseModal: canClose,
      modalCallback: onOk,
      modalOnBack: onBack,
      modalData: data,
    });
  };

  const closeModal = () => {
    document.body.classList.remove('popup-opened');
    setState({
      modalOpen: false,
      modalType: null,
      canCloseModal: true,
      modalData: null,
    });
  };

  const setLoading = (bool) => {
    setState({
      loading: bool,
    });
  };

  const setHasRegistered = (bool) => {
    if (bool) {
      localStorage.setItem('registered', true);
    } else {
      localStorage.removeItem('registered');
    }
  };

  const openImageModal = (uri) => {
    document.body.classList.add('popup-opened');
    setState({
      imageModal: uri,
      modalOpen: false,
      modalType: null,
      canCloseModal: true,
      modalData: null,
    })
  }

  const closeImageModal = () => {
    document.body.classList.remove('popup-opened');
    setState({
      imageModal: null,
      modalOpen: false,
      modalType: null,
      canCloseModal: true,
      modalData: null,
    })
  }

    const openRecipeModal = (data) => {
    document.body.classList.add('popup-opened');
    setState({
      recipeModal: data,
      modalOpen: false,
      modalType: null,
      canCloseModal: true,
      modalData: null,
    })
  }

  const closeRecipeModal = () => {
    document.body.classList.remove('popup-opened');
    setState({
      recipeModal: null,
      modalOpen: false,
      modalType: null,
      canCloseModal: true,
      modalData: null,
    })
  }

  return {
    role: state.role,
    userAuthed: state.userAuthed,
    login,
    logout,
    openModal,
    closeModal,
    modalOpen: state.modalOpen,
    modalType: state.modalType,
    canCloseModal: state.canCloseModal,
    modalCallback: state.modalCallback,
    modalOnBack: state.modalOnBack,
    modalData: state.modalData,
    name: state.name,
    email: state.email,
    loading: state.loading,
    setLoading,
    user: state.user,
    setUser: (e) => setState((old) => ({ ...old, user: e })),
    hasRegistered: state.hasRegistered,
    setHasRegistered,
    token: state.token,
    ssologin,
    imageModal: state.imageModal,
    openImageModal,
    closeImageModal,
    recipeModal: state.recipeModal,
    openRecipeModal,
    closeRecipeModal,
  };
}, initialState);

export { GlobalProvider, useGlobalContext };
