import React, { useState } from 'react';
import { postComment, editComment as editCommentRequest, deleteComment } from '../../../utils/data';
import get from 'lodash.get';
import { Link } from 'react-router-dom';
import ImageCommentsButton from "../../ui/imageCommentsButton";

export default function Reply(props) {
  const { parent, self, refreshVideo, like, openModal, profile } = props;
  // const { user } = useGlobalContext();

  const [imgComment, setImgComment] = useState();
  const [imgReply, setImgReply] = useState();
  const [showReply, setShowReply] = useState(false);
  const [editMode, setEditMode] = useState(false);
  // const [showModal, setShowModal] = useState(false);
  const [editComment, setEditComment] = useState(self.message);

  const commentIsFromUser = self.user?.id === profile?.id
  const prefix = `@${get(self, 'parent.user.name', '')} ${get(self, 'parent.user.surname', '')} `
  const selfprefix = `@${get(self, 'user.name', '')} ${get(self, 'user.surname', '')} `
  const [editReply, setEditReply] = useState(prefix);

  const toggleShowReply = () => {
    setShowReply(!showReply);
  };

  const addReply = () => {
    const textField = editReply // document.getElementById(`text${self.id}`);
    if (!!textField.substr(selfprefix.length)) {
      postComment(self.video, textField.substr(selfprefix.length), self.id, imgReply).then((res) => {
        refreshVideo(self.video, null, true);
      });
      openModal('reply');
      setImgReply(undefined);
      setEditReply(prefix)
      // textField.value = '';
      toggleShowReply();
    }
  };

  const likeReply = () => {
    like(self.id, !self.userLike);
  };
  
  const formatedDate = () => {
    const commentDate = new Date(self.createdAt)

    const hours = commentDate.getHours();
    const ampm = hours >= 12 ? 'pm' : 'am';
    const h = hours % 12;
    const pmHours =  h ? h : 12;
    const minutes = commentDate.getMinutes();
    const month = commentDate.getMonth() + 1;
    const day = commentDate.getDate();
    const year = commentDate.getFullYear();

    return `${pmHours}:${minutes}${ampm} - ${month}/${day}/${year}`
  }

  const handleEdit = () => {
    if (editMode) {
      setEditComment(self.message)
    } else {
      setImgComment(self.commentPicture)
    }
    setEditMode(!editMode)
  }

  const handleDelete = async () => {
    document.body.classList.remove('popup-opened');
    await deleteComment(self.id)
    openModal('delete-comment')
    refreshVideo(self.video, null, true)
  }

  const handleSave = async () => {
    setEditMode(!editMode)
    await editCommentRequest(self.id, editComment, imgComment)
    setImgReply(undefined);
    openModal('edit-comment')
    refreshVideo(self.video, null, true)
  }

  const handleEditComment = (e) => {
    setEditComment(e.target.value)
  }

  const toggleLikesModal = () => {
    openModal('likes', true, null, null, self.id);
  }

  const openPopDelete = () => {
    document.body.classList.add('popup-opened');
    // setShowModal(true)
    openModal('delete-comment-confirm', true, null, null, handleDelete);
  }

  return (
    <>
      <div className="comment-item-reply">
        {get(self, 'user.profilePicture.uri', '') !== '' && (
          <LinkOptional
            to={`/profile/${get(self, 'user.id', '')}`}
            disabled={!profile?.role || (profile?.role !== 'admin' && profile?.role !== 'super-admin' && profile?.role !== 'hotel')}
          >
            <div className="profile-pic">
              <span
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_IMG_URL}/storage/user-picture/${get(
                    self,
                    'user.profilePicture.uri',
                    ''
                  )})`,
                }}
              ></span>
            </div>
          </LinkOptional>
        )}
        {get(self, 'user.profilePicture.uri', '') === '' && (
          <LinkOptional
            to={`/profile/${get(self, 'user.id', '')}`}
            disabled={!profile?.role || (profile?.role !== 'admin' && profile?.role !== 'super-admin' && profile?.role !== 'hotel')}
          >
            <div className="profile-pic">
              <span style={{ backgroundImage: `url('/assets/images/profile.png')` }}></span>
            </div>
          </LinkOptional>
        )}
        <div className="comment-content">
          <LinkOptional
            to={`/profile/${get(self, 'user.id', '')}`}
            disabled={!profile?.role || (profile?.role !== 'admin' && profile?.role !== 'super-admin' && profile?.role !== 'hotel')}
          >
            <div className="comment-author">
              {`${get(self, 'user.name', '')} ${get(
                self,
                'user.surname',
                ''
              )}`}
                {!!get(self, 'user.expertViewerAward', null) &&
                  <span className='expert-award'>{self.user.expertViewerAward}<i className="icon-eye1"/></span>}
                {!!get(self, 'user.fastestViewerAward', null) &&
                  <span className='fastest-award'>{self.user.fastestViewerAward}<i className="icon-lightning"/></span>}
                {!!get(self, 'user.starContributorAward', null) &&
                  <span className='contrib-award'>{self.user.starContributorAward}<i className="icon-comment-2"/></span>}
            </div>
          </LinkOptional>
          <div className="form-item">
            <div className="comment-itself">
              {editMode ? (
                <>
                  <textarea
                    value={editComment}
                    onChange={handleEditComment}
                  />
                  <ImageCommentsButton
                    imgComment={imgComment}
                    setImgComment={setImgComment}
                  />
                </>
              ) : (
                <div>
                  {prefix ?
                    <span className='at'>
                      {prefix}
                    </span>
                  :
                    null}
                    {self.message}
                </div>
              )}
            </div>
            {!!editMode && <div style={{
              display: 'flex',
              justifyContent: 'end',
            }}>
              <span style={{
                fontSize: 12,
                fontWeight: 500,
                opacity: 0.7,
              }}>Image comment should be less than 30MB</span>
            </div>}
            {self.commentPicture && !editMode && (
              <img
                src={`${process.env.REACT_APP_IMG_URL}/storage/comment-image/${self.commentPicture?.uri}`}
                alt={self.message}
                style={{ maxHeight: 300, marginTop: 25, marginBottom: 10 }}
              />
            )}
            <div className="comment-date">{formatedDate()}</div>
          </div>
          <div className="like-reply">
            <span className={`like-count ${self.userLike ? 'active' : ''}`} onClick={likeReply}>
              <span className="icon-Heart" />
            </span>
            <span className="reply-span" onClick={toggleLikesModal}>
              {self.likes}
            </span>
            <span className="reply-span" onClick={toggleShowReply}>
              Reply
            </span>
            {commentIsFromUser &&
              <>
                {editMode && <span className="comment-save" onClick={handleSave}>Save</span>}
                <span className="comment-edit" onClick={handleEdit}>{editMode ? 'Cancel' : 'Edit'}</span>
                {!editMode && <span className="comment-delete" onClick={openPopDelete}>Delete</span>}
              </>
            }
          </div>
        </div>
      </div>
      {showReply && (
        <div class="comment-item-write">
          {get(profile, 'profilePicture.uri', '') !== '' && (
            <div className="profile-pic">
              <span
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_IMG_URL}/storage/user-picture/${get(
                    profile,
                    'profilePicture.uri',
                    ''
                  )})`,
                }}
              ></span>
            </div>
          )}
          {get(profile, 'profilePicture.uri', '') === '' && (
            <div className="profile-pic">
              <span style={{ backgroundImage: `url('/assets/images/profile.png')` }}></span>
            </div>
          )}
          <div class="comment-content">
            <div class="comment-author">{`${get(profile, 'name', '')} ${get(
              profile,
              'surname',
              ''
            )}`}</div>

            <div class="form-item" style={{ position: 'relative' }}>
              <textarea
                name=""
                value={editReply}
                id={`text${self.id}`}
                // ref={(target)=>{
                //   if (target)
                //     target.value = selfprefix
                // }}
                onChange={(e) => {
                  const input = e.target.value;
                  // e.target.value = prefix + input.substr(prefix.length);
                  setEditReply(selfprefix + input.substr(selfprefix.length))
                }}
                cols="30"
                rows="4"
              />

              <ImageCommentsButton
                imgComment={imgReply}
                setImgComment={setImgReply}
              />
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'end',
            }}>
              <span style={{
                fontSize: 12,
                fontWeight: 500,
                opacity: 0.7,
              }}>Image comment should be less than 30MB</span>
            </div>
            <div class="form-actions">
              <button class="x-small secondary" onClick={() => setShowReply(false)}>
                <span>Cancel</span>
              </button>
              <button class="x-small primary" onClick={addReply}>
                <span>Submit</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const LinkOptional = ({ to, className, children, disabled }) => {
  if (disabled) {
    return <span className={className}>{children}</span>
  }

  return <Link to={to} className={className}>{children}</Link>
}