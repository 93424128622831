import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useGlobalContext } from '../../../store/global';
import { getLikedVideo, getWatchlistVideo, getViewedVideo, clearViewedVideo } from '../../../utils/data';
import VideoList from '../home/videoList';
import ViewedVideoList from './viewedVideoList';
import { useTranslation } from 'react-i18next'

const Leaderboard = (props) => {
  const { t } = useTranslation();
  const { setLoading } = useGlobalContext()
  const [listSelected, setListSelected] = useState('watch');
  const [pageData, setPageData] = useState({
    leaderboard: {},
    user: {},
    isFetchingViewed: false,
    canDisplayMoreViewed: true,
    discoverViewedDisplay: 0,
  });

  useEffect(() => {
    setLoading(true)
    const getPageData = async () => {
      const liked = await getLikedVideo();
      const watchlist = await getWatchlistVideo();

      // get view history
      const filters = {
        range: `[${pageData.discoverViewedDisplay},${pageData.discoverViewedDisplay + 9}]`,
      }
      const viewed = await getViewedVideo(filters);

      setPageData((prevState) => ({
        ...prevState,
        likedVideos: liked?.data,
        viewedVideos: viewed?.data,
        watchlistVideos: watchlist?.data.map(e => ({...e, userWatchlist: true})),
        discoverViewedDisplay: prevState.discoverViewedDisplay + viewed.data.length,
      }));
      setTimeout(() => {
        setLoading(false)
      }, 500)
    };

    getPageData();
  }, []);

  const handelFetchMoreViewedVideo = async () => {
    if (pageData.isFetchingViewed) return;

    setPageData((prevState) => ({...prevState, isFetchingViewed: true}));
  
    const filters = {
      range: `[${pageData.discoverViewedDisplay},${pageData.discoverViewedDisplay + 9}]`,
    }

    const viewed = await getViewedVideo(filters);

    if (!viewed.data?.length) {
      setPageData((prevState) => ({
        ...prevState,
        canDisplayMoreViewed: false,
        isFetchingViewed: false,
      }))
      return;
    }


    setPageData((prevState) => ({
      ...prevState,
      viewedVideos: [...prevState.viewedVideos, ...viewed.data],
      discoverViewedDisplay: prevState.discoverViewedDisplay + viewed.data.length,
      isFetchingViewed: false,
    }));
  };

  const handelClearViewedVideo = async (filters) => {
    if (pageData.isFetchingViewed) return;

    setPageData((prevState) => ({...prevState, isFetchingViewed: true}));

    const hidden = await clearViewedVideo(filters);

    const range = {
      range: `[0,${pageData.discoverViewedDisplay - 1}]`,
    }
    const viewed = await getViewedVideo(range);

    setPageData((prevState) => ({
      ...prevState,
      viewedVideos: viewed.data,
      discoverViewedDisplay: viewed.data?.length,
      isFetchingViewed: false,
    }));
  };

  return (
    <div>
      <div id="content">
        <section className="my-lists">
          <div className="viewport">
            <div className="inner">
              <h2 className="center-title">{t('My_Lists')}</h2>
              <div className="watchlist-nav">
                <div
                  className={`${listSelected === 'watch' ? 'active' : ''}`}
                  onClick={e => setListSelected('watch')}
                >
                  {t('WATCH_LATER')}
                </div>
                <div
                  className={`${listSelected === 'viewed' ? 'active' : ''}`}
                  onClick={e => setListSelected('viewed')}
                >
                  {t('VIEWED')}
                </div>
                <div
                  className={`${listSelected === 'liked' ? 'active' : ''}`}
                  onClick={e => setListSelected('liked')}
                >
                  {t('LIKED')}
                </div>
              </div>
              {listSelected === 'watch' && (
                <>
                  {pageData?.watchlistVideos && pageData.watchlistVideos.length > 0 && (
                    <div className="vid-count">
                      {`(${pageData.watchlistVideos.length}${pageData.watchlistVideos.length > 1 ? t('XVideos') : t('XVideo')})`}
                    </div>
                  )}
                  {pageData.watchlistVideos?.length === 0 && (
                    <div className="empty">
                      <h2 className="title">{t('No_Result_For_Your_Search')}</h2>
                    </div>
                  )}
                  <VideoList
                    onWatchlistDelete={(id) => {
                      setPageData((prevState) => ({
                        ...prevState,
                        watchlistVideos: prevState.watchlistVideos.filter(e => e.id !== id),
                      }));
                    }}
                    videos={pageData?.watchlistVideos || []}
                    limit={pageData?.watchlistVideos?.length || 0}
                  />
                </>
              )} 
              {listSelected === 'liked' && (
                <>
                  {pageData?.likedVideos && pageData.likedVideos.length > 0 && (
                    <div className="vid-count">
                      {`(${pageData.likedVideos.length}${pageData.likedVideos.length > 1 ? t('XVideos') : t('XVideo')})`}
                    </div>
                  )}
                  {pageData.likedVideos?.length === 0 && (
                    <div className="empty">
                      <h2 className="title">{t('No_Result_For_Your_Search')}</h2>
                    </div>
                  )}
                  <VideoList
                    hideWatchlist
                    videos={pageData?.likedVideos || []}
                    limit={pageData?.likedVideos?.length || 0}
                  />
                </>
              )}
              {listSelected === 'viewed' && (
                <>
                  {pageData.viewedVideos?.length === 0 && (
                    <div className="empty">
                      <h2 className="title">{t('No_Result_For_Your_Search')}</h2>
                    </div>
                  )}
                  <ViewedVideoList
                    videos={pageData?.viewedVideos || []}
                    onViewedClear={handelClearViewedVideo}
                  />
                  {pageData.canDisplayMoreViewed && (
                    <div className="load-more">
                      <button className="tertiary small" onClick={handelFetchMoreViewedVideo}>
                        <span>
                          {pageData.isFetchingViewed ? t('Loading') : t('Discover_More')}
                        </span>
                      </button>
                    </div>
                  )}   
                </>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default withRouter(Leaderboard);