import React from 'react';
import Wrapper from '../wrapper/index.jsx';
import TermsFooter from './termsFooter';
import moment from 'moment';

const Terms = () => {
  return (
    <Wrapper>
      <div id="content">
        <div className="viewport">
          <div className="terms-content">
            <h1>Hyatt I.D.E.A.S. Terms & Conditions</h1>
            <p>
              The I.D.E.A.S. edutainment platform (the “<strong>Site</strong>”) is a virtual space where certain
              individuals working at or for Hyatt’s corporate and regional offices and Hyatt-branded
              hotels and resorts in Asia Pacific (“<strong>you</strong>” or “<strong>your</strong>” or “<strong>Colleague</strong>”) my browse
              educational materials and videos relating to restaurant, bar, and event management in
              Asia Pacific. Such materials and videos are collectively referred to as the “<strong>Program</strong>.”
              Hyatt’s provision of the Program is referred to as the “Services.” Your use of the
              Site and Services is subject to these Terms & Conditions along with any specific terms
              and conditions set forth on the individual pages within the Site, if any,
              (collectively, the “<strong>Terms & Conditions</strong>”) and all applicable laws and regulations,
              including those regarding copyright, trademark, and other intellectual property
              rights. Your submission of information on the Site is subject to Hyatt’s Global
              Privacy Policy (available at <a href="http://www.hyatt.com/hyatt/customer-service/privacy-policy.jsp" className="discover-style">privacy.hyatt.com</a>), which is hereby incorporated into
              these Terms & Conditions. These Terms govern your participation in the Program and any
              materials distributed via the Program and/or Site, unless superseded by an applicable
              contract between you and us. As used throughout the Site, the terms “<strong>Hyatt</strong>,” “<strong>the
              Company</strong>,” “<strong>our</strong>,” “<strong>we</strong>,” “<strong>us</strong>,” and other similar terms may refer to one or more of Hyatt
              Hotels Corporation, Hyatt Corporation, Hyatt International Corporation, Select Hotels
              Group, LLC, or one or more of their direct or indirect subsidiaries or affiliates.
              These terms are used for convenience only and are not intended as a precise
              designation of any one particular entity.
            </p>
            <p className="indent">1. Agreement to these Terms</p>
            <p>
              By accessing, viewing or otherwise using the Site and participating in the Program,
              you agree to be bound by these Terms. These Terms establish the terms on which the
              Program is made available on the Site. If you do not agree, then you are not permitted
              to use the Site. Certain areas and features of the Site or of the Services may have
              additional terms, and you should read and agree to those additional terms before using
              those areas and features. If there’s a conflict between these Terms and the additional
              terms, the additional terms will govern your use of those areas of the Site or
              features of the Services. By accessing, viewing or otherwise using the Site or
              Services, you further agree to comply with all applicable Hyatt policies, and that you
              will not use the Services for any unauthorized purpose. You further acknowledge and
              agree that any use of the Services is personal to you and access may not be provided
              to any other person. Any use beyond the permissible scope in violation of applicable
              laws and/or Hyatt’s policies could subject you to legal and/or disciplinary action up
              to and including or termination. from the Services.
            </p>
            <p className="indent">2. Eligibility</p>
            <p>
              You may only use the services of the Site if you are at least 18 years of age and can
              form legally binding contracts under applicable law and are a Colleague. If you cease
              to be a Colleague, you may no longer use the Services. However, Hyatt may, in its sole
              discretion, continue to grant access to the Site to Colleagues who cease to be a Hyatt
              colleague for any reason.
            </p>
            <p className="indent">3. Accounts / Passwords</p>
            <p>
              You are responsible for maintaining the secrecy of your passwords, log-in, and account
              information. You will be accountable for all uses of the Site by you and/or by anyone
              using your account information.
            </p>
            <p className="indent">
              4. Hyatt’s Right to Cancel / Errors / Mistakes/ Educational Purposes Only
            </p>
            <p>
              The Site may contain technical inaccuracies and typographical or other errors in
              connection with information displayed on the Site. Hyatt assumes no responsibility or
              liability for such errors, inaccuracies, or omissions. The information contained on
              this Site and the resources available for use through the Site are for educational and
              informational purposes only. Hyatt is not responsible for reliance on the Services or
              business outcomes thereto.
            </p>
            <p className="indent">5. Prohibited Uses</p>
            <p>
              You may not use the Site to post or transmit any unlawful, infringing, threatening,
              libelous, defamatory, obscene, indecent, inflammatory, pornographic or profane
              material or any material that could constitute or encourage conduct that would be
              considered a criminal offense, give rise to civil liability, or otherwise violate any
              law, or for any other purpose that is unlawful or prohibited by these Terms &
              Conditions. You agree that you will not (a) interfere with or disrupt the Site, (b)
              use, place, or distribute any viruses, worms, time bombs, and/or other computer
              programming routines that damage, intercept, disrupt, or harm (or are intended to do
              any of the preceding) the Site, including the underlying software, hardware, systems,
              and any users of the Site or their devices, (c) use or attempt to use any robot,
              spider, automatic device, or automatic or manual process to monitor or copy the Site
              without Hyatt’s prior express written consent, or (d) use any device, software, or
              other instrument to monitor, copy, interfere with, or attempt to interfere with the
              Site, its content, or its operation without Hyatt’s prior express written consent.
            </p>
            <p className="indent">6. Links and Third-Party Content</p>
            <p>
              The Site may contain links to other web sites on the Internet, or otherwise feature
              third-party content, and certain actions on the Site and/or features may result in
              specific advertising from third parties, including information that may be displayed
              in other windows or in third party sites. Hyatt provides these links and/or
              third-party content solely as a convenience to you. The inclusion of these links and
              content in no way indicates Hyatt’s endorsement, support, or approval of such sites,
              such site’s content, products, services, or the operators of any such site. We
              undertake no obligation to review or monitor any sites linked to or from the Site, or
              any third-party content featured on the Site. Your use of any such site is at your own
              risk and subject to the terms and conditions of such sites. We are not responsible for
              the content, products, services, or practices of third party sites.
            </p>
            <p className="indent">7. Miscommunications / Lost Transactions</p>
            <p>
              Hyatt is not responsible for communication failures, errors, difficulties, or other
              malfunctions or lost, stolen, or misdirected transactions, transmissions, messages, or
              entries on or in connection with the Site. Hyatt is not responsible for any incorrect
              information associated with any transaction or transmission on or to the Site
              regardless of whether such incident is the result of user error, system error, or
              human error.{' '}
            </p>
            <p className="indent">8. Availability</p>
            <p>
              The Site is available to anyone with authorized access. However, the Site may not be
              continuously available due to maintenance or repairs or due to computer or mobile
              device problems or crashes, disruption in Internet service or other unforeseen
              circumstances. A reference to a product or service on the Site does not imply that
              such product or service is or will be available in your location. The content of the
              Site, including advertising content, is intended for use and display only where its
              use and display are permissible in accordance with applicable laws and regulations.
              Each product or service is void where prohibited by law.
            </p>
            <p className="indent">9. General / Ownership</p>
            <p>
              The content included on the Site, including but not limited to text, software,
              photographs, images, graphics, illustrations, artwork, video, music, sound, and names,
              logos, trademarks and service marks, are the property of Hyatt or its licensors or
              suppliers. This content is protected by copyright, trademark and other laws. No right,
              title, or interest to the content is granted by your use of the Site, other than a
              right to review the content using a conventional Internet browser to conduct ordinary
              web browsing. All other uses, including making copies of any content on the Site, are
              strictly prohibited. You may not modify, reproduce, retransmit, distribute,
              disseminate, sell, publish, broadcast or circulate any such material for any purpose
              other than personal, non-commercial use (or legitimate activities of a travel agent or
              travel professional) without Hyatt’s written permission. Except for non-commercial
              individual private use, the downloading, reproduction, or retransmission of the Site
              (or any part of its content) is strictly prohibited.
            </p>
            <p className="indent">
              <i>Feedback</i>
            </p>{' '}
            <p>
              We welcome your input for improvements to the Services. If you decide to provide
              ideas, comments, suggestions or other feedback to us (whether written, verbal or in
              any other format) in connection with the Services or these Terms (“<strong>Feedback</strong>"), you
              acknowledge and agree that we have no obligation (whether of confidentiality,
              compensation or otherwise) with respect to such Feedback and we will be free to use
              and exploit the same in any manner without restriction of any kind. You agree that all
              Feedback will be our sole and exclusive property. You hereby irrevocably transfer and
              assign to us and agree to irrevocably transfer and assign to us all of your right,
              title, and interest in and to all Feedback, including all intellectual property rights
              therein. At our request and expense, you will execute documents and take such further
              acts as we may reasonably request to assist us to acquire, perfect and maintain our
              intellectual property rights and other legal protections for the Feedback. Further,
              you acknowledge and agree that we may currently or in the future be developing
              information and materials internally, or receiving information and materials from
              other parties, that are similar to your Feedback. Nothing in these Terms will be
              construed as a representation or agreement that we will not develop or have developed,
              distribute, market or promote applications, services, products or concepts that are
              similar to and/or compete with the applications, services, products or concepts
              contemplated by or embodied in any Feedback.
            </p>{' '}
            <p className="indent">10. Copyright</p>
            <p>
              All content on the Site is the copyrighted property of Hyatt or its affiliates or
              licensors. All content of the Site is protected by United States copyright laws as
              well as the applicable laws of other jurisdictions. Notice and Procedures for Making
              Claims of Copyright Infringement Hyatt respects the intellectual property rights of
              third parties. Hyatt responds to notices of alleged copyright infringement according
              to the Digital Millennium Copyright Act (“DMCA”) at 17 U.S.C. § 512 et seq. Regardless
              of whether or not Hyatt believes that it is liable for any copyright infringement for
              which we are provided notice, Hyatt’s response may include removing or disabling
              access to material claimed to be the subject of infringing activity and/or terminating
              an individual’s access to the Site, at Hyatt’s sole discretion and operating within
              the parameters of the DMCA.
            </p>{' '}
            <p>
              If you believe that your work has been copied in a manner that constitutes copyright
              infringement, please contact:
            </p>{' '}
            <p className="address">Hyatt Corporation</p>{' '}
            <p className="address">Attn: Copyright Agent, Legal Department</p>{' '}
            <p className="address">150 North Riverside Plaza,</p>
            <p className="address">Chicago, IL 60606</p>
            <p className="address">copyright@hyatt.com</p>
            <br />
            <p>with the following information:</p>
            <ul>
              <li>Your name, address, telephone number and email address;</li>
              <li>A description of the copyrighted work that you claim has been infringed;</li>
              <li>
                A description of the allegedly infringing material and where it is located on the
                Site;
              </li>
              <li>
                A statement by you that you have a good faith belief that the disputed use is not
                authorized by the copyright owner, its agents, or the law;
              </li>
              <li>
                A statement by you under penalty of perjury that your notice is accurate, that you
                are the copyright owner or that the copyright holder has authorized you to act on
                its behalf; and
              </li>
              <li>Your written or electronic signature attesting to the above.</li>
            </ul>{' '}
            <p>
              If your content has been removed from the Site in response to Hyatt’s receipt of a
              DMCA Notification as outlined above, and you believe the removal was inappropriate,
              you may submit a DMCA Counter-Notification by contacting Hyatt at:
            </p>{' '}
            <p className="address">Hyatt Corporation</p>{' '}
            <p className="address">Attn: Copyright Agent, Legal Department</p>{' '}
            <p className="address">150 North Riverside Plaza,</p>
            <p className="address">Chicago, IL 60606</p>
            <p className="address">copyright@hyatt.com</p>
            <br />
            <p>with the following information:</p>
            <ul>
              <li>Your name, address, telephone number and email address;</li>
              <li>
                A statement that you consent to the jurisdiction of Federal District Court for the
                judicial district in which your address is located, or if your address is outside of
                the United States, for any judicial district for which jurisdiction for Hyatt would
                be appropriate, and that you will accept service of process from the person who
                submitted the DMCA notification or an agent of such person;
              </li>
              <li>
                A description of the material that has been removed or to which access has been
                disabled and the location at which the material appeared before it was removed or
                access to it was disabled
              </li>
              <li>
                A statement by you under penalty of perjury that you have a good faith belief that
                the material was removed or disabled as a result of mistake or misidentification of
                the material to be removed or disabled; and
              </li>
              <li>Your written or electronic signature attesting to the above.</li>
            </ul>{' '}
            <p className="indent">11. Right to Restrict Access </p>
            <p>
              In addition to any other rights or remedies that may be available to Hyatt, Hyatt may,
              subject to any other applicable contract entered into by and between you and Hyatt, in
              its sole discretion and without any liability whatsoever, terminate or restrict your
              access or use of the Site at any time and without notice.
            </p>{' '}
            <p className="indent">12. Warranty Disclaimer</p>
            <p>
              YOUR USE OF THE SITE IS AT YOUR OWN RISK. THE SITE IS PROVIDED ON AN “AS IS” BASIS
              WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
              TO WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
              PARTICULAR PURPOSE, OTHER THAN THOSE WARRANTIES THAT ARE IMPOSED BY AND INCAPABLE OF
              EXCLUSION, RESTRICTION OR MODIFICATION UNDER THE LAWS APPLICABLE TO THIS AGREEMENT.
            </p>{' '}
            <p className="indent">13. Limitation of Liability </p>
            <p>
              NEITHER HYATT NOR ANY HYATT PARENT NOR ANY HYATT SUBSIDIARY, DIVISION, AFFILIATE,
              AGENT, REPRESENTATIVE OR LICENSOR SHALL BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSS
              OR INJURY OR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE OR
              SIMILAR DAMAGES ARISING OUT OF YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS
              OR USE, THE SITE OR ANY MATERIALS, OPINIONS OR RECOMMENDATIONS OF THIRD PARTIES ON THE
              SITE. THIS LIMITATION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT,
              TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF HYATT HAS BEEN ADVISED
              OF THE POSSIBILITY OF SUCH DAMAGE. IN JURISDICTIONS THAT DO NOT ALLOW THE EXCLUSION OR
              LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, HYATT’S LIABILITY IN SUCH
              JURISDICTIONS SHALL BE LIMITED TO THE EXTENT PERMITTED BY LAW. YOU HEREBY WAIVE ANY
              AND ALL CLAIMS AGAINST HYATT, ITS PARENT, AND ITS SUBSIDIARIES, DIVISIONS, AFFILIATES,
              AGENTS, REPRESENTATIVES AND LICENSORS ARISING OUT OF YOUR USE OF THE SITE OR ANY
              MATERIALS, OPINIONS OR RECOMMENDATIONS OF THIRD PARTIES ON THE SITE.
            </p>
            <p className="indent">14. Jurisdiction / Governing Law / Choice of Forum</p>
            <p>
              The Site is controlled and operated by Hyatt. Hyatt makes no representation that the
              content or materials on the Site are appropriate or available for use in a particular
              location. These Terms & Conditions and any dispute arising out of or related to the
              Terms & Conditions or use of the Site shall be governed in all respects by and
              construed and enforced in accordance with the laws of Hong Kong, excluding its choice
              of law principles, and all claims relating to or arising out of this Agreement, or the
              breach thereof, whether in contract, tort or otherwise. All disputes to this Agreement
              shall be submitted to arbitration in Hong Kong accordingly to the applicable rules of
              the International Chamber of Commerce. You further agree to submit to the exercise of
              personal jurisdiction of such courts for the purpose of litigating any such claim or
              action.
            </p>{' '}
            <p className="indent">15. Severability </p>
            <p>
              If any part of these Terms & Conditions is unenforceable, the unenforceable part shall
              be construed to reflect, as nearly as possible, the original intentions of the
              parties. All other provisions of these Terms & Conditions shall remain in full force
              and effect.
            </p>
            <p className="indent">16. Hyatt Trademarks</p>
            <p>
              Hyatt and related marks used on the Site are trademarks of Hyatt Corporation or its
              affiliates. The use of the ® symbol designates marks that are registered with the U.S.
              Patent and Trademark Office, and such marks may also be registered with the trademark
              offices of certain other countries. Those marks and related names, trademarks, logos,
              designs, and trade dress shown on the Site are owned by Hyatt and protected by the
              trademark laws of the United States and other jurisdictions.
            </p>{' '}
            <p className="indent">17. Forward Looking Statements </p>
            <p>
              Certain statements and assumptions in the Site contain or are based upon
              “forward-looking” information and are being made pursuant to the safe harbor
              provisions of the Private Securities Litigation Reform Act of 1995. When we use the
              words “will,” may,” “anticipate,” “estimate,” “should,” “expect,” “believe,” “intend,”
              or similar expressions, we intend to identify forward-looking statements. Such
              statements are subject to numerous assumptions and uncertainties as described in our
              Annual Report on Form 10-K, Quarterly Reports on Form 10-Q and other SEC filings which
              could cause our actual results to differ materially from those expressed in or implied
              by the content of the Site. Forward looking statements made in the Site are made only
              as of the date of their initial publication and we undertake no obligation to publicly
              update any of these forward looking statements as actual events unfold.
            </p>{' '}
            <p className="indent">18. No Waiver</p>
            <p>
              Hyatt’s failure to insist upon or enforce strict performance of any provision of these
              Terms & Conditions shall not constitute a waiver of the provision. Neither a course of
              dealing or conduct between you and us nor any trade practices shall be deemed to
              modify these Terms & Conditions.
            </p>{' '}
            <p>
              If you have any questions or concerns regarding the Site, please contact us at{' '}
              <span> </span>
              <a href="mailto:richard.vanwesting@hyatt.com" className="discover-style">
                richard.vanwesting@hyatt.com
              </a>
              . As noted in the Confidentiality section above, any communication or material
              transmitted to us via the Site or Internet e-mail is transmitted on a non-confidential
              basis.
            </p>{' '}
            <p>{`Revised April 2021`}</p>{' '}
            <p>{`©${moment().year()} Hyatt Corporation. All Rights Reserved.`}</p>
          </div>
          <TermsFooter />
        </div>
      </div>
    </Wrapper>
  );
};

export default Terms;
