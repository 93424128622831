import React from 'react';
import { useGlobalContext } from '../../../store/global';
import { sendSuggestion } from '../../../utils/data';

export default function Suggestion() {
  const { closeModal, openModal } = useGlobalContext();

  const handleSubit = (e) => {
    e.preventDefault();
    const field = document.getElementById('suggestionbox');
    if (field.value) {
      sendSuggestion(field.value);
      openModal('thanks');
    }
  };

  return (
    <div className="popup-inner">
      <div className="popup-title">
        <h2>Give us your suggestions to improve I.D.E.A.S.</h2>
        <div className="close icon-x" onClick={closeModal}></div>
      </div>

      <div className="popup-content">
        <form action="">
          <div className="form-item">
            <label htmlFor="">Type your text here:</label>
            <textarea
              name=""
              id="suggestionbox"
              cols="30"
              rows="4"
              maxLength={500}
              placeholder="Limited to 500 characters"
              style={{ marginTop: '5px' }}
            />
          </div>
          <div className="form-actions">
            <button className="primary big" onClick={handleSubit}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
