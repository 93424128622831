import React, { useState } from "react";
import {
  postComment,
  editComment as editCommentRequest,
  deleteComment,
} from "../../../utils/data";
import Reply from "./reply";
import get from "lodash.get";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ImageCommentsButton from "../../ui/imageCommentsButton";

export default function Comment(props) {
  const { comment, refreshVideo, like, openModal, profile } = props;
  // const { user } = useGlobalContext();

  const [maxReplies, setMaxReplies] = useState(2);
  const [showReply, setShowReply] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [imgComment, setImgComment] = useState();
  const [imgReply, setImgReply] = useState();
  const prefix = `@${get(comment, "user.name", "")} ${get(
    comment,
    "user.surname",
    ""
  )} `;
  const [editReply, setEditReply] = useState(prefix);
  const [editComment, setEditComment] = useState(comment.message);
  const { t } = useTranslation();

  const commentIsFromUser = comment.user.id === profile.id;

  const toggleReply = () => {
    setShowReply(!showReply);
  };

  const addReply = () => {
    const textField = editReply // document.getElementById(`text${comment.id}`);
    if (!!textField.substr(prefix.length)) {
      postComment(
        comment.video,
        textField.substr(prefix.length),
        comment.id,
        imgReply
      ).then((res) => {
        openModal("reply");
        refreshVideo(comment.video, null, true);
      });
      setImgReply(undefined);
      setEditReply(prefix)
      // textField.value = "";
      toggleReply();
    }
  };

  const increaseMaxReplies = () => {
    setMaxReplies(maxReplies + 2);
  };

  const likeComment = () => {
    like(comment.id, !comment.userLike);
  };

  const formatedDate = () => {
    const commentDate = new Date(comment.createdAt);

    const hours = commentDate.getHours();
    const ampm = hours >= 12 ? "pm" : "am";
    const h = hours % 12;
    const pmHours = h ? h : 12;
    const minutes = commentDate.getMinutes();
    const month = commentDate.getMonth() + 1;
    const day = commentDate.getDate();
    const year = commentDate.getFullYear();

    return `${pmHours}:${minutes}${ampm} - ${month}/${day}/${year}`;
  };

  const handleEdit = () => {
    if (editMode) {
      setEditComment(comment.message);
    } else {
      setImgComment(comment.commentPicture)
    }
    setEditMode(!editMode);
  };

  const handleDelete = async () => {
    document.body.classList.remove("popup-opened");
    await deleteComment(comment.id);
    openModal("delete-comment");
    refreshVideo(comment.video, null, true);
  };

  const handleSave = async () => {
    setEditMode(!editMode);
    await editCommentRequest(comment.id, editComment, imgComment);
    openModal("edit-comment");
    refreshVideo(comment.video, null, true);
  };

  const handleEditComment = (e) => {
    setEditComment(e.target.value);
  };

  const toggleLikesModal = () => {
    openModal("likes", true, null, null, comment.id);
  };

  const openPopDelete = () => {
    document.body.classList.add("popup-opened");
    openModal("delete-comment-confirm", true, null, null, handleDelete);
  };

  return (
    <>
      <div className="comment-item-wrapper">
        <div className="comment-item-origin">
          {get(comment, "user.profilePicture.uri", "") !== "" && (
            <LinkOptional
              to={`/profile/${get(comment, "user.id", "")}`}
              disabled={!profile?.role || (profile?.role !== 'admin' && profile?.role !== 'super-admin' && profile?.role !== 'hotel')}
            >
              <div className="profile-pic">
                <span
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_IMG_URL
                    }/storage/user-picture/${get(
                      comment,
                      "user.profilePicture.uri",
                      ""
                    )})`,
                  }}
                ></span>
              </div>
            </LinkOptional>
          )}
          {get(comment, "user.profilePicture.uri", "") === "" && (
            <LinkOptional
              to={`/profile/${get(comment, "user.id", "")}`}
              disabled={!profile?.role || (profile?.role !== 'admin' && profile?.role !== 'super-admin' && profile?.role !== 'hotel')}
            >
              <div className="profile-pic">
                <span
                  style={{
                    backgroundImage: `url('/assets/images/profile.png')`,
                  }}
                ></span>
              </div>
            </LinkOptional>
          )}
          <div className="comment-content">
            <LinkOptional
              to={`/profile/${get(comment, "user.id", "")}`}
              disabled={!profile?.role || (profile?.role !== 'admin' && profile?.role !== 'super-admin' && profile?.role !== 'hotel')}
            >
              <div className="comment-author">
                {`${get(comment, "user.name", "")} ${get(
                  comment,
                  "user.surname",
                  ""
                )}`}
                {!!get(comment, "user.expertViewerAward", null) && (
                  <span className="expert-award">
                    {comment.user.expertViewerAward}
                    <i className="icon-eye1" />
                  </span>
                )}
                {!!get(comment, "user.fastestViewerAward", null) && (
                  <span className="fastest-award">
                    {comment.user.fastestViewerAward}
                    <i className="icon-lightning" />
                  </span>
                )}
                {!!get(comment, "user.starContributorAward", null) && (
                  <span className="contrib-award">
                    {comment.user.starContributorAward}
                    <i className="icon-comment-2" />
                  </span>
                )}
              </div>
            </LinkOptional>
            <div className="form-item">
              <div className="comment-itself" style={{ position: "relative" }}>
                {editMode ? (
                  <>
                    <textarea
                      value={editComment}
                      onChange={handleEditComment}
                    />
                    <ImageCommentsButton
                      imgComment={imgComment}
                      setImgComment={setImgComment}
                    />
                  </>
                ) : (
                  <div>{comment.message}</div>
                )}
              </div>
              {!!editMode && <div style={{
                display: 'flex',
                justifyContent: 'end',
              }}>
                <span style={{
                  fontSize: 12,
                  fontWeight: 500,
                  opacity: 0.7,
                }}>Image comment should be less than 30MB</span>
              </div>}
              {comment.commentPicture && !editMode && (
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/storage/comment-image/${comment.commentPicture?.uri}`}
                  alt={comment.message}
                  style={{ maxHeight: 300, marginTop: 25, marginBottom: 10 }}
                />
              )}
              <div className="comment-date">{formatedDate()}</div>
            </div>
            <div className="like-reply">
              <span
                className={`like-count ${comment.userLike ? "active" : ""}`}
                onClick={likeComment}
              >
                <span className="icon-Heart" />
              </span>
              <span className="reply-span" onClick={toggleLikesModal}>
                {comment.likes}
              </span>
              <span className="reply-span" onClick={toggleReply}>
                {t("Reply")}
              </span>
              {commentIsFromUser && (
                <>
                  {editMode && (
                    <span className="comment-save" onClick={handleSave}>
                      Save
                    </span>
                  )}
                  <span className="comment-edit" onClick={handleEdit}>
                    {editMode ? "Cancel" : "Edit"}
                  </span>
                  {!editMode && (
                    <span className="comment-delete" onClick={openPopDelete}>
                      Delete
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {showReply && (
          <div className="comment-item-write">
            {get(profile, "profilePicture.uri", "") !== "" && (
              <div className="profile-pic">
                <span
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_IMG_URL
                    }/storage/user-picture/${get(
                      profile,
                      "profilePicture.uri",
                      ""
                    )})`,
                  }}
                ></span>
              </div>
            )}
            {get(profile, "profilePicture.uri", "") === "" && (
              <div className="profile-pic">
                <span
                  style={{
                    backgroundImage: `url('/assets/images/profile.png')`,
                  }}
                ></span>
              </div>
            )}
            <div className="comment-content">
              <div className="comment-author">{`${get(
                profile,
                "name",
                ""
              )} ${get(profile, "surname", "")}`}</div>

              <div className="form-item" style={{ position: "relative" }}>
                <textarea
                  name=""
                  value={editReply}
                  id={`text${comment.id}`}
                  // ref={(target) => {
                  //   if (target) target.value = prefix;
                  // }}
                  onChange={(e) => {
                    const input = e.target.value;
                    // e.target.value = prefix + input.substr(prefix.length);
                    setEditReply(prefix + input.substr(prefix.length))
                  }}
                  cols="30"
                  rows="4"
                />

                <ImageCommentsButton
                  imgComment={imgReply}
                  setImgComment={setImgReply}
                />
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'end',
              }}>
                <span style={{
                  fontSize: 12,
                  fontWeight: 500,
                  opacity: 0.7,
                }}>Image comment should be less than 30MB</span>
              </div>
              <div className="form-actions">
                <button
                  className="x-small secondary"
                  onClick={() => setShowReply(false)}
                >
                  <span>{t("Cancel")}</span>
                </button>
                <button className="x-small primary" onClick={addReply}>
                  <span>{t("Submit")}</span>
                </button>
              </div>
            </div>
          </div>
        )}
        {comment.children &&
          comment.children
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            .map((r, i) => {
              return i + 1 <= maxReplies ? (
                <Reply
                  key={r.id}
                  parent={comment}
                  self={r}
                  refreshVideo={refreshVideo}
                  like={like}
                  openModal={openModal}
                  profile={profile}
                />
              ) : null;
            })}
        {comment.children.length > maxReplies && (
          <span
            className="reply-span replies-show-more"
            onClick={increaseMaxReplies}
          >
            {t("Show_More")}
          </span>
        )}
      </div>
    </>
  );
}

const LinkOptional = ({ to, className, children, disabled }) => {
  if (disabled) {
    return <span className={className}>{children}</span>
  }

  return <Link to={to} className={className}>{children}</Link>
}