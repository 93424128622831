import Home from "../components/pages/home";
import Category from "../components/pages/category";
import Leaderboard from "../components/pages/leaderboard";
import MyList from "../components/pages/myList";
import Series from "../components/pages/series";
import Speaker from "../components/pages/speaker";
import Search from "../components/pages/search";
import Video from "../components/pages/video";
import Profile from "../components/pages/profile";
import ProfileUser from "../components/pages/profileUser";
import EditProfile from "../components/pages/edit";
import NotFound from "../components/pages/404";
import Terms from "../components/pages/terms";
import Sustainability from "../components/pages/sustainability";
import Directory from "../components/pages/directory";
import Statistics from "../components/pages/statistics";
import Classics from "../components/pages/classics";

export default [
  {
    path: "/",
    exact: true,
    component: Home,
  },
  {
    path: "/terms",
    exact: true,
    component: Terms,
  },
  {
    path: "/category/:slug",
    exact: true,
    component: Category,
  },
  {
    path: "/leaderboard",
    exact: true,
    component: Leaderboard,
  },
  {
    path: "/my-list",
    exact: true,
    component: MyList,
  },
  {
    path: "/series/:slug",
    exact: true,
    component: Series,
  },
  {
    path: "/speaker/:slug",
    exact: true,
    component: Speaker,
  },
  {
    path: "/search",
    exact: true,
    component: Search,
  },
  {
    path: "/video",
    exact: true,
    component: Video,
  },
  {
    path: "/quiz",
    exact: true,
    component: Video,
  },
  {
    path: "/profile",
    exact: true,
    component: Profile,
  },
  {
    path: "/profile/edit",
    exact: true,
    component: EditProfile,
  },
  {
    path: "/profile/:user_id",
    // exact: true,
    component: ProfileUser,
  },
  {
    path: "/sustainability",
    exact: true,
    component: Sustainability,
  },
  {
    path: "/directory",
    exact: true,
    component: Directory,
  },
  {
    path: "/statistics",
    exact: true,
    component: Statistics,
  },
  {
    path: "/classics",
    exact: true,
    component: Classics,
  },
  {
    path: "/notfound",
    exact: true,
    component: NotFound,
  },
  {
    component: NotFound,
  },
];
