import React, { useEffect } from 'react';
import { useGlobalContext } from '../../store/global';

const Loader = () => {
  const { loading } = useGlobalContext();

  useEffect(() => {
    document.body.classList.toggle('noscroll');
    return () => {
      document.body.classList.remove('noscroll');
    };
  }, []);

  return (
    <>
      {loading && (
        <div className="loader">
          <div
            className="logo-wrapper"
            style={{ position: 'absolute', textAlign: 'center', padding: '40px' }}
          >
            <div
              className="ideas"
            >
              <img src="/assets/images/ideas.svg" />
            </div>
          </div>
          <div className="loading-dots">
            <div className="loading-dots--dot"></div>
            <div className="loading-dots--dot"></div>
            <div className="loading-dots--dot"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
