import React from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../store/global';
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t } = useTranslation();

  const { openModal, hasRegistered } = useGlobalContext();
  const handleModal = () => {
    openModal('suggestion', true);
  };

  return (
    <footer>
      <div className="logo-wrapper">
        <div className="hyatt icon-HYATT"></div>
        <div className="ideas icon-IDEAS2-1"></div>
      </div>
      <div className="suggestions">
        <p>
          {t('Have_Suggestions_For_Improvements')}
          <br />
          <span onClick={handleModal}>{t('Give_Us_Your_Ideas')}</span>
        </p>
      </div>
      <Link to="/terms" className="discover-style">
        {t('Terms_And_Conditions')}
      </Link>
    </footer>
  );
};

export default Footer;
