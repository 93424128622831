import { useState, useEffect } from 'react';
import { searchVideos, searchSeries, searchQuizzes } from '../../utils/data';

const organizeColumns = (fb, ops, sm) => {
  // organize videos so they line up in columns by category
  const organizedVideos = [];
  let i = 0;
  while (true) {
    if (fb[i]) {
      organizedVideos.push(fb[i]);
    }
    if (ops[i]) {
      organizedVideos.push(ops[i]);
    }
    if (sm[i]) {
      organizedVideos.push(sm[i]);
    }
    if (!fb[i] && !ops[i] && !sm[i]) {
      return organizedVideos;
    }
    i++;
  }
};

const useSearch = (q, tags, filter, category = null, startLoading = true, isSpeakers = false, sort = '', isSustainability = false) => {
  const EPISODES_PAGESIZE = 15;
  const QUIZZES_PAGESIZE = 8;
  const SERIES_PAGESIZE = (category == null && isSustainability) === false ? 16 : 8;

  const [episodes, setEpisodes] = useState([]);
  const [episodesPage, setEpisodesPage] = useState(0);
  const [multiEpisodesPage, setMultiEpisodesPage] = useState([0, 0, 0]);
  const [episodeIsLoading, setEpisodeIsLoading] = useState(false);
  const [episodeEndReach, setEpisodeEndReach] = useState(false);
  const [episodeSort, setEpisodeSort] = useState('');

  const [series, setSeries] = useState([]);
  const [seriesPage, setSeriesPage] = useState(0);
  const [seriesIsLoading, setSeriesIsLoading] = useState(false);
  const [seriesEndReach, setSeriesEndReach] = useState(false);

  const [quizzes, setQuizzes] = useState([]);
  const [quizzesPage, setQuizzesPage] = useState(0);
  const [multiQuizzesPage, setMultiQuizzesPage] = useState([0, 0, 0]);
  const [quizzesIsLoading, setQuizzesIsLoading] = useState(false);
  const [quizzesEndReach, setQuizzesEndReach] = useState(false);
  const [multiQuizzesEndReach, setMultiQuizzesEndReach] = useState(
    [false, false, false]
  );
  const [quizzesSort, setQuizzesSort] = useState('');

  const resetEpisodes = () => {
    setEpisodes([]);
    setEpisodesPage(0);
    setMultiEpisodesPage([0, 0, 0]);
    setEpisodeIsLoading(false);
    setEpisodeEndReach(false);    
    setEpisodeSort('');
  }

  const resetSeries = () => {
    setSeries([]);
    setSeriesPage(0);
    setSeriesIsLoading(false);
    setSeriesEndReach(false);
  }

  const resetQuizzes = () => {
    setQuizzes([]);
    setQuizzesPage(0);
    setMultiQuizzesPage([0, 0, 0]);
    setQuizzesIsLoading(false);
    setQuizzesEndReach(false);
    setQuizzesSort('');
  }


  useEffect(() => {
    if (!startLoading) {
      return;
    }

    switch (filter) {
      case 'series':
        resetEpisodes();
        resetQuizzes();

        fetchSeries(0, true);
        break;

      case 'episodes':
      case 'speakers':
        resetSeries();
        resetQuizzes();

        setEpisodeSort(sort);

        fetchEpisodes(0, true, [0, 0, 0], sort);
        break;

      case 'quiz':
        resetEpisodes();
        resetSeries();

        setQuizzesSort(sort);

        fetchQuizzes(0, true, [0, 0, 0], [false, false, false], sort);
        break;
    
      default:
        fetchEpisodes(0, true, [0, 0, 0], '');
        fetchSeries(0, true);
        fetchQuizzes(0, true, [0, 0, 0], [false, false, false], '');
        break;
    }

  }, [q, tags, filter, category, startLoading, isSpeakers, sort]);

  async function fetchEpisodes(page, stillContinue, multipage, sort = '') {
    if ((episodeIsLoading || episodeEndReach) && !stillContinue) return;
    setEpisodesPage(page);
    setEpisodeIsLoading(true);
    setEpisodeEndReach(false);
    let epi = page === 0 ? [] : [...episodes];

    if (!q && !tags && !category && !isSustainability) {
      const range = `[${(page * EPISODES_PAGESIZE) / 3}, ${((page * EPISODES_PAGESIZE) /3) + (EPISODES_PAGESIZE / 3) - 1}]`

      let params = {}
      if (sort != '') {
        params.sort = sort;
      }

      let [FBE, OPE, SME] = await Promise.all([
        searchVideos({
          ...params,
          category: process.env.REACT_APP_FB,
          range,
          filter: { releaseDate: {$lte: new Date()} },
          // sort: { createdAt: -1 },
        }),
        searchVideos({
          ...params,
          category: process.env.REACT_APP_OPERATIONS,
          range,
          filter: { releaseDate: {$lte: new Date()} },
          // sort: { createdAt: -1 },
        }),
        searchVideos({
          ...params,
          category: process.env.REACT_APP_SM,
          range,
          filter: { releaseDate: {$lte: new Date()} },
          // sort: { createdAt: -1 },
        }),
      ])

      const mergedEpisodes = organizeColumns(FBE.data, OPE.data, SME.data)
      epi = [...epi, ...mergedEpisodes];

      if (mergedEpisodes.length === 0)
        setEpisodeEndReach(true);
    } else {
      let params = {}
      if (sort != '') {
        params.sort = sort;
      }
      if (tags === 'sustainability') {
        params.tags = '';
        params.isSustainability = true;
      } else {
        params.tags = tags;
      }
      if (isSustainability) {
        params.isSustainability = true;
      }

      let EPI = await searchVideos({
        ...params,
        category,
        q,
        range: `[${page * EPISODES_PAGESIZE}, ${(page * EPISODES_PAGESIZE) + EPISODES_PAGESIZE - 1}]`,
        filter: { releaseDate: {$lte: new Date()} },
      }, isSpeakers);

      epi = [...epi, ...EPI.data];

      if (EPI.data.length < EPISODES_PAGESIZE)
        setEpisodeEndReach(true);
    }

    setEpisodes(epi);
    setEpisodeIsLoading(false);
  }

  async function fetchSeries(page, stillContinue, multipage) {
    if ((seriesIsLoading || seriesEndReach) && !stillContinue) return;
    setSeriesPage(page);
    setSeriesIsLoading(true);
    setSeriesEndReach(false);
    let ser = page === 0 ? [] : [...series];

    let params = {};
    params.category = category;
    if (isSustainability) {
      params.isSustainability = true;
    }

    let SER = await searchSeries({
        ...params,
        q,
        range: `[${page * SERIES_PAGESIZE}, ${(page * SERIES_PAGESIZE) + SERIES_PAGESIZE - 1}]`,
        sort: `["createdAt", "DESC"]`
    })

    if (!SER.data || SER?.data?.length < SERIES_PAGESIZE)
      setSeriesEndReach(true);

    setSeries([...ser, ...SER.data]);
    setSeriesIsLoading(false);
  }

  async function fetchQuizzes(page, stillContinue, multipage, multipageEndReach, sort = '') {
    if ((quizzesIsLoading || quizzesEndReach) && !stillContinue) return;
    setQuizzesPage(page);
    setQuizzesIsLoading(true);
    setQuizzesEndReach(false);
    setMultiQuizzesEndReach(multipageEndReach);
    let qui = page === 0 ? [] : [...quizzes];

    // let QUI = await searchQuizzes({
    //     category,
    //     // tags,
    //     q,
    //     range: `[${page * QUIZZES_PAGESIZE}, ${(page * QUIZZES_PAGESIZE) + QUIZZES_PAGESIZE - 1}]`,
    //     // filter: { releaseDate: {$lte: new Date()} },
    //     // sort: { createdAt: -1 },
    // })

    // console.log('kkkk1', page, stillContinue, multipage, multipageEndReach)
    // console.log('kkkk2', !q && !tags && !category)
  //
    if (!q && !tags && !category) {
      // const range = `[${(page * QUIZZES_PAGESIZE) / 3}, ${((page * QUIZZES_PAGESIZE) /3) + (QUIZZES_PAGESIZE / 3) - 1}]`
      const range = (p) => `[${p}, ${Math.floor(p + (QUIZZES_PAGESIZE / 3) - 1)}]`
      let mergedQuizzes = []
      let cpyEnd = multipageEndReach
      let pagesCat = multipage

      // console.log('kkkk3', cpyEnd[0], cpyEnd[1], cpyEnd[2])
      if (!cpyEnd[0] && !cpyEnd[1] && !cpyEnd[2]) {
        let params = {}
        if (sort != '') {
          params.sort = sort;
        }
        if (isSustainability) {
          params.isSustainability = true;
        }

        let [FBE, OPE, SME] = await Promise.all([
          searchQuizzes({
            ...params,
            category: process.env.REACT_APP_FB,
            range: range(multipage[0]),
            // filter: { releaseDate: {$lte: new Date()} },
            // sort: { createdAt: -1 },
          }),
          searchQuizzes({
            ...params,
            category: process.env.REACT_APP_OPERATIONS,
            range: range(multipage[1]),
            // filter: { releaseDate: {$lte: new Date()} },
            // sort: { createdAt: -1 },
          }),
          searchQuizzes({
            ...params,
            category: process.env.REACT_APP_SM,
            range: range(multipage[2]),
            // filter: { releaseDate: {$lte: new Date()} },
            // sort: { createdAt: -1 },
          }),
        ])

        pagesCat = [
          multipage[0] + FBE.data.length,
          multipage[1] + OPE.data.length,
          multipage[2] + SME.data.length
        ]
        if (FBE.data.length < Math.floor(QUIZZES_PAGESIZE / 3))
          cpyEnd = [true, cpyEnd[1], cpyEnd[2]]
        if (OPE.data.length < Math.floor(QUIZZES_PAGESIZE / 3))
          cpyEnd = [cpyEnd[0], true, cpyEnd[2]]
        if (SME.data.length < Math.floor(QUIZZES_PAGESIZE / 3))
          cpyEnd = [cpyEnd[0], cpyEnd[1], true]

        mergedQuizzes = organizeColumns(FBE.data, OPE.data, SME.data)
      }

      // console.log('kkkk4', cpyEnd[0], cpyEnd[1], cpyEnd[2])

      const rangePlus = (p) =>
        `[${(p)}, ${(p) + 1}]`
      while (mergedQuizzes.length < QUIZZES_PAGESIZE) {
        let count = 0;

        let params = {}
        if (sort != '') {
          params.sort = sort;
        }
        if (isSustainability) {
          params.isSustainability = true;
        }

        let FBplus = { data: [] }
        if (!cpyEnd[0])
          FBplus = await searchQuizzes({
            ...params,
            category: process.env.REACT_APP_FB,
            range: rangePlus(pagesCat[0]),
          })
        if (FBplus.data.length === 0) {
          cpyEnd = [true, cpyEnd[1], cpyEnd[2]]
          count++; // means that there is one more category can't load more
        } else {
          pagesCat = [pagesCat[0] + 1, pagesCat[1], pagesCat[2]]
          mergedQuizzes.push(FBplus.data[0])
          if (mergedQuizzes.length === QUIZZES_PAGESIZE) break;
        }

        let OPplus = { data: [] }
        if (!cpyEnd[1])
          OPplus = await searchQuizzes({
            ...params,
            category: process.env.REACT_APP_OPERATIONS,
            range: range(pagesCat[1]),
          })
        if (OPplus.data.length === 0) {
          cpyEnd = [cpyEnd[0], true, cpyEnd[2]]
          count++;
        } else {
          pagesCat = [pagesCat[0], pagesCat[1] + 1, pagesCat[2]]
          mergedQuizzes.push(OPplus.data[0])
          if (mergedQuizzes.length === QUIZZES_PAGESIZE) break;
        }

        let SMplus = { data: [] }
        if (!cpyEnd[2])
          SMplus = await searchQuizzes({
            ...params,
            category: process.env.REACT_APP_SM,
            range: range(pagesCat[2]),
          })
        if (SMplus.data.length === 0) {
          cpyEnd = [cpyEnd[0], cpyEnd[1], true]
          count++;
        } else {
          pagesCat = [pagesCat[0], pagesCat[1], pagesCat[2] + 1]
          mergedQuizzes.push(SMplus.data[0])
          if (mergedQuizzes.length === QUIZZES_PAGESIZE) break;
        }

        if (count === 3) {
          pagesCat = [true, true, true]
          break;
        }
      }

      qui = [...qui, ...mergedQuizzes];
      setMultiQuizzesEndReach(cpyEnd)
      setMultiQuizzesPage(pagesCat);
      if (mergedQuizzes.length === 0)
        setQuizzesEndReach(true);
    } else {
      let params = {}
      if (sort != '') {
        params.sort = sort;
      }
      if (isSustainability) {
        params.isSustainability = true;
      }
      // sort: '["createdAt","ASC"]',

      let QUI = await searchQuizzes({
        ...params,
        category,
        // tags,
        q,
        range: `[${page * QUIZZES_PAGESIZE}, ${(page * QUIZZES_PAGESIZE) + QUIZZES_PAGESIZE - 1}]`,
        // filter: { releaseDate: {$lte: new Date()} },
        // sort: { createdAt: -1 },
      }, isSpeakers);

      qui = [...qui, ...QUI.data];

      if (!QUI?.data || QUI?.data?.length < QUIZZES_PAGESIZE)
        setQuizzesEndReach(true);
    }

    setQuizzes(qui);
    setQuizzesIsLoading(false);
  }

  const fetchMoreEpisodes = async () =>
    fetchEpisodes(
      episodesPage + 1,
      false,
      multiEpisodesPage,
      episodeSort
    );
  const fetchMoreSeries = async () => fetchSeries(seriesPage + 1, false);
  const fetchMoreQuizzes = async () =>
    fetchQuizzes(
      quizzesPage + 1,
      false,
      multiQuizzesPage,
      multiQuizzesEndReach,
      quizzesSort
    );

  return {
    episodes: {
      list: episodes,
      fetchMore: fetchMoreEpisodes,
      isLoading: episodeIsLoading,
      endReach: episodeEndReach,
    },
    series: {
      list: series,
      fetchMore: fetchMoreSeries,
      isLoading: seriesIsLoading,
      endReach: seriesEndReach,
    },
    quizzes: {
      list: quizzes,
      fetchMore: fetchMoreQuizzes,
      isLoading: quizzesIsLoading,
      endReach: !q && !tags && !category ?
        multiQuizzesEndReach[0] && multiQuizzesEndReach[1] && multiQuizzesEndReach[2]
        : quizzesEndReach
    },
  }
}

export default useSearch;